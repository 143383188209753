import React, { FC } from 'react'
import { ColorProps, FontSizeProps, theme } from '../../../theme'

export const LocationPointer: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color = 'gray700', size = 'x3' }) => {
  return (
    <svg
      width={theme.fontSizes[size]}
      height={theme.fontSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns={'https://www.w3.org/2000/svg'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7456 15.8031L16.7426 15.7991C18.2066 14.4611 19.1286 12.5121 19.1286 10.3431C19.1286 6.3071 15.9366 3.0351 11.9996 3.0351C8.0626 3.0351 4.8706 6.3071 4.8706 10.3431C4.8706 12.5121 5.7936 14.4611 7.2576 15.7991L7.2536 15.8031L11.4696 20.1241C11.7626 20.4251 12.2376 20.4251 12.5306 20.1241L16.7456 15.8031ZM11.9996 1.0001C17.1286 1.0001 21.2856 5.1571 21.2856 10.2861C21.2856 13.0421 20.0846 15.5171 18.1776 17.2181L18.1816 17.2231L12.6916 22.7141C12.3096 23.0951 11.6906 23.0951 11.3086 22.7141L5.8176 17.2231L5.8226 17.2181C3.9156 15.5171 2.7146 13.0421 2.7146 10.2861C2.7146 5.1571 6.8716 1.0001 11.9996 1.0001ZM9.4579 10.6702C9.4579 12.0742 10.5959 13.2122 11.9999 13.2122C13.4039 13.2122 14.5419 12.0742 14.5419 10.6702C14.5419 9.2672 13.4039 8.1292 11.9999 8.1292C10.5959 8.1292 9.4579 9.2672 9.4579 10.6702Z"
        fill={theme.colors[color]}
      />
    </svg>
  )
}
