import React from 'react'
import { ButtonIcon } from '@lojinha/design-system'
import { Menu } from '@lojinha/design-system/src/icons'
import { MenuNavigationProps } from '../../../types'
import { t } from '../../../dictionary'
import { Drawer } from '../components/drawer'
import { DrawerHeader } from '../components/drawer-header'
import { MenuContent } from './components/menu-content'

export const MenuMobileUI = ({
  isMenuOpen,
  onCloseMenu,
  onOpenMenu,
  menuItems,
  selectedSubMenu,
  handleClickMenu,
  onGoBack,
}: MenuNavigationProps) => {
  return (
    <>
      <ButtonIcon
        icon={<Menu size="x3" />}
        onClick={onOpenMenu}
        ariaLabel={t.openMenuAlt}
      />
      <Drawer
        isOpen={isMenuOpen}
        label={t.hungryOfWhat}
        onRequestClose={onCloseMenu}
      >
        <DrawerHeader
          title={selectedSubMenu ? selectedSubMenu.title : t.categories}
          hasGoBackButton={!!selectedSubMenu}
          onClose={onCloseMenu}
          onGoBackClick={onGoBack}
        />
        <MenuContent
          items={menuItems}
          selectedSubMenu={selectedSubMenu}
          onClickMenu={handleClickMenu}
          showSubMenu={!!selectedSubMenu}
        />
      </Drawer>
    </>
  )
}
