import { CookieSerializeOptions, parse, serialize } from 'cookie'
import dayjs from 'dayjs'
import { NextPageContext } from 'next'

export const getCartId = async (ctx?: NextPageContext) => {
  const stringfiedCookies = process.browser
    ? document?.cookie
    : ctx?.req?.headers.cookie || ''

  return parse(stringfiedCookies)['_cartId']
}

export const setCartCookie = (cartId: string) => {
  const isSecureToken = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

  const cookieOptions: CookieSerializeOptions = {
    sameSite: 'lax',
    secure: isSecureToken,
    path: '/',
    expires: dayjs().add(180, 'day').toDate(),
    domain: window.location.hostname,
  }

  const cartCookie = serialize('_cartId', cartId, cookieOptions)

  document.cookie = cartCookie

  return cartId
}
