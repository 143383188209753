import React from 'react'
import {
  Box,
  Center,
  Grid,
  tabNavigationStyle,
  Text,
} from '@lojinha/design-system'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { capitalizeFirstLetter, updateSearchParam } from '@lojinha/helpers'
import { t } from '../../dictionary'

const Anchor = styled.a(
  ({ theme }) => `
    min-width: fit-content;
    border-radius: ${theme.space.x48};
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    padding: ${theme.space.x12} ${theme.space.x24};
    text-decoration: none;
    border: 1px solid ${theme.colors.gray200};
    margin-top: ${theme.space.x8};
    margin-bottom: ${theme.space.x8};
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${theme.colors.success};
      color: ${theme.colors.white};
      transition: background-color 0.3s ease-in;
    }

    ${theme.media.below('sm')} {
      margin-top: ${theme.space.x4};
      margin-bottom: ${theme.space.x4};
    }
`,
  tabNavigationStyle
)

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: ${theme.space.x12};
  margin-top: ${theme.space.x16};
`
)

export const PreSearch = () => {
  const router = useRouter()

  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid gridColumn={['2 / 24', '3 / 11']}>
          <Box marginTop={['x24', 'x56']}>
            <Text
              textAlign="center"
              isBrand
              textTransform="uppercase"
              textSize={['x3', 'x4']}
            >
              {t.search.preSearchTitle}
            </Text>
            <Container>
              {t.search.mostSearchedTerms.map((term: string) => (
                <Anchor
                  key={term}
                  onClick={() => updateSearchParam(router, term)}
                >
                  {capitalizeFirstLetter(term)}
                </Anchor>
              ))}
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Center>
  )
}
