import { Text } from '@lojinha/design-system'
import React from 'react'
import { t } from '../../../../dictionary'
import { numberToCurrency } from '@lojinha/helpers'

type Props = {
  price: number
  priceFrom?: number | null
}

export const Price = ({ price, priceFrom }: Props) => {
  return (
    <div aria-label={t.priceAriaLabel({ price, priceFrom })}>
      {!!priceFrom && priceFrom > price && (
        <Text
          color="gray700"
          textSize={'x14'}
          textDecoration="line-through"
          aria-hidden
        >
          {numberToCurrency(priceFrom)}
        </Text>
      )}
      <Text color="success" textSize={'x14'} aria-hidden isBold>
        {numberToCurrency(price)}
      </Text>
    </div>
  )
}
