export const labels = {
  goToHomeAlt: 'Voltar para a página inicial',
  hungryOfWhat: 'Fome do que?',
  inDev: 'Em desenvolvimento',
  willDelivery: 'Será que entrega?',
  whereAreYou: 'Onde você tá?',
  indicationsAlt: 'Descontos por indicação',
  openBagAlt: 'Abrir sacola',
  openProfileAlt: 'Minha conta',
  openSearchAlt: 'Abrir busca',
  openMenuAlt: 'Abrir menu',
  new: 'novo',
  closeMenu: 'Fechar menu',
  seeAll: 'Ver todos',
  deliveryOn: 'Entregar em:',
  addNewAddress: '+ adicionar endereço',
  addNewAddressAriaLabel: 'Adicionar novo endereço',
  changeAddressTo: 'Trocar entrega para: ',
  addressSelected: 'Endereço de entrega selecionado: ',
  menu: {
    account: {
      profile: 'Perfil',
      login: 'Entrar',
      helloUser: 'Olá,',
    },
    myOrders: 'minhas compras',
    fidelivup: 'fidelivup',
    mgm: 'indique um amigo e ganhe ',
    logout: 'sair da conta',
  },
  categories: 'Categorias',
  goBack: 'Voltar',
  home: 'Início',
  yourBag: 'Sua sacola',
  deliveryAddress: 'Endereço de entrega',
  search: 'Buscar',
  searchAriaLabel: 'Campo de busca',
  close: 'Fechar',
  packedLunchs: 'Marmitas',
  dayToDay: 'Dia a dia',
  chefsChoices: 'Escolhas dos chefs',
  lowCarb: 'Low carb',
  kits: 'Kits',
  portions: 'Porções',
  redMeats: 'Carnes vermelhas',
  chickens: 'Frangos',
  fishesAndSeaFood: 'Peixes e Frutos do mar',
  vegetarians: 'Vegetarianos',
  carbohydrates: 'Carboidratos',
  vegetables: 'Vegetais',
  soups: 'Sopas',
  savoryAndSweets: 'Salgados & Doces',
  savory: 'Salgados',
  sweets: 'Doces',
  breads: 'Pães',
  proteinSnacks: 'Lanches Proteicos',
  packedLunchsPlus: 'Turbinadas 370g',
}
