import Image, { ImageLoaderProps } from 'next/image'
import React from 'react'
import styled from 'styled-components'
import { imgix } from '@lojinha/helpers'

const ImageWrapper = styled.div<{
  isHover?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.space.x8};
  background: ${({ theme }) => theme.colors.gray100};
  height: 100px;
  max-width: 160px;

  & div {
    position: relative;
    border-radius: ${({ theme }) => theme.space.x8};
    max-height: 100px;
    max-width: 160px;
    min-width: 100%;
  }
  & img {
    min-width: 100%;
    object-fit: cover;
    transition: transform 200ms ease-out;
    border-radius: ${({ theme }) => theme.space.x8};
    &:hover {
      transition: transform 200ms;
      transform: scale(1.05);
    }
  }

  > span {
    width: 100% !important;
    height: 100% !important;
  }
`

type Props = {
  url: string
  ariaLabel: string
  isHover?: boolean
}

export const ItemImage = ({ url, ariaLabel, isHover }: Props) => {
  const imageLoader = ({ src }: ImageLoaderProps) => {
    return `${imgix.DOMAIN}${src}?auto=compress,format`
  }

  return (
    <ImageWrapper aria-hidden={true} isHover={isHover}>
      <Image
        src={url?.replace(imgix.DOMAIN, '/') ?? ''}
        alt={ariaLabel}
        width="100%"
        height="100%"
        loader={imageLoader}
      />
    </ImageWrapper>
  )
}
