import styled, { keyframes } from 'styled-components'
import { Box, Grid, tabNavigationStyle, Text } from '@lojinha/design-system'

const hoverStyled = (color: string) => `
  background-color: ${color};
  transition: background-color 250ms ease-in;
`

export const LinkSection = styled(Text)<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => `
    display: flex;
    background-color: ${theme.colors.transparent};
    padding: ${theme.space.x12} ${theme.space.x24};
    border-radius: ${theme.borderRadius.x48};
    transition: background-color 200ms ease-in;
    width: fit-content;

    &:hover {
      ${hoverStyled(theme.colors.gray100)};
    }

    ${isSelected ? hoverStyled(theme.colors.gray100) : ''}
  `,
  tabNavigationStyle
)

export const MenuNavigationContainer = styled.div<{ enabled: boolean }>(
  ({ enabled }) => `
  background: ${enabled ? 'rgba(0, 0, 0, 0.4)' : 'transparent'};
  height: 100%;
  transition: background-color 350ms ease-in;
`
)

export const MenuNavigationContent = styled.div<{ enabled: boolean }>(
  ({ theme, enabled }) => `
    display: block;
    height: 568px;
    background-color: ${theme.colors.white};
    overflow: hidden;
    transition: max-height 300ms linear, opacity 200ms ease-out 100ms;
    margin-top: ${theme.space.x32};
    max-height: ${enabled ? '80vh' : 0};
`
)

export const MenuNavigationDrawer = styled.nav<{ enabled: boolean }>(
  ({ enabled, theme }) => {
    return `
    position: absolute;
    height: 100vh;
    width: 100%;
    left: 0;
    top: ${theme.space.x64};
    pointer-events: ${enabled ? 'all' : 'none'};
  `
  }
)

const fadeInLeftAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-25px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const SubMenuList = styled.ul`
  animation: ${fadeInLeftAnimation} 300ms ease;
`

export const MenuBannerContainer = styled(Grid)<{ isVisible: boolean }>(
  ({ isVisible }) => `
  opacity: ${isVisible ? 1 : 0};
  transition: opacity 30ms ease-out;
`
)

export const MenuBannerContent = styled(Box)`
  opacity: 0;
  animation: ${fadeInAnimation} 350ms ease forwards;
`
