import { tabNavigationStyle } from '@lojinha/design-system'
import { testId, normalizeProductUrl } from '@lojinha/helpers'
import React, { FC } from 'react'
import styled from 'styled-components'
import NextLink from 'next/link'

type Props = {
  onMouseOver?: () => void
  onClick: () => void
  onMouseLeave?: () => void
  ariaLabel: string
  slug: string
}

const Component = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-decoration: none;

  ${({ theme }) => `
    border-radius: ${theme.space.x8};
  `}
  ${tabNavigationStyle};
`

const Border = styled.div`
  position: absolute;
  border-radius: 8px;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${({ theme }) => `
    box-shadow: inset 0px 0px 0px 1px ${theme.colors.gray200};
  `}
`

export const CardWrapper: FC<Props> = ({
  children,
  ariaLabel,
  onClick,
  onMouseOver,
  onMouseLeave,
  slug,
}) => {
  const handleOnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!onClick) return

    const target = event.target as HTMLAnchorElement
    const clickOnAddToCardButton = target.closest(
      '[data-id="add-to-cart-button"]'
    )

    if (!clickOnAddToCardButton) {
      onClick()
    } else {
      event.preventDefault()
    }
  }

  return (
    <NextLink href={normalizeProductUrl(slug)} passHref>
      <Component
        as={'a'}
        tabIndex={0}
        aria-label={ariaLabel}
        onClick={handleOnClick}
        onMouseOver={() => onMouseOver && onMouseOver()}
        onMouseLeave={() => onMouseLeave && onMouseLeave()}
        {...testId('card-wrapper')}
      >
        <Border />
        {children}
      </Component>
    </NextLink>
  )
}
