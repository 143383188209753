import { AddressKind } from '@lojinha/palantir'
import { captureException, SentryError } from '@lojinha/sentry'

export const updateDeliveryTax = ({
  subtotal,
  deliveryCost,
  isFreeDeliveryCoupon,
  deliveryKind,
  setDeliveryTax,
  freeShippingValue,
}: {
  subtotal: number
  deliveryCost?: number
  isFreeDeliveryCoupon?: boolean
  deliveryKind?: AddressKind
  setDeliveryTax?: (deliveryTax: number) => void
  freeShippingValue?: number
}) => {
  const allowedFreeDeliveryTax =
    (!!freeShippingValue && subtotal >= freeShippingValue) ||
    isFreeDeliveryCoupon ||
    deliveryKind === AddressKind.Pickup

  if (!freeShippingValue) {
    const freeShippingValueError: SentryError = new Error(
      'Failed to get freeShippingValue'
    )

    captureException(freeShippingValueError)
  }

  if (!deliveryCost && !allowedFreeDeliveryTax) {
    const error: SentryError = new Error('Failed to update delivery tax')

    captureException(error)
  }

  const finalDeliveryTax =
    !deliveryCost || allowedFreeDeliveryTax ? 0 : deliveryCost

  setDeliveryTax && setDeliveryTax(finalDeliveryTax)

  return {
    finalDeliveryTax,
  }
}
