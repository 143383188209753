import { useEffect, useState } from 'react'

import {
  applyKitReplacingExperimentChangesOnBag,
  useGoToCheckout,
} from '@lojinha/bag'
import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import {
  BagFeedbacks,
  BagProps,
  CartItem,
  CartItemGroup,
} from '@lojinha/context/src/types'
import { useWindowProperties } from '@lojinha/design-system'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@lojinha/helpers'
import {
  NumberOfOrdersQuery,
  RemoveUnavailableItemsFromCartMutation,
  RemoveUnavailableItemsMutationVariables,
  useApolloClient,
} from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'
import {
  UpdateCartEvents,
  UpdateCartProps,
  UpdateItemOnBagType,
} from '../types'
import {
  NUMBER_OF_ORDERS_QUERY,
  REMOVE_UNAVAILABLE_ITEMS_MUTATION,
} from './queries'

const sendAddToCartEvent = async ({
  addend,
  cartItems,
  cartSubtotal,
  displayName,
  eventList,
  experimentGroup,
  isFirstBuy,
  item,
  positionInList,
  user,
  freeDeliveryThreshold,
}: UpdateCartEvents) => {
  const action = addend === 1 ? 'add' : 'remove'
  beholder.shot('addToCart', {
    action,
    list: eventList,
    positionInList,
    isFirstBuy,
    item,
    cartItems,
    cartSubtotal,
    experimentGroup,
    displayName,
    user,
    freeDeliveryThreshold,
  })

  const isCartCreated = cartItems.length === 0 && action === 'add'

  isCartCreated &&
    beholder.shot('cartCreated', {
      list: eventList,
      positionInList,
      isFirstBuy,
      item,
      cartItems,
    })
}

// TODO: move to @lojinha/helpers
const createBagContent = (items: CartItemGroup[]) => {
  const updateBagValues = items?.reduce(
    (
      acc: {
        bagSubtotal: number
        bagPriceFrom: number
        bagQuantity: number
      },
      bagItem: CartItemGroup
    ) => {
      const { priceFrom: bagItemPriceFrom, price: bagItemPrice } = bagItem.item

      const itemPriceFrom =
        bagItemPriceFrom && bagItemPriceFrom > 0
          ? bagItemPriceFrom
          : bagItemPrice

      return {
        bagSubtotal: bagItemPrice * bagItem.quantity + acc.bagSubtotal,
        bagPriceFrom: itemPriceFrom * bagItem.quantity + acc.bagPriceFrom,
        bagQuantity: bagItem.quantity + acc.bagQuantity,
      }
    },
    {
      bagSubtotal: 0,
      bagPriceFrom: 0,
      bagQuantity: 0,
    }
  )

  const { bagQuantity, bagSubtotal, bagPriceFrom } = updateBagValues

  return {
    cartItems: items,
    quantity: bagQuantity,
    subtotal: bagSubtotal,
    subtotalPriceFrom: bagPriceFrom,
    createdAt: new Date(),
  } as BagProps
}

const shouldOpenBag = (
  quantity: number,
  addend: number,
  isMobile: boolean | undefined
) => {
  const isFirstAdd = quantity === 1 && addend === 1
  return isFirstAdd && !Boolean(isMobile)
}

function hasItemOnBag(items: CartItemGroup[], itemId: string) {
  return items?.some((bagItem: CartItemGroup) => bagItem.item.id === itemId)
}

function addItemToBag(
  currentItems: CartItemGroup[],
  item: CartItem,
  addend: number
): CartItemGroup[] {
  if (hasItemOnBag(currentItems, item.id)) {
    return currentItems
      .map(bagItem => {
        if (bagItem.item.id === item.id) {
          return {
            ...bagItem,
            quantity: bagItem.quantity + addend,
          }
        }
        return bagItem
      })
      .filter(bagItem => bagItem.quantity > 0)
  }
  if (addend < 0) {
    return currentItems
  }
  return [
    ...currentItems,
    {
      item,
      kind: item.kind,
      quantity: item.quantity + addend,
      id: item.id,
    },
  ]
}

export const useUpdateCart = ({
  eventList,
  positionInList,
}: UpdateCartProps) => {
  const {
    address,
    bag,
    setBag,
    isAuthenticated,
    isFirstBuy = true,
    centerId,
    setBagOpen,
    setBagFeedback,
    setRemovedItems,
    localizationModal,
    experimentGroup,
    user,
    freeShippingValue,
  } = useLojinhaContext()

  const { isMobile } = useWindowProperties()

  const localStorageBag = JSON.parse(getLocalStorage('localBag') || '{}')
  const bagItems = localStorageBag.cartItems ?? []

  const [isLoading, setIsLoading] = useState(false)
  const [updateCartError, setUpdateCartError] = useState(false)

  const client = useApolloClient()

  const { handleCartSync } = useGoToCheckout()

  const getNumberOfOrders = async () => {
    try {
      if (!isAuthenticated) return 0

      const { data } = await client.query<NumberOfOrdersQuery>({
        query: NUMBER_OF_ORDERS_QUERY,
        fetchPolicy: 'cache-first',
      })

      return data.viewer.user?.sales.totalCount ?? 0
    } catch {
      return 0
    }
  }

  const updateLocalBag = (bagContent: BagProps) => {
    setLocalStorage('localBag', JSON.stringify(bagContent))
    setBag(bagContent)
  }

  const removeStandByItem = () => removeLocalStorage('standByItem')

  const syncCartOnPalantir = async (bagContent: BagProps) => {
    try {
      const cartIdCreated = await handleCartSync(bagContent)
      if (!cartIdCreated) throw Error

      setIsLoading(false)
    } catch {
      setUpdateCartError(true)
      setIsLoading(false)
      captureException(new Error('Failed to add purchase products on cart'))
    }
  }

  useEffect(() => {
    const standByItem =
      getLocalStorage('standByItem') &&
      JSON.parse(getLocalStorage('standByItem')!)

    if (localizationModal?.fromAddToCart && standByItem && address) {
      updateLocalBag(
        createBagContent([
          {
            item: standByItem,
            quantity: 1,
            id: standByItem.id,
          },
        ])
      )
      setBagOpen(true)
      removeStandByItem()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizationModal?.openedModal])

  const updateItemOnBag = async ({
    item,
    addend,
    displayName,
  }: UpdateItemOnBagType) => {
    setIsLoading(true)

    const updateBagItems = addItemToBag(bagItems, item, addend)
    const intermediaryBag = createBagContent(updateBagItems)
    const bagContent = applyKitReplacingExperimentChangesOnBag(
      intermediaryBag,
      isFirstBuy
    )

    updateLocalBag(bagContent)
    shouldOpenBag(bagContent.quantity, addend, isMobile) && setBagOpen(true)

    const numberOfOrders = await getNumberOfOrders()

    sendAddToCartEvent({
      eventList,
      freeDeliveryThreshold: freeShippingValue,
      positionInList,
      item: {
        id: item.sku,
        category: item.kind,
        name: item.name,
        price: item.price,
        quantity: (item.quantity ?? 0) + (addend ?? 0),
      },
      addend,
      isFirstBuy: !numberOfOrders,
      cartItems: bagItems
        ? bagItems.map((bagItem: CartItemGroup) => ({
            id: bagItem.item.id,
            quantity: bagItem.quantity,
            price: bagItem.item.price,
          }))
        : [],
      cartSubtotal: bagContent.subtotal,
      experimentGroup,
      displayName,
      user: {
        email: user?.email,
        name: user?.name,
        phoneNumber: user?.phoneNumber,
        city: user?.city,
        state: user?.state,
        country: user?.country,
        zipCode: user?.zipCode,
      },
    })

    if (isAuthenticated) {
      syncCartOnPalantir(bagContent)
    }

    setIsLoading(false)
  }

  const removeUnavailableItems = async (
    cartId?: string
  ): Promise<{ removedItems?: string[]; cartItems?: CartItemGroup[] }> => {
    if (!centerId || !cartId) return {}

    try {
      const { data } = await client.mutate<
        RemoveUnavailableItemsFromCartMutation,
        RemoveUnavailableItemsMutationVariables
      >({
        mutation: REMOVE_UNAVAILABLE_ITEMS_MUTATION,
        variables: {
          cartId,
          distributionCenterId: centerId,
        },
      })

      const removeUnavailableData = data?.removeUnavailableItemsFromCart

      if (!removeUnavailableData) throw Error

      const { cart } = removeUnavailableData

      const cartItems = cart?.items.nodes.map(item => {
        return {
          id: item.item.id,
          item: item.item,
          quantity: item.quantity,
        }
      })

      const removedItems = removeUnavailableData.removedItems.map(
        removeItem => removeItem.name
      )

      return {
        removedItems,
        cartItems,
      }
    } catch (err: any) {
      setUpdateCartError(true)
      captureException(
        new Error(`Failed to remove unavailable items from cart, ${err}`)
      )

      return {}
    }
  }

  const addNewProductsToBagGroup = (newProducts: CartItemGroup[]) =>
    bagItems.reduce((saleItems: CartItemGroup[], saleItem: CartItemGroup) => {
      const hasItemOnBag = newProducts.filter(
        itemChanged => itemChanged.id === saleItem.id
      ).length

      if (!hasItemOnBag) return [saleItem, ...saleItems]
      return saleItems
    }, newProducts)

  const updateItemsOnBag = async ({
    products,
  }: {
    products: CartItemGroup[]
  }) => {
    setIsLoading(true)

    const productsUpdated: CartItemGroup[] = products.map(item => {
      const { id } = item.item

      const itemOnBag = bagItems?.filter(
        (bagItem: CartItemGroup) => bagItem.id === id
      )

      const itemQuantity = itemOnBag?.length
        ? itemOnBag[0].quantity + item.quantity
        : item.quantity

      return {
        id,
        item: item.item,
        quantity: itemQuantity,
      }
    })

    const bagContent = applyKitReplacingExperimentChangesOnBag(
      createBagContent(productsUpdated),
      isFirstBuy
    )

    try {
      const cartIdCreated = await handleCartSync(bagContent)
      if (!cartIdCreated) {
        throw Error
      }

      const removeUnavailablesData = await removeUnavailableItems(cartIdCreated)
      const bagWithoutUnavailableItems = removeUnavailablesData?.cartItems
      const removedItems = removeUnavailablesData?.removedItems
      const hasAvailableItems =
        bagWithoutUnavailableItems && bagWithoutUnavailableItems.length
      const allItemsHaveBeenRemoved = !bagWithoutUnavailableItems?.filter(
        item =>
          bagContent.cartItems.filter(
            saleProduct => saleProduct.item.id === item.id
          ).length
      ).length

      if (!hasAvailableItems || allItemsHaveBeenRemoved) {
        setBagFeedback(BagFeedbacks.allItemsRemoved)
      } else if (removedItems && removedItems.length) {
        setBagFeedback(BagFeedbacks.someItemsRemoved)
        setRemovedItems(removedItems)
      } else {
        setBagFeedback(BagFeedbacks.allItemsAdded)
      }

      const bagItemsUpdated = addNewProductsToBagGroup(
        bagWithoutUnavailableItems ?? []
      )

      const bagContentUpdated = createBagContent(bagItemsUpdated)

      updateLocalBag(bagContentUpdated)

      setIsLoading(false)
      setBagOpen(true)
    } catch {
      setUpdateCartError(true)
      setIsLoading(false)
      captureException(new Error('Failed to add purchase products on cart'))
    }
  }

  const getToItemQuantity = (itemId: string) =>
    bag?.cartItems.find(bagItem => bagItem.item.id === itemId)?.quantity ?? 0

  return {
    getToItemQuantity,
    isLoading,
    updateItemOnBag,
    updateItemsOnBag,
    updateCartError,
  }
}
