import { Coordinates } from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'

type AddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export const getZipCodeAndNumberByCoordinates = async ({
  latitude,
  longitude,
}: Coordinates) => {
  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GEOCODE_KEY}`

    const response = await fetch(url)
    const geocodeResponse = await response.json()
    const address = geocodeResponse.results[0].formatted_address as string
    const addressComponents = geocodeResponse.results[0]
      .address_components as AddressComponent[]

    const zipCode = addressComponents.find(component =>
      component.types.includes('postal_code')
    )?.long_name

    const filteredNumber = address.split(',')[1]
    const number = filteredNumber && filteredNumber.trim().split(' - ')[0]

    return {
      zipCode,
      number,
    }
  } catch (err: any) {
    captureException(err)
    return {
      zipCode: null,
      number: null,
    }
  }
}
