import { useEffect } from 'react'
import { useDebounce } from '@lojinha/helpers'
import {
  MarketableKind,
  SearchQuery,
  SearchQueryVariables,
  useLazyQuery,
} from '@lojinha/palantir'
import { useLojinhaContext } from '@lojinha/context'
import { DisplayItems } from '../../page-content/page-content.types'
import { SEARCH_QUERY } from './query'
import { beholder } from '@lojinha/beholder'

const SEARCH_DELAY = 500
const MININUM_SEARCH_LENGTH = 3

export const useSearch = (term = '') => {
  const [search, { loading, data, error, called }] = useLazyQuery<
    SearchQuery,
    SearchQueryVariables
  >(SEARCH_QUERY)

  const { nodes, totalCount } = data?.search.results || {}

  const { debouncedValue, debouncing } = useDebounce(term, SEARCH_DELAY)

  const hasResults = !!totalCount

  const hasMinimumLength = debouncedValue.length >= MININUM_SEARCH_LENGTH

  const { centerId: distributionCenterId } = useLojinhaContext()

  useEffect(() => {
    if (hasMinimumLength) {
      search({ variables: { term: debouncedValue, distributionCenterId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, distributionCenterId])

  useEffect(() => {
    if (!loading && hasMinimumLength) {
      beholder.shot('structuredEvent', {
        category: 'search_global',
        action: 'search_terms',
        label: debouncedValue,
        property: hasResults ? 'found_results' : 'no_results',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  const itemsByKind = {
    [MarketableKind.Product]: {
      items: [] as DisplayItems[],
    },
    [MarketableKind.Kit]: {
      items: [] as DisplayItems[],
    },
    [MarketableKind.Meal]: {
      items: [] as DisplayItems[],
    },
  }

  ;(nodes || []).forEach(item => {
    itemsByKind[item.kind].items.push(item)
  })

  return {
    debouncedSearchTerm: debouncedValue,
    loading: loading || (!called && hasMinimumLength),
    error,
    debouncing,
    hasResults,
    results: [
      {
        kind: MarketableKind.Product,
        ...itemsByKind[MarketableKind.Product],
      },
      {
        kind: MarketableKind.Kit,
        ...itemsByKind[MarketableKind.Kit],
      },
      {
        kind: MarketableKind.Meal,
        ...itemsByKind[MarketableKind.Meal],
      },
    ],
    totalCount: totalCount || 0,
    hasMinimumLength,
  }
}
