import { tabNavigationOutline, Text } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import React from 'react'
import styled from 'styled-components'
import { t } from '../../../dictionary'

type Props = {
  ariaLabel: string
  isBig?: boolean
}

const AddButton = styled.div<{
  isBig?: boolean
}>(
  ({ theme, isBig }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-transform: uppercase;
  background-color: ${theme.colors.gray200};
  border: 2px solid ${theme.colors.gray200};
  border-radius: ${theme.space.x8};
  height: ${isBig ? theme.space.x48 : theme.space.x32};
`
)

const Wrapper = styled.button<{
  isBig?: boolean
}>(
  ({ theme, isBig }) => `
  cursor: not-allowed;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  min-height: ${theme.space.x48};
  min-width: ${isBig ? theme.space.x72 : theme.space.x48};
  background-color: ${theme.colors.transparent};
  padding: ${isBig ? '0px' : theme.space.x8};

  &:focus:not(:focus-visible) {
    ${AddButton} {
      outline: none;
    }
  }

  &:focus-visible {
    ${AddButton} {
      ${tabNavigationOutline}
    }
  }
`
)

export const BackSoonUI = ({ ariaLabel, isBig = false }: Props) => {
  return (
    <Wrapper
      aria-label={ariaLabel}
      isBig={isBig}
      {...testId('back-soon-button-vitrine')}
    >
      <AddButton isBig={isBig}>
        <Text textAlign="center" textSize="x1" isBold color="gray700">
          {t.comeBackSoon}
        </Text>
      </AddButton>
    </Wrapper>
  )
}
