import { useLojinhaContext } from '@lojinha/context'
import { BagProps } from '@lojinha/context/src/types'
import { redirectToCheckout } from '@lojinha/helpers'
import { useFreeShipping } from '@lojinha/location/src/free-shipping/free-shipping.hooks'
import {
  CartSyncItemInput,
  CartSyncMutation,
  CartSyncMutationVariables,
  useMutation,
} from '@lojinha/palantir'
import { Router } from '@lojinha/vitrine/router'
import { setCartCookie } from '@lojinha/vitrine/src/helpers/cartId'
import { useEffect, useState } from 'react'
import { adaptBagToCartItemsInput } from './logic'
import { CART_SYNC } from './queries'

export const useGoToCheckout = () => {
  const {
    bag,
    isAuthenticated,
    setCartId,
    setRouteAfterLogin,
  } = useLojinhaContext()

  const [isLoading, setIsLoading] = useState(false)

  const [cartSyncMutation] = useMutation<
    CartSyncMutation,
    CartSyncMutationVariables
  >(CART_SYNC)

  const cartSync = async (items?: CartSyncItemInput[]) => {
    return cartSyncMutation({
      variables: {
        input: {
          items: items,
          forceSync: true,
        },
      },
    })
  }

  const handleCartSync = async (currentBag?: BagProps) => {
    setIsLoading(true)

    const items = adaptBagToCartItemsInput(currentBag ?? bag)
    const { data } = await cartSync(items)
    const cartId = data?.cartSync?.cart?.id
    if (!cartId) {
      setIsLoading(false)
      return
    }

    setCartId(cartId)
    setCartCookie(cartId)
    setIsLoading(false)

    return cartId
  }

  const handleCartSyncAndRedirect = async () => {
    if (!isAuthenticated) {
      setRouteAfterLogin('/checkout')
      Router.push('/identification/sign-up')

      return
    }

    await handleCartSync()

    redirectToCheckout()
  }

  return {
    isLoading,
    handleCartSyncAndRedirect,
    handleCartSync,
  }
}

export const useShippingValue = () => {
  const [shouldFetchShippingValue, setShouldFetchShippingValue] = useState(true)
  const {
    freeShippingValue,
    setFreeShippingValue,
    isBagOpen,
    setLoadingFreeShippingValue,
    isFreeShippingLoading,
    coupon,
  } = useLojinhaContext()

  const { getFreeShippingValue } = useFreeShipping({
    setFreeShippingValue,
    setLoadingFreeShippingValue,
  })

  const handleFreeShippingValue = async () => {
    await getFreeShippingValue()
    setShouldFetchShippingValue(false)
  }

  const requestRefetchShippingValue = async (): Promise<void> => {
    setFreeShippingValue(undefined)
    setShouldFetchShippingValue(true)
  }

  useEffect(() => {
    if (isBagOpen && shouldFetchShippingValue) {
      handleFreeShippingValue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBagOpen, shouldFetchShippingValue])

  return {
    freeShippingValue,
    requestRefetchShippingValue,
    isFreeShippingLoading,
    coupon,
  }
}
