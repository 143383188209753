import React, { FC, useState, useEffect } from 'react'
import { theme, ColorProps } from '../../../theme'
import styled from 'styled-components'

type Direction = 'left' | 'right' | 'up' | 'down'

const Icon = styled.svg<{ iconTransform: string }>`
  transition: 0.2s ease-in-out;
  transform: ${({ iconTransform }) => `${iconTransform}`};
`

export const Chevron: FC<{
  direction?: Direction
  color?: ColorProps
  size?: string
}> = ({ direction, color, size }) => {
  const [transform, setTransform] = useState('')

  useEffect(() => {
    direction === 'up' && setTransform('rotate(180deg)')
    direction === 'right' && setTransform('rotate(-90deg)')
    direction === 'left' && setTransform('rotate(90deg)')
    direction === 'down' && setTransform('none')
  }, [direction])

  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={size}
      viewBox="0 0 185.344 185.344"
      iconTransform={transform}
    >
      <g>
        <g
          fill={theme.colors[color!]}
          fillRule="evenodd"
          stroke={color}
          strokeWidth="2"
        >
          <path
            d="M92.672,144.373c-2.752,0-5.493-1.044-7.593-3.138L3.145,59.301c-4.194-4.199-4.194-10.992,0-15.18
            c4.194-4.199,10.987-4.199,15.18,0l74.347,74.341l74.347-74.341c4.194-4.199,10.987-4.199,15.18,0
            c4.194,4.194,4.194,10.981,0,15.18l-81.939,81.934C98.166,143.329,95.419,144.373,92.672,144.373z"
          />
        </g>
      </g>
    </Icon>
  )
}

Chevron.defaultProps = {
  direction: 'down',
  size: theme.fontSizes.x2,
  color: 'black',
}
