import React, { FC } from 'react'
import { Box } from '@lojinha/design-system'
import styled from 'styled-components'

const CustomBottomBox = styled(Box)(
  ({ theme }) => `
    position: fixed;
    bottom: ${theme.space.x16};
    justify-content: space-between;
    z-index: 1;
    right: ${theme.space.x16};
    display: flex;

    .ReactModalOpenClass & {
      transform: translateX(-29rem);
      ${theme.media.below('sm')} {
        display: none;
      }
    }
    
    transition: 0.25s transform linear;
    
    & > * + * {
      margin-left: ${theme.space.x8};
    }
  `
)

export const BottomBox: FC = ({ children }) => {
  return <CustomBottomBox>{children}</CustomBottomBox>
}
