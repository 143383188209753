import React, { useEffect } from 'react'
import { Address } from '@lojinha/location'
import { Stack, Text, AlertTag, AlertTagIcons } from '@lojinha/design-system'
import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import { t } from '../../../dictionary'

type Props = {
  address: Address
}

enum AlertTagVariants {
  success = 'success',
}

export const InsideDeliveryArea = ({ address }: Props) => {
  const { isAuthenticated } = useLojinhaContext()

  useEffect(() => {
    beholder.shot('structuredEvent', {
      category: 'localization_modal',
      action: 'available',
      label: isAuthenticated ? 'logged_in' : 'not_logged_in',
    })
  }, [])

  return (
    <Stack margin="x24" layout="vertical" alignItems="center">
      <AlertTag
        content={t.singleAddress(address)}
        icon={AlertTagIcons.check}
        variant={AlertTagVariants.success}
      />
      <Text textAlign="center">{t.insideDeliveryArea}</Text>
    </Stack>
  )
}
