import React, { FC } from 'react'
import styled from 'styled-components'
import { Accordion, Text, Stack, Box } from '@lojinha/design-system'

const Line = styled.div`
  width: 100%;
  border: 0.5px solid ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.space.x32};
  margin-bottom: ${({ theme }) => theme.space.x16};
`

export const Group: FC<{ title: string }> = ({ title, children }) => (
  <>
    <Box display={['block', 'block', 'none']}>
      <Accordion heading={title} justifyContent="space-between">
        {children}
      </Accordion>
      <Line />
    </Box>
    <Box display={['none', 'none', 'block']}>
      <Stack margin="x16">
        <Text as="span">{title}</Text>
        {children}
      </Stack>
    </Box>
  </>
)
