import { MarketableClassification } from '@lojinha/palantir'
import { BagContentProps, BagGroupProps } from './types'

export const getToGroupKinds = ({ items }: BagContentProps) => {
  const classifications = items.reduce<MarketableClassification[]>(
    (acc, current) => {
      const groupName = current.item.classification || current.item.kind
      return acc.includes(groupName) ? acc : [...acc, groupName]
    },
    []
  )

  return [...classifications]
    .sort((a, b) => a.localeCompare(b))
    .map<BagGroupProps>(classification => ({
      classification,
      items: items.filter(item => item.item.classification === classification),
    }))
}
