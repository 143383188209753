import React, { FC } from 'react'
import { ColorProps, FontSizeProps, theme } from '../../../theme'

export const Location: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color = 'black', size = 'x3' }) => {
  const url = 'http://www.w3.org/2000/svg' // Sensitive
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      width={theme.fontSizes[size]}
      height={theme.fontSizes[size]}
      xmlns={url}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={theme.colors[color]}
        d="M7 12C7 9.23917 9.23917 7 12 7C14.7617 7 17 9.23917 17 12C17 14.7617 14.7617 17 12 17C9.23917 17 7 14.7617 7 12ZM19.1591 12.8189H20.2138C20.6479 12.8189 21 12.4668 21 12.0328V11.9672C21 11.5332 20.6479 11.1811 20.2138 11.1811H19.1623C18.7938 11.1811 18.4753 10.9247 18.3958 10.5652C17.8471 8.09454 15.9038 6.14959 13.4339 5.59763C13.0744 5.5182 12.8189 5.19964 12.8189 4.83112V3.78617C12.8189 3.35214 12.4668 3 12.0328 3H11.9672C11.5332 3 11.1811 3.35214 11.1811 3.78617V4.83112C11.1811 5.19964 10.9256 5.5182 10.5669 5.59763C8.097 6.14959 6.15369 8.09454 5.60419 10.5652C5.52475 10.9247 5.20619 11.1811 4.83767 11.1811H3.78617C3.35214 11.1811 3 11.5332 3 11.9672V12.0328C3 12.4668 3.35214 12.8189 3.78617 12.8189H4.84095C5.20864 12.8189 5.52721 13.0744 5.60746 13.4331C6.16106 15.8965 8.10109 17.8357 10.5661 18.386C10.9256 18.4662 11.1811 18.7848 11.1811 19.1525V20.2138C11.1811 20.6479 11.5332 21 11.9672 21H12.0328C12.4668 21 12.8189 20.6479 12.8189 20.2138V19.1525C12.8189 18.7848 13.0744 18.4662 13.4339 18.386C15.8989 17.8357 17.8389 15.8965 18.3925 13.4331C18.4728 13.0744 18.7914 12.8189 19.1591 12.8189ZM12 10C13.104 10 14 10.896 14 12C14 13.104 13.104 14 12 14C10.896 14 10 13.104 10 12C10 10.896 10.896 10 12 10Z"
      />
    </svg>
  )
}
