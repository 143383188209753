export function getDiscountPercentage(
  reducedPrice?: number | null,
  fullPrice?: number | null
) {
  if (
    typeof reducedPrice !== 'number' ||
    typeof fullPrice !== 'number' ||
    fullPrice === 0 ||
    reducedPrice > fullPrice
  ) {
    return 0
  }
  return Math.round(((fullPrice - reducedPrice) / fullPrice) * 100)
}
