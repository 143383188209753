import React, { FC } from 'react'
import Img from 'next/image'
import { Box, Button, Center, Stack, Text, theme } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import emptyState from '../../assets/images/empty-state.svg'

type EmptyStateProps = {
  text: string
  buttonText?: string
  title?: string
  onButtonClick?: () => void
}

export const EmptyState: FC<EmptyStateProps> = ({
  title,
  text,
  buttonText,
  onButtonClick,
}) => (
  <Center hasChildrenCentered px="x16">
    <Box marginBottom="x24">
      <Img
        src={emptyState}
        alt=""
        aria-hidden
        loading="lazy"
        width={160}
        height={152}
        unoptimized
      />
    </Box>
    <Stack margin="x8" htmlAttrs={{ style: { marginBottom: theme.space.x24 } }}>
      {title && (
        <Text isBrand textTransform="uppercase" textAlign="center">
          {title}
        </Text>
      )}
      <Text textAlign="center">{text}</Text>
    </Stack>
    {onButtonClick && buttonText && (
      <Button
        htmlAttrs={{
          style: {
            width: 'auto',
          },
          role: 'link',
        }}
        onClick={onButtonClick}
        {...testId('empty-state-button')}
      >
        {buttonText}
      </Button>
    )}
  </Center>
)
