import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const ArrowRightFine: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M21.569 11.435L15.712 3.395C15.531 3.147 15.243 3 14.936 3H14.799C14.012 3 13.56 3.896 14.027 4.53L18.514 10.618C18.631 10.777 18.518 11 18.321 11H2.96C2.43 11 2 11.431 2 11.961V12.04C2 12.571 2.43 13 2.96 13H18.32C18.517 13 18.631 13.225 18.514 13.383L14.022 19.471C13.554 20.104 14.006 21 14.794 21H14.929C15.236 21 15.525 20.854 15.705 20.606L21.569 12.566C21.815 12.229 21.815 11.772 21.569 11.435Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

ArrowRightFine.defaultProps = {
  color: 'black',
  size: 'x2',
}
