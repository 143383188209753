import React from 'react'
import { Box, Stack, Grid } from '@lojinha/design-system'

import { PaymentMethod } from './payment-method'
import { Links } from './links'
import { SocialNetwork } from './social-network'
import { SecurityBadges } from './security-badges'
import { DowloadApp } from './download-app'

export const LinksArea = () => {
  return (
    <Stack layout="vertical" margin={['x0', 'x0', 'x64']}>
      <Links />
      <Grid gridTemplateColumns="repeat(10, 1fr)">
        <Grid gridColumn={['1 / 11']} display={['block', 'block', 'grid']}>
          <PaymentMethod />
        </Grid>
      </Grid>
      <Box marginBottom="x24">
        <Grid gridTemplateColumns="repeat(6, 1fr)">
          <Grid
            gridColumn={['1 / 7', '1 / 7', '1 / 3']}
            display={['block', 'block', 'grid']}
          >
            <SocialNetwork />
          </Grid>
          <Grid
            gridColumn={['1 / 7', '1 / 7', '3 / 5']}
            display={['block', 'block', 'grid']}
          >
            <DowloadApp />
          </Grid>
          <Grid
            gridColumn={['1 / 7', '1 / 7', '5 / 7']}
            display={['block', 'block', 'grid']}
          >
            <Box marginTop={['x32', 'x32', 'x0']}>
              <SecurityBadges />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}
