import React from 'react'

import { Stack } from '@lojinha/design-system'
import { MenuHeaderDropdown } from '../../../components/menu-header-dropdown'
import { MenuAnchor } from './components/menu-anchor'
import { Menu } from '../../../types'

export type MenuNavigationProps = {
  handleClickMenu: (menu: Menu) => void
  menuItems: Menu[]
}

export const MenuDesktopUI = ({
  handleClickMenu,
  menuItems,
}: MenuNavigationProps) => {
  return (
    <Stack
      layout="horizontal"
      margin="x0"
      as="ul"
      htmlAttrs={{
        style: {
          position: 'relative',
        },
      }}
    >
      {menuItems.map(item => {
        return (
          <MenuHeaderDropdown
            key={item.id}
            route={item.route}
            anchorElement={
              <MenuAnchor
                label={item.title}
                htmlAttrs={{
                  ref: item.reference,
                }}
              />
            }
            menuItems={item.children || []}
            onClick={handleClickMenu}
          />
        )
      })}
    </Stack>
  )
}
