import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import { LocalizationModalType } from '@lojinha/context/src/types'
import { Address, AddressData, useSaveAddress } from '@lojinha/location'
import { useState } from 'react'
import { useShippingValue } from '@lojinha/bag'

export const useLocalizationModal = () => {
  const {
    setAddress,
    setCenterId,
    isAuthenticated,
    setLocalizationModal,
    localizationModal,
  } = useLojinhaContext()

  const { requestRefetchShippingValue } = useShippingValue()
  const { onSubmit: saveAddressToUser } = useSaveAddress({
    onCreate: requestRefetchShippingValue,
  })

  const [modalAddress, setModalAddress] = useState<Address | undefined>()

  const sendLocalizationEvent = (isGeoLocation: boolean, address?: Address) =>
    beholder.shot('structuredEvent', {
      category: 'localization_modal',
      action: 'check_address',
      label: isGeoLocation ? 'my_location' : 'typed_cep',
      property:
        address && `${address.city}_${address.neighborhood}_${address.zipCode}`,
    })

  const validateAndPersistAddress = async (
    isInDeliveryArea: boolean,
    isGeoLocation: boolean,
    address?: Address & { isGeoLocation?: boolean }
  ) => {
    setModalAddress(address)

    if (address && isInDeliveryArea) {
      setAddress(address)
      address.nearestDistributionCenter?.id &&
        setCenterId(address.nearestDistributionCenter?.id)
      isAuthenticated && (await saveAddressToUser(address as AddressData))
    }

    setLocalizationModal({
      ...localizationModal,
      openedModal:
        address && isInDeliveryArea
          ? LocalizationModalType.InsideDeliveryArea
          : LocalizationModalType.OutsideDeliveryAreaForm,
    })
    sendLocalizationEvent(isGeoLocation, address)
  }

  const goToAddressStep = () => {
    setLocalizationModal({
      ...localizationModal,
      openedModal: LocalizationModalType.Address,
    })
  }

  const goToOutsideSubmitted = () => {
    setLocalizationModal({
      ...localizationModal,
      openedModal: LocalizationModalType.OutsideDeiveryAreaSubmitted,
    })
  }

  const resetLocalizationModal = () => setLocalizationModal()

  return {
    modalAddress,
    validateAndPersistAddress,
    resetLocalizationModal,
    goToAddressStep,
    goToOutsideSubmitted,
    localizationModal,
  }
}
