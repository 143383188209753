import React from 'react'
import { Box, Text } from '@lojinha/design-system'

import { Price } from '../price'
import { t } from '../../../../dictionary'

type Props = {
  price: number
  priceFrom?: number
  weight: string
}

export const PriceAndWeight = ({ price, priceFrom, weight }: Props) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <Price price={price} priceFrom={priceFrom} />
      <Text
        color="gray700"
        textSize={['x14', 'x2']}
        aria-label={t.weightAriaLabel(weight)}
      >
        {weight}
      </Text>
    </Box>
  )
}
