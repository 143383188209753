export const formatCardNumber = (
  cardNumber: string,
  gapsList: number[],
  maxLength: number
) =>
  cardNumber
    .replace(/[^\d]/g, '')
    .split('')
    .reduce(
      (result: string, char: string, position: number) =>
        gapsList.includes(position) ? `${result} ${char}` : `${result}${char}`,
      ''
    )
    .substr(0, maxLength)

export const formatPureCardNumber = (cardNumber: string) =>
  cardNumber.replace(/\ /g, '')

export const formatHolderDocument = (holderDocument: string) =>
  holderDocument
    .replace(/[^\d]/g, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    .substr(0, 14)

export const formatExpirationDate = (expirationDate: string) => {
  const [month, year] = expirationDate
    .replace(/[^\d]/g, '')
    .replace(/(\d{2})(\d{1})/, '$1/$2')
    .split('/')

  if (!year) {
    return month
  }

  const toDigitYear = year.split('').splice(-2, 2).join('')

  return `${month}/${toDigitYear}`
}

export const formatCvv = (cvv: string, maxLength: number) =>
  cvv.replace(/[^\d]/g, '').substr(0, maxLength)

export const formatZipCode = (zipCode: string) =>
  zipCode
    .replace(/[^\d]/g, '')
    .replace(/(\d{5})(\d{3})/, '$1-$2')
    .substr(0, 9)

export const formatPhoneNumber = (phoneNumber: string) => {
  const purePhoneNumber = phoneNumber.replace(/[^\d]/g, '')

  if (purePhoneNumber.length <= 10) {
    return purePhoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3')
  }
  return purePhoneNumber
    .replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3')
    .substr(0, 14)
}
