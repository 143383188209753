import React from 'react'

import { CouponInputUI } from './coupon-input.ui'
import { useCouponInput } from './coupon-input.hook'

export interface CouponInputProps {
  isCheckoutReview: boolean
  cartId?: string
}

export const CouponInput = ({ isCheckoutReview, cartId }: CouponInputProps) => {
  const {
    applyCoupon,
    changeCoupon,
    onRemoveCoupon,
    customMessage,
    loading,
    success,
    pending,
    defaultCoupon,
  } = useCouponInput({ isCheckoutReview })

  return (
    <CouponInputUI
      loading={loading}
      success={success}
      pending={pending}
      cartId={cartId}
      customMessage={customMessage}
      onChangeCoupon={changeCoupon}
      onApplyCoupon={applyCoupon}
      defaultCoupon={defaultCoupon ?? ''}
      onRemoveCoupon={onRemoveCoupon}
    />
  )
}
