import React, { FC } from 'react'
import { useWindowProperties } from '@lojinha/design-system'
import { HeaderDesktop } from './modules/header-desktop'
import { HeaderMobile } from './modules/header-mobile'

type HeaderProps = {
  onClickBag: () => void
  onClickWillDelivery: () => void
  modalOpened?: boolean
  address?: string
  isHome: boolean
  showSearch?: boolean
}

export const Header: FC<HeaderProps> = ({
  onClickBag,
  onClickWillDelivery,
  modalOpened,
  address,
  isHome,
  showSearch,
}) => {
  const { isDesktop, isMobile } = useWindowProperties()

  // On server side, both vars are undefined, so we render both headers
  // When `window` is available, we decide the right one to render
  const hideOnMobile = !isMobile
  const hideOnDesktop = !isDesktop

  return (
    <>
      {hideOnDesktop ? (
        <HeaderMobile
          onClickBag={onClickBag}
          address={address}
          onClickWillDelivery={onClickWillDelivery}
          modalOpened={modalOpened}
          isHome={isHome}
          showSearch={showSearch}
        />
      ) : null}
      {hideOnMobile ? (
        <HeaderDesktop
          onClickBag={onClickBag}
          onClickWillDelivery={onClickWillDelivery}
          address={address}
          modalOpened={modalOpened}
          showSearch={showSearch}
        />
      ) : null}
    </>
  )
}
