import React, { useState } from 'react'

import { beholder } from '@lojinha/beholder'
import { Menu } from '../../../../types'
import { Router } from '@lojinha/vitrine/router'

export const useMenuNavigation = (menu: Menu[]) => {
  const [menuItems, setMenuItems] = useState<Menu[]>(
    menu.map(item => {
      const subItems = item.children ?? []

      return {
        ...item,
        route: item.route,
        reference: React.createRef(),
        children: subItems.map(child => ({
          ...child,
          reference: React.createRef(),
        })),
      }
    })
  )

  const onClickMenu = (currentMenu: Menu) => {
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'access_category',
      label: currentMenu.title?.toLowerCase(),
      property: currentMenu.route,
    })

    if (currentMenu.route) {
      Router.push(currentMenu.route)
    }
  }

  return {
    menuItems,
    onClickMenu,
    setMenuItems,
  }
}
