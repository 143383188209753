export const labels = {
  applyCoupon: 'Aplicar',
  removeCoupon: 'Remover',
  couponApplied: 'Cupom aplicado com sucesso',
  coupon: 'Cupom',
  noCoupon: 'Sem cupom aplicado',
  couponInput: 'Aplicar cupom',
  couponValidation: {
    invalidCoupon: 'Cupom inválido',
    expiredCoupon: 'Infelizmente este cupom já expirou',
    invalidDate: 'Infelizmente este cupom já expirou',
    invalidUser: 'Cupom inválido para esse usuário',
    invalidAddress: 'Cupom inválido para esse endereço',
    cartNotFound: 'Não conseguimos achar seu carrinho',
    limitReached: 'O limite de utilizações do cupom já foi atingido',
    firstPurchase: 'Este cupom só é valido na primeira compra',
    invalidCard: 'Cupom inválido para bandeira selecionada',
    minPurchase: 'Valor mínimo de compra não atingido',
    maxUsage: 'Limite de usos do cupom atingido',
    otherUser: 'Este cupom pertence a outro usuário',
    onlyMobile: 'Cupom exclusivo para uso no aplicativo',
    shouldHaveProduct:
      'Seu carrinho não possui os itens necessários para o cupom',
    invalidApplyToItems: 'Cupom válido apenas para produtos específicos',
  },
}
