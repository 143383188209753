import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'

export const useChat = () => {
  const { setChatLoading, setChatOpen } = useLojinhaContext()

  const openChatEvent = () => beholder.shot('openChat', null)

  const onOpenChat = () => {
    setChatLoading(true)
    openChatEvent()
    setTimeout(() => setChatOpen(true), 3000)
  }

  return {
    onOpenChat,
  }
}
