import React, { FC, useState } from 'react'
import { Box, Input, Text } from '@lojinha/design-system'
import { Tag } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'

import { dictionary } from './helpers'
import { ApplyCouponProps } from './coupon-input.hook'

export type CouponInputUIProps = {
  loading: boolean
  success: boolean
  pending: boolean
  defaultCoupon?: string
  customMessage?: string
  cartId?: string
  onApplyCoupon: (data: ApplyCouponProps) => void
  onChangeCoupon: (code: string) => void
  onRemoveCoupon: () => void
}

export const CouponInputUI: FC<CouponInputUIProps> = ({
  loading,
  success,
  pending,
  cartId,
  defaultCoupon,
  customMessage,
  onApplyCoupon,
  onChangeCoupon,
  onRemoveCoupon,
}) => {
  const [couponValue, setCouponValue] = useState(defaultCoupon ?? '')

  const isFilled = couponValue !== ''
  const removeCoupon = success || !!customMessage

  return (
    <Box paddingBottom="x24" paddingTop="x32" {...testId('apply-coupon-form')}>
      <Input
        icon={!isFilled ? <Tag /> : undefined}
        error={!success && customMessage}
        warning={pending && success ? customMessage : false}
        success={!pending && success ? customMessage : false}
        value={couponValue}
        loading={loading}
        blocked={!!success}
        label={dictionary.couponInput}
        name="coupon"
        onChange={event => {
          setCouponValue(event.target.value)
          onChangeCoupon(event.target.value)
        }}
        htmlAttrs={{
          autoComplete: 'off',
          list: 'autocompleteOff',
        }}
        addon={
          <Box
            as="button"
            htmlAttrs={{
              style: {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0,
                opacity: `${isFilled ? '1' : '0'}`,
                transition: 'opacity 250ms ease',
              },
            }}
            onClick={() => {
              if (removeCoupon) {
                setCouponValue('')
                onRemoveCoupon()
              }

              onApplyCoupon({ code: couponValue, cartId })
            }}
          >
            <Text
              as="span"
              color="primary"
              textTransform="uppercase"
              isBold
              textSize="x1"
            >
              {removeCoupon ? dictionary.removeCoupon : dictionary.applyCoupon}
            </Text>
          </Box>
        }
      />
    </Box>
  )
}
