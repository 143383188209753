import React, { FC, useEffect } from 'react'
import ReactModal from 'react-modal'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle<{ isOpen?: boolean }>(
  ({ isOpen }) => {
    if (!isOpen) {
      return ''
    }
    return `
      html, body {
        max-height: 100%;
        position: fixed;
      }
    `
  },
  ({ theme }) => `
  .menu-drawer-is-visible {
    overflow: hidden;
  }

  .menu-drawer {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;

    &-overlay {
      position: relative;
      height: 100vh;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
    }

    &-content {
      position: absolute;
      background: ${theme.colors.white};
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      overflow: auto;
    }

    // animation stuffs
    .ReactModal {
      &__Overlay {
        opacity: 0;
        transition: opacity 150ms ease-out 100ms;

        &--after-open {
          opacity: 1;
          transition: opacity 200ms ease-in-out;
        }

        &--before-close {
          opacity: 0;
          transition: opacity 150ms ease-out 100ms;
        }
      }

      &__Content {
        width: 100%;
        max-width: 100%;
        transform: translateX(-100%);
        transition: transform 250ms linear;

        &--after-open {
          transform: translateX(0);
          transition: transform 250ms linear;
        }

        &--before-close {
          transform: translateX(-100%);
          transition: transform 250ms linear;
        }
      }
    }
  }
`
)

export type DrawerProps = {
  label: string | React.ReactNode
  isOpen?: boolean
  onRequestClose: () => void
}

export const Drawer: FC<DrawerProps> = ({
  children,
  isOpen,
  onRequestClose,
}) => {
  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  return (
    <>
      <GlobalStyle isOpen={isOpen} />
      <ReactModal
        closeTimeoutMS={250}
        isOpen={!!isOpen}
        onRequestClose={onRequestClose}
        portalClassName="menu-drawer"
        bodyOpenClassName="menu-drawer-is-visible"
        overlayClassName="menu-drawer-overlay"
        className="menu-drawer-content"
      >
        {children}
      </ReactModal>
    </>
  )
}
