import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const Tag: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 0.666992H1.33341C0.965225 0.666992 0.666748 0.965469 0.666748 1.33366V8.00033C0.666748 8.17727 0.737093 8.34696 0.862285 8.472L6.58895 14.192C6.96355 14.567 7.4726 14.778 8.00342 14.778C8.53423 14.778 9.04328 14.567 9.41842 14.1915L14.1982 9.41173C14.975 8.63025 14.975 7.3704 14.1996 6.59033L8.47149 0.862254C8.34646 0.73723 8.17689 0.666992 8.00008 0.666992ZM2.00006 2.00049H7.72339L13.2553 7.53189C13.5124 7.79051 13.5124 8.21046 13.2539 8.47049L8.47532 13.2491C8.35002 13.3745 8.18033 13.4449 8.00339 13.4449L7.9157 13.4391C7.77113 13.4199 7.63593 13.3537 7.53173 13.2493L2.00006 7.72449V2.00049ZM5.00006 3.33382C5.92054 3.33382 6.66673 4.08001 6.66673 5.00049C6.66673 5.92096 5.92054 6.66715 5.00006 6.66715C4.07959 6.66715 3.33339 5.92096 3.33339 5.00049C3.33339 4.08001 4.07959 3.33382 5.00006 3.33382ZM5.00008 4.66699C4.81599 4.66699 4.66675 4.81623 4.66675 5.00033C4.66675 5.18442 4.81599 5.33366 5.00008 5.33366C5.18418 5.33366 5.33341 5.18442 5.33341 5.00033C5.33341 4.81623 5.18418 4.66699 5.00008 4.66699Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

Tag.defaultProps = {
  color: 'success',
  size: 'x2',
}
