import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../theme'

export const ShoppingBagIcon: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12129 4.70696C6.73077 4.31643 6.0976 4.31643 5.70708 4.70696C5.31655 5.09748 5.31655 5.73065 5.70708 6.12117L9.58576 9.99985H3C2.44772 9.99985 2 10.4476 2 10.9998C2 11.5521 2.44772 11.9998 3 11.9998H3.31917L4.92642 21.115C4.97945 21.4158 5.16173 21.6612 5.40606 21.8044C5.57215 21.9272 5.7776 21.9998 6.00002 21.9998H18C18.2223 21.9998 18.4277 21.9273 18.5937 21.8046C18.8382 21.6615 19.0206 21.4159 19.0736 21.115L20.6365 12.2518C20.6515 12.1667 20.6553 12.0822 20.6488 11.9998H21C21.5523 11.9998 22 11.5521 22 10.9998C22 10.4476 21.5523 9.99985 21 9.99985H12.3947C12.3628 9.95518 12.3269 9.91254 12.2868 9.87245L7.12129 4.70696ZM5.35003 11.9998L6.76064 19.9998H8.83999L8.14008 11.9998H5.35003ZM10.8476 19.9998H13.1524L13.8523 11.9998H10.1477L10.8476 19.9998ZM17.2394 19.9998H15.16L15.8599 11.9998H18.6501L17.2394 19.9998Z"
        fill="black"
      />
    </svg>
  )
}

ShoppingBagIcon.defaultProps = {
  size: 'x3',
}
