import { LocalizationModalType } from '@lojinha/context/src/types'
import { Modal } from '@lojinha/design-system'
import React from 'react'
import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import { t } from '../../dictionary'
import { Router } from '../../../router'
import { AddressForm } from './address'
import { useLocalizationModal } from './hooks'
import { OutsideForm } from './outside-form'
import { OutsideFormSubmitted } from './outside-form-submitted'
import { InsideDeliveryArea } from './inside'
import { UnavailableItems } from './unavailable-items'

export const LocalizationModal = () => {
  const {
    localizationModal,
    validateAndPersistAddress,
    modalAddress,
    goToAddressStep,
    goToOutsideSubmitted,
    resetLocalizationModal,
  } = useLocalizationModal()

  const { isAuthenticated } = useLojinhaContext()

  const { openedModal } = localizationModal ?? {}

  const onRequestClosed = () => {
    beholder.shot('structuredEvent', {
      category: 'localization_modal',
      action: 'closed',
      label: isAuthenticated ? 'logged_in' : 'not_logged_in',
    })
    resetLocalizationModal()
  }
  return (
    <Modal
      headerText={t.willDelivery}
      isOpen={!!localizationModal}
      onRequestClose={onRequestClosed}
    >
      {openedModal === LocalizationModalType.Address && (
        <AddressForm
          onSubmit={(isInDeliveryArea, isGeoLocation, address) =>
            validateAndPersistAddress(isInDeliveryArea, isGeoLocation, address)
          }
          onLoginClick={() => {
            resetLocalizationModal()
            Router.push('/identification')
          }}
        />
      )}
      {openedModal === LocalizationModalType.InsideDeliveryArea &&
        modalAddress && <InsideDeliveryArea address={modalAddress} />}
      {openedModal === LocalizationModalType.OutsideDeliveryAreaForm &&
        modalAddress && (
          <OutsideForm
            address={modalAddress}
            onBackAddressClick={goToAddressStep}
            onSubmitForm={goToOutsideSubmitted}
          />
        )}
      {openedModal === LocalizationModalType.OutsideDeiveryAreaSubmitted && (
        <OutsideFormSubmitted onChangeAddressClick={goToAddressStep} />
      )}
      {openedModal === LocalizationModalType.UnavailableItems &&
        localizationModal?.items && (
          <UnavailableItems items={localizationModal.items} />
        )}
    </Modal>
  )
}
