import { useLojinhaContext } from '@lojinha/context'
import React, { FC, useEffect } from 'react'
import { BagUI } from './ui'

export const Bag: FC = () => {
  const {
    bag,
    setBagOpen,
    isBagOpen,
    localizationModal,
    bagFeedback,
    setBagFeedback,
    removedItems,
    isAuthenticated,
    subtotalWithDiscount,
  } = useLojinhaContext()

  const onCloseBag = () => {
    setBagOpen(false)
    setBagFeedback(undefined)
  }

  const totalItems = bag?.quantity ?? 0
  const items = bag?.cartItems ?? []
  const subtotal = subtotalWithDiscount ?? 0
  const subtotalPriceFrom = bag?.subtotalPriceFrom ?? 0

  useEffect(() => {
    setBagFeedback(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Remove this when globalStyle of Modals removed
  if (!!localizationModal) return null

  return (
    <BagUI
      isOpen={!!isBagOpen}
      onRequestClose={onCloseBag}
      totalItems={totalItems}
      items={items}
      subtotal={subtotal}
      subtotalPriceFrom={subtotalPriceFrom}
      feedback={bagFeedback}
      removedItems={removedItems}
      isAuthenticated={isAuthenticated}
    />
  )
}
