import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { emailValidator } from '@lojinha/input-helpers'
import { Address } from '@lojinha/location'
import { beholder } from '@lojinha/beholder'
import { testId } from '@lojinha/helpers'
import {
  Box,
  Button,
  Spinner,
  Stack,
  Text,
  Input,
  AlertTag,
  AlertTagVariants,
  theme,
} from '@lojinha/design-system'
import { t } from '../../../dictionary'

type Props = {
  address: Address
  onSubmitForm?: () => void
  onBackAddressClick?: () => void
}

type FormProps = {
  email: string
}

export const OutsideForm = ({
  address,
  onSubmitForm,
  onBackAddressClick,
}: Props) => {
  const {
    errors,
    formState,
    handleSubmit,
    register,
    watch,
  } = useForm<FormProps>({
    mode: 'onChange',
    shouldFocusError: true,
  })

  const inputEmailValue = watch('email')

  const onSubmitEmail = () => {
    beholder.shot('structuredEvent', {
      category: 'localization_modal',
      action: 'email_lead',
      label: inputEmailValue,
      property:
        address && `${address.city}_${address.neighborhood}_${address.zipCode}`,
    })

    onSubmitForm && onSubmitForm()
  }

  useEffect(() => {
    beholder.shot('structuredEvent', {
      category: 'localization_modal',
      action: 'not_available',
    })
  }, [])

  return (
    <Stack margin="x24" layout="vertical" alignItems="center">
      <AlertTag
        variant={AlertTagVariants.error}
        content={t.singleAddress(address)}
      />
      <Text>{t.outsideDeliveryArea.title}</Text>
      <form onSubmit={handleSubmit(onSubmitEmail)}>
        <Box paddingBottom="x40">
          <Input
            error={errors.email && 'Email inválido!'}
            label={t.outsideDeliveryArea.inputEmailLabel}
            name="email"
            ref={register({
              required: true,
              validate: {
                email: value => emailValidator(value),
              },
            })}
            htmlAttrs={{
              style: { height: theme.space.x48, paddingTop: theme.space.x8 },
            }}
            addon={
              formState.isSubmitting && (
                <Spinner color="primary" bottom="0.2rem" right="0.2rem" />
              )
            }
          />
        </Box>
        <Stack layout="vertical">
          <Button
            isDisabled={!inputEmailValue || !!errors.email}
            isBlock
            type="submit"
            {...testId('submit-button')}
          >
            {t.outsideDeliveryArea.submittedLabel}
          </Button>
          <Button
            isOutlined
            isBlock
            onClick={onBackAddressClick}
            {...testId('back-to-address-button')}
          >
            {t.outsideDeliveryArea.backToAddress}
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
