import React from 'react'
import { Box, Skeleton, theme } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'

export const MenuLoading = () => (
  <Box
    display="flex"
    htmlAttrs={{
      style: {
        gap: theme.space.x48,
      },
    }}
    {...testId('menu-loading')}
  >
    <Skeleton
      htmlAttrs={{
        style: {
          height: theme.space.x12,
          width: theme.space.x72,
        },
      }}
    />
    <Skeleton
      htmlAttrs={{
        style: {
          height: theme.space.x12,
          width: theme.space.x96,
        },
      }}
    />
  </Box>
)
