import { Anchor, Box, Stack, Text } from '@lojinha/design-system'
import { LocationPointer } from '@lojinha/design-system/src/icons'
import { t } from '@lojinha/header/src/dictionary'
import { testId } from '@lojinha/helpers'
import React, { FC } from 'react'

export type WillDeliverAnchorProps = {
  onClickWillDeliver?: () => void
  text: string
  testIdText: string
}

export const WillDeliverAnchor: FC<WillDeliverAnchorProps> = ({
  onClickWillDeliver,
  text,
  testIdText,
}) => {
  return (
    <Anchor
      as="div"
      color="gray700"
      onClick={() => onClickWillDeliver && onClickWillDeliver()}
      textSize="x2"
      hasAccessibilitySize
      aria-label={text}
      {...testId(testIdText)}
      htmlAttrs={{ tabIndex: 0 }}
    >
      <Box marginRight="x8">
        <LocationPointer color="black" />
      </Box>
      <Stack htmlAttrs={{ style: { width: '90%' } }}>
        <Text textTransform="none" textSize="x12" color="black">
          {t.deliveryAddress}
        </Text>
        <Text
          isUnderlined
          isBold
          color="black"
          textSize="x14"
          htmlAttrs={{
            style: {
              marginTop: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textDecoration: 'none',
            },
          }}
        >
          {text}
        </Text>
      </Stack>
    </Anchor>
  )
}
