import { useState } from 'react'
import {
  AddressKind,
  SaveDeliveryAddressInput,
  DeliveryAddressSaveMutation,
  DeliveryAddressSaveMutationVariables,
  useMutation,
} from '@lojinha/palantir'
import { DELIVERY_ADDRESS_SAVE_MUTATION } from '../queries'
import { makeAddressId } from './logic'
import {
  Address,
  AddressData,
  UseSaveAddress,
  UseSaveAddressOptions,
} from './types'

export const useSaveAddress = (
  options?: UseSaveAddressOptions
): UseSaveAddress => {
  const defaultValues: Partial<AddressData> = {
    country: 'BR',
  }

  const [isLoadingSaveDeliveryAddress, setLoading] = useState<boolean>(false)

  /**
   * DeliveryAddress
   */
  const [saveDeliveryAddressMutation] = useMutation<
    DeliveryAddressSaveMutation,
    DeliveryAddressSaveMutationVariables
  >(DELIVERY_ADDRESS_SAVE_MUTATION)

  const handleUseSaveAddressOptions = async (createdAddress: Address) => {
    try {
      setLoading(true)
      await options?.onCreate(createdAddress, true)
      setLoading(false)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const saveDeliveryAddress = async (addressData: AddressData) => {
    const {
      street,
      number,
      complement,
      zipCode,
      city,
      state,
      country,
      neighborhood,
      coordinates: { latitude, longitude },
    } = addressData

    const input: SaveDeliveryAddressInput = {
      address: {
        street,
        number,
        complement,
        zipCode,
        city,
        state,
        country,
        neighborhood,
        coordinates: { latitude, longitude },
      },
    }

    await saveDeliveryAddressMutation({
      variables: {
        input,
      },
    })

    const createdAddress: Address = {
      ...input.address,
      id: makeAddressId(input.address),
      kind: AddressKind.Delivery,
      lastUsedAt: new Date().toISOString(),
    }

    options && handleUseSaveAddressOptions(createdAddress)
  }

  const onSubmit = async (addressData: AddressData) => {
    try {
      setLoading(true)
      await saveDeliveryAddress(addressData)
      setLoading(false)
    } catch (error: any) {
      throw new Error('Failed to save address')
    }
  }

  return {
    defaultValues,
    onSubmit,
    isLoadingSaveDeliveryAddress,
  }
}
