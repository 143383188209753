import React from 'react'
import { Text, Stack, theme } from '@lojinha/design-system'
import { t } from '../../../../dictionary'
import { Price } from '../price'

type Props = {
  price: number
  priceFrom?: number
  pricePerMeal: number
}

export const PricePerMeal = ({ price, priceFrom, pricePerMeal }: Props) => {
  return (
    <Stack alignItems="flex-end" layout="horizontal">
      <Price price={price} priceFrom={priceFrom} />
      <Text
        color="gray700"
        textSize={['x14', 'x2']}
        aria-label={t.pricePerMealAriaLabel(pricePerMeal)}
        htmlAttrs={{ style: { marginLeft: theme.space.x8 } }}
      >
        {t.pricePerMealLabel(pricePerMeal)}
      </Text>
    </Stack>
  )
}
