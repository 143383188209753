import { BagProps, CartItemGroup } from '@lojinha/context/src/types'
import { CartSyncItemInput, MarketableKind } from '@lojinha/palantir'

export const adaptBagToCartItemsInput = (bag?: BagProps) => {
  if (!bag) return

  return bag.cartItems.reduce((cartItems, itemGroup) => {
    if (!!itemGroup.item.id && !!itemGroup.item.kind && !!itemGroup.quantity) {
      return [
        ...cartItems,
        {
          productId: itemGroup.item.id,
          // Fix ATV-103 - some local carts have kind as 'Product' instead of 'PRODUCT'
          // should be a temporary fix, since the API will send the right value from now on
          classification: itemGroup.item.classification,
          kind: itemGroup.item.kind.toUpperCase() as MarketableKind,
          quantity: itemGroup.quantity,
        },
      ]
    }
    return cartItems
  }, [] as CartSyncItemInput[])
}

export function applyKitReplacingExperimentChangesOnBag(
  bag: BagProps | undefined,
  isFirstBuy: boolean
): BagProps {
  const newBag: Required<BagProps> = {
    quantity: 0,
    subtotal: 0,
    subtotalPriceFrom: 0,
    cartItems: [],
    createdAt: bag?.createdAt || new Date(),
  }

  if (!bag) {
    return newBag
  }

  const itemMap = new Map<string, CartItemGroup & { idx?: number }>(
    bag.cartItems.map((item, idx) => [item.item.id, { ...item, idx }])
  )

  bag.cartItems
    .filter(item => item.item.firstOfferReplacement)
    .forEach(item => {
      const substitutionDefinition = item.item.firstOfferReplacement
      const rule = isFirstBuy
        ? substitutionDefinition?.firstBuy
        : substitutionDefinition?.rebuy

      if (!rule || rule.id === item.item.id) {
        return
      }

      const itemToReplace = itemMap.get(item.item.id)
      const existingItem = itemMap.get(rule.id)

      itemMap.set(rule.id, {
        id: rule.id,
        kind: item.item.kind,
        quantity: (existingItem?.quantity || 0) + item.quantity,
        classification: item.item.classification,
        idx: existingItem?.idx || itemToReplace?.idx,
        item: {
          ...item.item,
          ...rule,
        },
      })

      if (itemToReplace) {
        itemMap.delete(item.item.id)
      }
    })

  const sortedCartItems = Array.from(itemMap.values()).sort((a, b) => {
    if (a.idx && b.idx) {
      return a.idx > b.idx ? 1 : -1
    }
    return a.item.id > b.item.id ? 1 : -1
  })

  sortedCartItems.forEach(({ idx, ...item }) => {
    newBag.cartItems.push(item)
    newBag.quantity = item.quantity + newBag.quantity
    newBag.subtotal = newBag.subtotal + item.item.price * item.quantity
    newBag.subtotalPriceFrom =
      newBag.subtotalPriceFrom + (item.item.priceFrom ?? 0) * item.quantity
  })

  return newBag
}
