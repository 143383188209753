import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const BagFilled: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C9.79089 2 8.00003 3.79086 8.00003 6V7H4.12503L3.32246 17.7008C3.14843 20.0213 4.98426 22 7.31126 22H16.7528C19.0562 22 20.8836 20.0596 20.7457 17.7604L20.1 7H16V6C16 3.79086 14.2092 2 12 2ZM14 7V6C14 4.89543 13.1046 4 12 4C10.8955 4 10 4.89543 10 6V7H14Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

BagFilled.defaultProps = {
  color: 'primary',
  size: 'x2',
}
