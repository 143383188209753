import { gql } from '@lojinha/palantir'

export const SEARCH_QUERY = gql`
  query Search($term: String!, $distributionCenterId: String) {
    search(
      input: { term: $term, distributionCenterId: $distributionCenterId }
    ) {
      results {
        totalCount
        nodes {
          id
          imageUrl
          name
          kind
          classification
          sku
          price
          priceFrom
          unit
          sellingStatus
          seo {
            url
          }
          ... on Product {
            isAvailable(distributionCenterId: $distributionCenterId)
            isAgeRestricted
            weight
            itemTags(maxTags: 2) {
              totalCount
              nodes {
                colorPalette
                content
              }
            }
          }
          ... on Meal {
            isAvailable(distributionCenterId: $distributionCenterId)
            weight
            mainProduct
            subtitle
            itemTags(maxTags: 2) {
              totalCount
              nodes {
                colorPalette
                content
              }
            }
          }
          ... on Kit {
            isAvailable(distributionCenterId: $distributionCenterId)
            pricePerDay
            totalMealsCount
            description
            itemTags(maxTags: 2) {
              totalCount
              nodes {
                colorPalette
                content
              }
            }
          }
        }
      }
    }
  }
`
