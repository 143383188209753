import React from 'react'
import { testId } from '@lojinha/helpers'
import {
  Box,
  Stack,
  Text,
  AlertTag,
  AlertTagVariants,
  AlertTagIcons,
  Anchor,
} from '@lojinha/design-system'
import { t } from '../../../dictionary'

type Props = {
  onChangeAddressClick?: () => void
}

export const OutsideFormSubmitted = ({ onChangeAddressClick }: Props) => {
  const { emailSuccessTexts } = t

  return (
    <Stack margin="x24" layout="vertical" alignItems="center">
      <AlertTag
        variant={AlertTagVariants.info}
        icon={AlertTagIcons.check}
        content={emailSuccessTexts.title}
      />
      <Box>
        <Text textAlign="center">
          {emailSuccessTexts.body}{' '}
          <Anchor
            onClick={onChangeAddressClick}
            role="button"
            isUnderlined
            isBold
            color="black"
            {...testId('change-address-button')}
          >
            {emailSuccessTexts.changeAddress}
          </Anchor>
        </Text>
      </Box>
    </Stack>
  )
}
