import { LocationPointer } from '@lojinha/design-system/src/icons'
import { AddressData, useSaveAddress } from '@lojinha/location'
import React, { FC } from 'react'
import { Address } from '@lojinha/context/src/types'
import { useShippingValue } from '@lojinha/bag'
import { AddressListDropdown } from '../address-list-dropdown'
import { HeaderDesktopButton } from '../header-desktop-button'
import { DropdownAnchor } from './components/dropdown-anchor'
import { WillDeliveryLoading } from './components/loading'

type Props = {
  onClickWillDelivery: () => void
  address?: string
  isLoading?: boolean
}

export const WillDelivery: FC<Props> = ({
  onClickWillDelivery,
  address,
  isLoading,
}) => {
  const { requestRefetchShippingValue } = useShippingValue()
  const { onSubmit } = useSaveAddress({ onCreate: requestRefetchShippingValue })

  if (isLoading) return <WillDeliveryLoading />

  const handleOnSubmit = async (selectedAddress: Address) => {
    await onSubmit(selectedAddress as AddressData)
  }

  return address ? (
    <AddressListDropdown
      address={address}
      onClickWillDelivery={onClickWillDelivery}
      onSelectAddress={handleOnSubmit}
      anchorElement={<DropdownAnchor />}
    />
  ) : (
    <HeaderDesktopButton
      mainText={''}
      icon={<LocationPointer color="black" />}
      onClick={onClickWillDelivery}
    />
  )
}
