import React, { FC, ChangeEvent, RefObject } from 'react'
import { Box } from '@lojinha/design-system'
import { Close, Search } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'
import { t } from '../../dictionary'
import { CloseButton, Container, Input } from './styles'
import { SpaceProps } from '@lojinha/design-system/dist/theme'

type Props = {
  searchTerm: string
  isSearchOpen: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
  inputRef: RefObject<HTMLInputElement>
  marginTop: SpaceProps
}

export const SearchInputUI: FC<Props> = ({
  searchTerm,
  isSearchOpen,
  onChange,
  onClose,
  inputRef,
  marginTop,
}) => (
  <Box htmlAttrs={{ style: { position: 'relative' } }} marginTop={marginTop}>
    <Container
      color="white"
      tabIndex={isSearchOpen ? -1 : 0}
      aria-label={t.search}
      {...testId('search-input-container')}
    >
      <Box display="flex">
        <Box display="flex" flex={1} order={1} alignItems="center">
          <Input
            ref={inputRef}
            onChange={onChange}
            value={searchTerm}
            tabIndex={isSearchOpen ? 0 : -1}
            type="text"
            placeholder={t.search}
            aria-label={t.searchAriaLabel}
          />
        </Box>
        <Box>
          <Search size="x3" color="success" />
        </Box>
      </Box>
    </Container>
    {isSearchOpen && (
      <Box
        marginLeft="x8"
        htmlAttrs={{
          style: { position: 'absolute', right: '1rem', top: '1rem' },
        }}
      >
        <CloseButton
          aria-label={t.close}
          onClick={onClose}
          {...testId('close-search-btn')}
        >
          <Close size="x3" color="black" />
        </CloseButton>
      </Box>
    )}
  </Box>
)
