import { useLojinhaContext } from '@lojinha/context'
import { beholder } from '@lojinha/beholder'

export const useAgeRestrictionModal = () => {
  const {
    ageRestrictionModal,
    setAgeRestrictionModal,
    callbackAgeRestriction,
  } = useLojinhaContext()

  const onConfirmAge = () => {
    sessionStorage.setItem('hasAgeRestrictionModal', 'true')
    setAgeRestrictionModal(false)
    if (callbackAgeRestriction) {
      callbackAgeRestriction()
    }

    beholder.shot('structuredEvent', {
      category: 'alcoholic_drink_alert',
      action: 'click',
      property: 'accept',
    })
  }

  const onDenyAge = () => {
    setAgeRestrictionModal(false)

    beholder.shot('structuredEvent', {
      category: 'alcoholic_drink_alert',
      action: 'click',
      property: 'deny',
    })
  }

  const resetAgeRestrictionModal = () => {
    setAgeRestrictionModal(false)
  }

  return {
    ageRestrictionModal,
    onConfirmAge,
    onDenyAge,
    resetAgeRestrictionModal,
  }
}
