import React from 'react'
import { Box, Stack } from '@lojinha/design-system'
import { BagContentProps } from './types'
import { BagGroup } from './group'
import { getToGroupKinds } from './logic'

export const BagContent = ({ items, hideEdition = false }: BagContentProps) => {
  const groupItems = getToGroupKinds({ items: items })

  return (
    <Box px={['x16', 'x40']}>
      <Stack layout="vertical" margin="x0">
        {groupItems.map(group => (
          <BagGroup
            classification={group.classification}
            items={group.items}
            key={group.classification}
            hideEdition={hideEdition}
          />
        ))}
      </Stack>
    </Box>
  )
}
