import { Box } from '@lojinha/design-system'
import React from 'react'
import styled from 'styled-components'
import { DATA_FOOTER } from '../footer.i18n'
import { Group } from './group'

const Brands = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: '';
    flex: auto;
  }
`

export const PaymentMethod = () => (
  <Group title={DATA_FOOTER.paymentMethods.title}>
    <Brands>
      {DATA_FOOTER.paymentMethods.brands.map(brand => {
        const PaymentMethodIcon = brand.image
        return (
          <Box key={brand.alt} marginRight={['x16', 'x8']} marginBottom="x8">
            <PaymentMethodIcon size="x4" />
          </Box>
        )
      })}
    </Brands>
  </Group>
)
