import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Box, Text, ButtonIcon, Stack } from '@lojinha/design-system'
import { ArrowLeftThin, Close } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'
import { t } from '../../../../dictionary'

type DrawerHeaderProps = {
  title: string
  onClose?: () => void
  hasGoBackButton?: boolean
  onGoBackClick?: () => void
}

const Header = styled(Box)(
  ({ theme }) => `
    padding: ${theme.space.x16} ${theme.space.x16} ${theme.space.x4} ${theme.space.x32};
  `
)

export const DrawerHeader: FC<DrawerHeaderProps> = ({
  title,
  hasGoBackButton = false,
  onGoBackClick,
  onClose,
}) => {
  const headerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    headerRef.current?.scrollIntoView()
  }, [title])

  return (
    <Header
      htmlAttrs={{ ref: headerRef }}
      alignItems="center"
      display="flex"
      justifyContent="space-between"
    >
      <Text
        {...testId('drawer-title')}
        textSize="x2"
        textTransform="uppercase"
        isBold
        isBrand
      >
        {title}
      </Text>
      <Stack layout="horizontal" margin="x8">
        {hasGoBackButton && onGoBackClick && (
          <ButtonIcon
            icon={<ArrowLeftThin size="x3" />}
            onClick={onGoBackClick}
            {...testId(`go-back-menu-btn`)}
            ariaLabel={t.goBack}
            hasRoundedBackground
          />
        )}
        <ButtonIcon
          icon={<Close size="x3" />}
          onClick={onClose}
          {...testId(`close-menu-btn`)}
          ariaLabel={t.closeMenu}
          hasRoundedBackground
        />
      </Stack>
    </Header>
  )
}
