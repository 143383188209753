import { Box, Spinner, Text } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useLojinhaContext } from '@lojinha/context'
import { t } from '../../dictionary'
import { AddButtonUI } from './add'
import { BackSoonUI } from './back-soon'
import { RemoveButtonUI } from './remove'
import { addToCartMethodType } from './types'

type Props = {
  isHover?: boolean
  isSmall?: boolean
  isAvailable?: boolean
  isAgeRestricted?: boolean
  quantity: number
  isLoading?: boolean
  isBig?: boolean
  displayName?: string
} & addToCartMethodType

const shouldShowStyle = css`
  width: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease 300ms;
`

const Container = styled.div<{ shouldShow?: boolean }>`
  width: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease 300ms;
  justify-content: center;
  align-items: center;
  display: flex;

  ${({ shouldShow }) => (shouldShow ? shouldShowStyle : '')}
`

const MiddleButtonContent: FC<{
  quantity: number
  isLoading: boolean
}> = ({ quantity, isLoading }) =>
  isLoading ? (
    <Spinner color="gray700" />
  ) : (
    <Text
      color={'black'}
      htmlAttrs={{
        'aria-label': t.quantityAriaLabel(quantity),
      }}
      {...testId('item-quantity')}
    >
      {quantity}
    </Text>
  )

export const AddToCartButtonUI: FC<Props> = ({
  displayName,
  isAgeRestricted = false,
  isAvailable = true,
  isBig = false,
  isHover,
  isLoading = false,
  isSmall = false,
  item,
  quantity,
  updateItemOnBag,
}) => {
  const {
    setAgeRestrictionModal,
    setCallbackAgeRestriction,
  } = useLojinhaContext()
  const [hasItems, setHasItems] = useState(quantity > 0)

  useEffect(() => {
    setHasItems(quantity > 0)
  }, [quantity])

  const addItemToCart = () => {
    updateItemOnBag({ item, addend: 1, displayName })
  }

  const handleAddItem = () => {
    if (isAgeRestricted && !('hasAgeRestrictionModal' in sessionStorage)) {
      setAgeRestrictionModal(true)
      setCallbackAgeRestriction(addItemToCart)
      return
    }
    addItemToCart()
  }

  const removeItemFromCart = () =>
    updateItemOnBag({ item, addend: -1, displayName })

  const addButtonAriaLabel = hasItems ? t.addToCartPlusAlt : t.addToCartAlt
  const removeButtonAriaLabel =
    quantity > 1 ? t.removeToCartAlt : t.deleteToCartAlt

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row-reverse"
      justifyContent={hasItems ? 'space-between' : 'flex-start'}
      htmlAttrs={{
        'data-id': 'add-to-cart-button',
      }}
    >
      {isAvailable ? (
        <>
          <AddButtonUI
            addItem={handleAddItem}
            isHover={isHover}
            isBlock={!hasItems}
            isSmall={isSmall}
            ariaLabel={addButtonAriaLabel}
            isBig={isBig}
          />

          <Container shouldShow={hasItems}>
            <MiddleButtonContent quantity={quantity} isLoading={isLoading} />
          </Container>

          <Container shouldShow={hasItems}>
            <RemoveButtonUI
              isSmall={isSmall}
              removeItem={removeItemFromCart}
              ariaLabel={removeButtonAriaLabel}
              isBig={isBig}
            />
          </Container>
        </>
      ) : (
        <BackSoonUI ariaLabel={t.comeBackSoonAlt} isBig={isBig} />
      )}
    </Box>
  )
}
