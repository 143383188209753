import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Menu } from '../../../../../types'
import { MenuList } from '../menu-list'
import { SubMenuList } from '../submenu-list'

type MenuContentProps = {
  onClickMenu: (menu: Menu) => void
  items: Menu[]
  selectedSubMenu: Menu | null
  showSubMenu: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const MenuTransitionBox = styled.nav<{ visible: boolean }>(
  ({ visible }) => `
    opacity: ${visible ? '1' : '0'};
    transform: ${visible ? 'translateX(0)' : 'translateX(-100%)'};
    height: ${visible ? 'auto' : '0'};
    width: ${visible ? '100%' : '50%'};
    transition: opacity 200ms ease-out, transform 250ms ease-out;
`
)

const SubMenuTransitionBox = styled.nav<{ visible: boolean }>(
  ({ visible }) => `
    opacity: ${visible ? '1' : '0'};
    transform: ${visible ? 'translateX(0)' : 'translateX(100%)'};
    height: ${visible ? 'auto' : '0'};
    width: ${visible ? '100%' : '50%'};
    transition: opacity 200ms ease, transform 250ms ease-out;
`
)

export const MenuContent: FC<MenuContentProps> = ({
  onClickMenu,
  items,
  selectedSubMenu,
  showSubMenu,
}: MenuContentProps) => {
  const [subMenuItems, setSubMenuItems] = useState(
    selectedSubMenu?.children || []
  )

  useEffect(() => {
    if (selectedSubMenu) {
      setSubMenuItems(selectedSubMenu.children || [])
    }
  }, [selectedSubMenu])

  return (
    <Container>
      <SubMenuTransitionBox visible={showSubMenu}>
        <SubMenuList
          items={subMenuItems}
          selectedItem={selectedSubMenu}
          onClick={onClickMenu}
        />
      </SubMenuTransitionBox>
      <MenuTransitionBox visible={!showSubMenu}>
        <MenuList items={items} onClick={onClickMenu} />
      </MenuTransitionBox>
    </Container>
  )
}
