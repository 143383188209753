import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Grid, Stack } from '@lojinha/design-system'
import {
  AccountButton,
  BagButton,
  Logo,
  Menu,
  SearchButton,
  SearchInput,
  WillDelivery,
} from '../../components'
import { MenuDesktop } from '../menu/menu-desktop'

const StickySection = styled.header<{ modalOpened?: boolean }>(
  ({ theme, modalOpened }) => `
  position: fixed;
  width: 100%;
  height: ${theme.space.x96};
  top: -${modalOpened ? theme.space.x96 : 0};
  background-color: ${theme.colors.white};
  z-index: 9;
  box-shadow: ${theme.shadows.gray16};
`
)

const Container = styled(Box)(
  ({ theme }) => `
    box-shadow: inset 0 -1px 0 ${theme.colors.gray200};
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    li {
      &::before {
        content: none;
      }
    }
  `
)

type HeaderDesktopProps = {
  onClickBag: () => void
  onClickWillDelivery: () => void
  address?: string
  modalOpened?: boolean
  showSearch?: boolean
}

export const HeaderDesktop: FC<HeaderDesktopProps> = ({
  onClickBag,
  onClickWillDelivery,
  address,
  modalOpened,
  showSearch,
}) => {
  return (
    <Box display={['none', 'none', 'block']} height="100%">
      <StickySection modalOpened={modalOpened}>
        <Container>
          <Box as="nav" width="100%" paddingLeft="x32" paddingRight="x32">
            <Grid gridTemplateColumns="repeat(12, 1fr)">
              <Grid gridColumn="1/1">
                <Logo height="x46" />
              </Grid>
              <Grid gridColumn="2 / 14">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Menu isMobile={false} />
                  <Box marginLeft="x8">
                    <Stack layout="horizontal" margin="x8">
                      <MenuDesktop
                        menu={[
                          {
                            id: '3c6db007-659e-4ea8-96c1-df0cea7ddd80',
                            isNew: false,
                            title: 'Revenda Liv Up',
                            route: '/revenda-liv-up',
                          },
                        ]}
                      />
                      {showSearch && <SearchButton />}
                      <WillDelivery
                        onClickWillDelivery={onClickWillDelivery}
                        address={address}
                      />
                      <AccountButton />
                      <BagButton onClick={onClickBag} />
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </StickySection>
      {showSearch && <SearchInput marginTop="x100" />}
    </Box>
  )
}
