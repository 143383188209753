import { gql } from '@lojinha/palantir'

export const CART_RECOMMENDATIONS = gql`
  query cartRecommendations(
    $distributionCenterId: String
    $skusToFilter: [String!]
  ) {
    cartRecommendations(
      skusToFilter: $skusToFilter
      distributionCenterId: $distributionCenterId
    ) {
      id
      imageUrl
      name
      kind
      sku
      price
      priceFrom
      unit
      seo {
        url
      }
      classification
      ... on Kit {
        isAvailable(distributionCenterId: $distributionCenterId)
        weight
      }
      ... on Product {
        weight
        isAvailable(distributionCenterId: $distributionCenterId)
        isAgeRestricted
      }
      ... on Meal {
        weight
        isAvailable(distributionCenterId: $distributionCenterId)
      }
    }
  }
`
