import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Text } from '@lojinha/design-system'
import { testId, useOnScreen } from '@lojinha/helpers'
import { useUpdateCart } from '@lojinha/vitrine/src/components/add-to-cart-button/hook'
import { t } from '../../dictionary'
import { CardWrapper } from './components/card'
import { ItemImage } from './components/item-image'
import { PriceAndWeight } from './components/price-and-weight'
import { RecommendationCardType } from './types'

const Title = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AddButton = styled(Button)(
  ({ theme }) => `
  border: 2px solid ${theme.colors.gray200};
  padding: ${theme.space.x12};
  background-color: ${theme.colors.white};
  color: ${theme.colors.success};

  &:hover,
  &:focus {
    background-color: ${theme.colors.white};
    box-shadow: none;
  }
  &:focus {
    border: 2px solid ${theme.colors.gray200};
  }
  &:hover {
    box-shadow: ${theme.shadows.gray16};
    border: 2px solid ${theme.colors.success};
  }
  &:disabled {
    color: ${theme.colors.gray700};
    background-color: ${theme.colors.gray200};
    box-shadow: none;
    border: 2px solid ${theme.colors.gray200};
  }
  `
)

export const Recommendation = ({
  id,
  kind,
  classification,
  title,
  imageUrl,
  eventList,
  price,
  priceFrom,
  weight,
  sku,
  quantity,
  imageAriaLabel,
  positionInList,
  isAgeRestricted,
  isAvailable,
  onItemClick,
  onRenderItem,
  slug,
}: RecommendationCardType) => {
  const [isCardHover, setIsCardHover] = useState(false)
  const { updateItemOnBag } = useUpdateCart({
    eventList,
    positionInList,
  })
  const itemCardRef = useRef<HTMLDivElement>(null)
  const { isRendered } = useOnScreen(itemCardRef)

  useEffect(() => {
    isRendered && onRenderItem && onRenderItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered])

  const addItemOnBag = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    updateItemOnBag({
      item: {
        id,
        imageUrl,
        kind,
        classification,
        name: title,
        sku,
        price,
        priceFrom,
        quantity,
        isAgeRestricted,
      },
      addend: 1,
    })
  }

  const onMouseOver = () => {
    setIsCardHover(true)
  }

  const onMouseLeave = () => {
    setIsCardHover(false)
  }

  return (
    <CardWrapper
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ariaLabel={t.bagRecommendationAriaLabel({
        title,
        price,
        priceFrom,
      })}
      onClick={onItemClick}
      slug={slug}
    >
      <div ref={itemCardRef}>
        <ItemImage
          url={imageUrl}
          ariaLabel={imageAriaLabel}
          isHover={isCardHover}
        />
        <Box px="x8" marginTop="x8">
          <Title
            color={'black'}
            textSize={'x14'}
            {...testId('product-card-title')}
          >
            {title}
          </Title>
        </Box>
      </div>

      <div>
        <Box px="x8">
          <PriceAndWeight price={price} priceFrom={priceFrom} weight={weight} />
        </Box>
        <Box py="x6">
          <AddButton
            type="button"
            isBlock
            onClick={addItemOnBag as () => void}
            isDisabled={!isAvailable}
          >
            {isAvailable ? t.buy : t.backSoon}
          </AddButton>
        </Box>
      </div>
    </CardWrapper>
  )
}
