import React from 'react'
import Img from 'next/image'
import { Anchor, Stack, Text, Box } from '@lojinha/design-system'
import { imgix } from '@lojinha/helpers'

import { DATA_FOOTER } from '../footer.i18n'

export const DowloadApp = () => {
  return (
    <Stack margin="x16">
      <Text textAlign={['center', 'center', 'left']} as="span">
        {DATA_FOOTER.downloadApp.title}
      </Text>
      <Box
        display="flex"
        marginRight={['x0', 'x24']}
        justifyContent={['center', 'center', 'flex-start']}
      >
        <Stack margin="x8" layout="horizontal">
          {DATA_FOOTER.downloadApp.stores.map(store => {
            return (
              <Anchor
                key={store.url}
                href={store.url}
                isExternal={store.externalLink}
              >
                <Img
                  loader={({ src }) => imgix.withParams(src)}
                  src={store.image}
                  alt={store.alt}
                  width={134}
                  height={40}
                  unoptimized
                />
              </Anchor>
            )
          })}
        </Stack>
      </Box>
    </Stack>
  )
}
