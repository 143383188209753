import { useEffect } from 'react'
import { default as NextRouter } from 'next/router'

export const Router = {
  prefetch: (url: string) => {
    NextRouter.prefetch(url)
  },
  push: (url: string) => {
    NextRouter.push(url)
  },
  replace: (url: string) => {
    NextRouter.replace(url)
  },
  back: () => {
    NextRouter.back()
  },
}

export const usePrefetch = (url: string) => {
  useEffect(() => {
    Router.prefetch(url)
  }, [url])
}
