import React, { FC } from 'react'
import styled from 'styled-components'
import Img from 'next/image'
import { Box, Text, Grid, Center } from '@lojinha/design-system'
import logo from '@lojinha/design-system/dist/assets/liv-up-logo.svg'

import { DATA_FOOTER } from './footer.i18n'
import { LinksArea } from './links-area'

const Main = styled.footer`
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: ${({ theme }) =>
    `${theme.space.x64} ${theme.space.x0} ${theme.space.x24} ${theme.space.x0}`};
`

const Line = styled.div`
  position: relative;
  width: 100%;
  border: 0.5px solid ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.space.x24};
`

export const Footer: FC = ({ children }) => {
  return (
    <Main>
      <Center htmlAttrs={{ style: { width: '100%' } }}>
        <Grid
          gridTemplateColumns="repeat(12, 1fr)"
          gridTemplateRows="repeat(6, 1fr)"
        >
          <Grid gridColumn={['6 / 8', '6 / 8', '2 / 5']}>
            <Box marginBottom="x32">
              <Img
                src={logo}
                alt={DATA_FOOTER.logoAlt}
                width={49}
                height={46}
                unoptimized
              />
            </Box>
          </Grid>
          <Grid
            gridColumn={['2 / 12', '2 / 12', '5 / 11']}
            gridRow={['1 / 12']}
          >
            <LinksArea />
          </Grid>
        </Grid>
        {children && (
          <Grid gridTemplateColumns="repeat(12, 1fr)">
            <Grid gridColumn={['2 / 12', '2 / 12', '5 / 12']}>
              <Box marginBottom="x32" marginTop="x32">
                {children}
              </Box>
            </Grid>
          </Grid>
        )}
        <Line />
        <Grid gridTemplateColumns="repeat(12, 1fr)">
          <Grid gridColumn={['2 / 12', '2 / 12', '5 / 11']}>
            <Text
              as="span"
              textSize={['x1', 'x2']}
              textAlign={['center', 'center', 'left']}
              color="black"
            >
              {DATA_FOOTER.copyright}
            </Text>
          </Grid>
        </Grid>
      </Center>
    </Main>
  )
}
