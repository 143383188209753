import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { beholder } from '@lojinha/beholder'
import { Button } from '@lojinha/design-system'
import { ArrowTop } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'
import { t } from '../../dictionary'

const ButtonWrapper = styled(Button)<{ isEnabled: boolean }>(
  ({ theme, isEnabled }) => `
    padding: 0 ${theme.space.x16};
    border: 1px solid rgb(236, 236, 236);
    transition: box-shadow 0.2s ease-in-out;
    background: ${theme.colors.white};

    &:hover,
    &:active,
    &:focus {
      box-shadow: ${theme.shadows.gray16};
      text-decoration: none;
    }

    ${theme.media.below('md')}{
      padding: 0;
    }

    ${theme.media.below('sm')}{
      font-size: 0;

      * {
        margin: 0;
      }
    }

    display: ${isEnabled ? 'flex' : 'none'} !important;
  `
)

const onClickButton = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })

  beholder.shot('structuredEvent', {
    category: 'product_lifecycle',
    action: 'click',
    label: 'back_to_top',
    property: 'product_view_detail',
  })
}

const sendEventOnEnabledButton = () => {
  beholder.shot('structuredEvent', {
    category: 'product_lifecycle',
    action: 'view',
    label: 'back_to_top',
    property: 'product_view_detail',
  })
}

export const BackToTopButton = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false)

  const onScrollPage = () => {
    const { scrollY } = window

    const summaryHeight = 492

    if (scrollY >= summaryHeight && !isEnabled) {
      return setIsEnabled(true)
    }

    if (scrollY < summaryHeight) {
      return setIsEnabled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScrollPage)

    return () => {
      window.removeEventListener('scroll', onScrollPage, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEnabled) {
      sendEventOnEnabledButton()
    }
  }, [isEnabled])

  return (
    <ButtonWrapper
      icon={<ArrowTop />}
      isLink
      isWrapper
      size="small"
      ariaLabel={t.backToTop}
      onClick={isEnabled ? onClickButton : undefined}
      {...testId('back-to-top-button')}
      {...{ isEnabled: isEnabled }}
    >
      {t.top}
    </ButtonWrapper>
  )
}
