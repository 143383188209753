import React from 'react'
import styled from 'styled-components'

import { CouponInput } from '@lojinha/coupon-input'
import { Box, Button, Stack, Text } from '@lojinha/design-system'
import { numberToCurrency, testId } from '@lojinha/helpers'

import { t } from '../dictionary'

export type Props = {
  total: number
  totalPriceFrom?: number
  buttonLabel: string
  isLoading?: boolean
  isFreeDeliveryTax: boolean
  onClickButton: () => void
  freeShippingValue?: number
}

const FreeDeliveryProgress = styled.div<{ current: number; max: number }>`
  background-color: ${({ theme }) => theme.colors.gray200};
  border-radius: 4px;
  height: 0.25rem;
  position: relative;
  width: 100%;

  &::after {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    content: '';
    height: 0.25rem;
    position: absolute;
    width: ${({ current, max }) =>
      ((current > max ? max : current) * 100) / max}%;
  }
`

const freeDeliveryInfo = ({
  freeShippingValue,
  isFreeDeliveryTax,
  total,
}: {
  freeShippingValue?: number
  isFreeDeliveryTax: boolean
  total: number
}) => {
  if (!freeShippingValue) return null

  return total >= freeShippingValue || isFreeDeliveryTax ? (
    <Text as="span" isBold textSize="x1" {...testId('free-delivery')}>
      {t.freeDelivery}
    </Text>
  ) : (
    <Text textSize="x1">
      {t.missing}{' '}
      <Text as="span" isBold {...testId('amount-needed')}>
        {numberToCurrency(freeShippingValue - total)}
      </Text>{' '}
      {t.for}{' '}
      <Text as="span" isBold>
        {t.freeDelivery.toLowerCase()}
      </Text>
    </Text>
  )
}

const footerAriaLabel = ({
  total,
  freeShippingValue,
}: {
  total: number
  freeShippingValue?: number
}) => {
  if (!freeShippingValue) return t.totalValue(total)

  const isFreeDelivery = total >= freeShippingValue

  return `${t.totalValue(total)} ${
    isFreeDelivery
      ? t.youWonFreeDelivery
      : t.freeDeliveryAmountNeededAriaLabel({
          amountNeeded: freeShippingValue ?? 0,
          total,
        })
  }`
}

export const BagFooter = ({
  total,
  totalPriceFrom = 0,
  buttonLabel,
  isLoading = false,
  onClickButton,
  freeShippingValue,
  isFreeDeliveryTax,
}: Props) => {
  return (
    <Box
      boxShadow="gray16"
      px={['x16', 'x40']}
      py={['x8', 'x24']}
      width="100%"
      htmlAttrs={{
        'aria-label': footerAriaLabel({ total, freeShippingValue }),
      }}
    >
      <Stack margin="x8">
        <Stack margin="x4">
          <CouponInput isCheckoutReview={false} />
        </Stack>
        <Stack margin="x4">
          <Stack layout="horizontal" margin="x8">
            <Text isBold>{numberToCurrency(total)}</Text>
            {totalPriceFrom > total && (
              <Text
                color="gray700"
                textDecoration="line-through"
                {...testId('total-price-from')}
              >
                {numberToCurrency(totalPriceFrom)}
              </Text>
            )}
          </Stack>
          {freeShippingValue && (
            <FreeDeliveryProgress
              current={isFreeDeliveryTax ? freeShippingValue : total}
              max={freeShippingValue}
            />
          )}
        </Stack>
        {freeDeliveryInfo({
          freeShippingValue,
          isFreeDeliveryTax,
          total,
        })}
        <Button
          isLoading={isLoading}
          color="success"
          isBlock
          onClick={onClickButton}
          {...testId('go-to-checkout')}
        >
          {buttonLabel}
        </Button>
      </Stack>
    </Box>
  )
}
