import React from 'react'
import NextLink from 'next/link'
import { HtmlAttrs } from '@lojinha/design-system/src/baseProps'
import { testId } from '@lojinha/helpers'
import { LinkSection } from '../../styles'

type Props = {
  label: string
  route?: string
  isSelected?: boolean
  isSecondary?: boolean
  htmlAttrs?: HtmlAttrs
  onClick?: () => void
}

export const MenuAnchor = ({
  route,
  label,
  isSelected,
  isSecondary,
  onClick,
  htmlAttrs,
}: Props) => {
  return route ? (
    <NextLink href={route} passHref>
      <LinkSection
        textDecoration={'none'}
        isBold={!isSecondary}
        textSize={'x14'}
        isSelected={isSelected}
        onClick={onClick}
        htmlAttrs={{
          ...htmlAttrs,
          as: 'a',
        }}
        {...testId('anchor-element')}
      >
        {label}
      </LinkSection>
    </NextLink>
  ) : (
    <LinkSection
      textDecoration={'none'}
      isBold={!isSecondary}
      textSize={'x14'}
      isSelected={isSelected}
      onClick={onClick}
      htmlAttrs={{
        ...htmlAttrs,
        style: {
          cursor: 'default',
        },
      }}
      {...testId('anchor-element')}
    >
      {label}
    </LinkSection>
  )
}
