import { gql } from '@lojinha/palantir'

export const NUMBER_OF_ORDERS_QUERY = gql`
  query NumberOfOrders {
    viewer {
      user {
        id
        sales {
          totalCount
        }
      }
    }
  }
`

export const REMOVE_UNAVAILABLE_ITEMS_MUTATION = gql`
  mutation RemoveUnavailableItemsFromCart(
    $distributionCenterId: String!
    $cartId: String!
  ) {
    removeUnavailableItemsFromCart(
      distributionCenterId: $distributionCenterId
      cartId: $cartId
    ) {
      removedItems {
        name
      }
      cart {
        items {
          nodes {
            id
            quantity
            item {
              id
              imageUrl
              kind
              sku
              name
              price
              priceFrom
              classification
              ... on Meal {
                mainProduct
                sideProducts
              }
            }
          }
        }
      }
    }
  }
`
