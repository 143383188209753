import { useLojinhaContext } from '@lojinha/context'
import { beholder } from '@lojinha/beholder'
import { CART_RECOMMENDATIONS } from './queries'
import { useQuery } from '@lojinha/palantir'
import { Recommendation, RecommendationEventItem } from './types'

export const useBagRecommendations = () => {
  const { bag, centerId: distributionCenterId } = useLojinhaContext()
  const { data } = useQuery(CART_RECOMMENDATIONS, {
    variables: {
      distributionCenterId,
      skusToFilter: [],
    },
  })

  const recommendations =
    data?.cartRecommendations.filter(
      (recommendation: Recommendation) =>
        !bag?.cartItems.find(({ item }) => item.sku === recommendation.sku)
    ) || []

  return {
    recommendations,
  }
}

export const useBagRecommendationsTrackers = () => {
  const { user } = useLojinhaContext()

  const trackImpression = (item: RecommendationEventItem) => {
    const { id, name, positionInList, listName, isAvailable, price } = item
    beholder.shot('itemView', {
      id,
      name,
      positionInList,
      listName,
      isAvailable,
      price,
    })
  }

  const trackProductDetailView = (item: RecommendationEventItem) => {
    beholder.shot('productDetailView', {
      id: item.id,
      name: item.name,
      positionInList: item.positionInList,
      listName: item.listName,
      isAvailable: item.isAvailable,
      price: item.price,
      user: user || {},
    })
  }

  return {
    trackImpression,
    trackProductDetailView,
  }
}
