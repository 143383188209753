import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const Search: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6303 14.611C12.2492 16.9894 8.38654 16.9894 6.00437 14.611C3.6222 12.2326 3.6222 8.37719 6.00437 5.99882C8.38654 3.62044 12.2492 3.62044 14.6303 5.99882C17.0125 8.37719 17.0125 12.2326 14.6303 14.611ZM16.1996 4.43318C12.9501 1.18894 7.68454 1.18894 4.43623 4.43318C1.18792 7.67522 1.18792 12.9336 4.43623 16.1767C7.41838 19.1552 12.1018 19.3977 15.3623 16.9075L20.1521 21.6886C20.5669 22.1038 21.2412 22.1038 21.657 21.6886L21.6881 21.6576C22.104 21.2424 22.104 20.5692 21.6881 20.1539L16.9038 15.3773C19.4268 12.1197 19.1917 7.42055 16.1996 4.43318Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

Search.defaultProps = {
  color: 'black',
  size: 'x2',
}
