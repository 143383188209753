import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Badge, BadgeColorPalette, Box, Text } from '@lojinha/design-system'
import { MarketableClassification, MarketableKind } from '@lojinha/palantir'
import { t } from '../../../dictionary'
import { getDiscountPercentage } from '../../../helpers/getDiscountPercentage'
import getPricePerMeal from '../../../helpers/getPricePerMeal'
import { useOnScreen } from '../../../helpers/useOnScreen'
import { useRefCallback } from '../../../helpers/useRefCallback'
import { AddToCartButton } from '../../add-to-cart-button'
import { BadgesGroup } from '../components/badges-group'
import { CardWrapper } from '../components/card'
import { ItemImage } from '../components/item-image'
import { Price } from '../components/price'
import { PricePerMeal } from '../components/price-per-meal'
import { ProductKitCardType } from '../types'

const Content = styled(Box)<{ isLarge: boolean }>(
  ({ theme, isLarge }) => `
  height: 100%;
  grid-template-columns: ${isLarge ? '88px auto max-content' : '88px auto'};
  ${isLarge ? 'grid-template-rows: 1fr;' : ''}

  grid-template-areas: 'image content' 'image content';
  grid-gap: ${theme.space.x16};
  grid-row-gap: 0;
  padding: ${theme.space.x24} ${theme.space.x16} ${theme.space.x16};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray200};
  border-radius: ${theme.space.x8};

  ${theme.media.below('sm')}{
    padding:
      ${theme.space.x16}
      ${theme.space.x8}
      ${theme.space.x8}
      ${theme.space.x16};
    flex-direction: column;
    justify-content: space-between;
    height: 176px;
  }
`
)

export const ProductCardKit = ({
  badges,
  classification,
  description,
  displayName,
  eventList,
  id,
  imageAriaLabel,
  imageUrl,
  isAgeRestricted,
  isAvailable,
  isLarge = false,
  kind,
  positionInList,
  price,
  priceFrom,
  pricePerMeal,
  quantity,
  sku,
  slug,
  title,
  firstOfferReplacement,
  onItemClick,
  onRenderItem,
}: ProductKitCardType) => {
  const [isCardHover, setIsCardHover] = useState(false)
  const [
    substitutionDiscountPercentage,
    setSubstitutionDiscountPercentage,
  ] = useState(0)

  const [itemCard] = useRefCallback<HTMLDivElement>()
  const { isRendered } = useOnScreen(itemCard)

  useEffect(() => {
    isRendered && onRenderItem && onRenderItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered])

  useEffect(() => {
    if (firstOfferReplacement?.firstBuy.price) {
      setSubstitutionDiscountPercentage(
        getDiscountPercentage(
          firstOfferReplacement.firstBuy.price,
          firstOfferReplacement.rebuy.priceFrom
        )
      )
    }
  }, [firstOfferReplacement])

  const onMouseOver = () => {
    setIsCardHover(true)
  }

  const onMouseLeave = () => {
    setIsCardHover(false)
  }

  return (
    <Box minHeight={['176px', isLarge ? '136px' : '192px']}>
      <CardWrapper
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        ariaLabel={t.productKitCardAriaLabel({
          title,
          badges,
          price,
          priceFrom,
          pricePerMeal: pricePerMeal ?? 0,
        })}
        onClick={onItemClick}
        slug={slug}
      >
        <Content
          display={['flex', 'grid']}
          isLarge={isLarge}
          css={{ height: 'max-content' }}
          htmlAttrs={{
            ref: itemCard,
          }}
        >
          <Box
            display={['none', 'flex']}
            alignItems="center"
            justifyContent="center"
            htmlAttrs={{ style: { gridArea: 'image' } }}
          >
            <ItemImage
              url={imageUrl}
              ariaLabel={imageAriaLabel}
              cardType="kit"
              isHover={isCardHover}
            />
          </Box>
          <Box alignSelf={['flex-start', 'center', 'center']}>
            <Box htmlAttrs={{ style: { height: '16px' } }}>
              {badges && <BadgesGroup badges={badges} />}
            </Box>
            <Box>
              <Text color={'black'} textSize={['x14', 'x2']}>
                {title}
              </Text>
              <Text
                textSize={['x1', 'x14']}
                color="gray700"
                htmlAttrs={{
                  style: {
                    height: '32px',
                    maxWidth: '446px',
                    overflow: 'hidden',
                  },
                  title: description,
                }}
              >
                {description}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            py={['x0', isLarge ? 'x8' : 'x0']}
          >
            <Box py="x8" px={['x0', isLarge ? 'x16' : 'x0']}>
              {firstOfferReplacement ? (
                <>
                  <Box
                    borderColor="error"
                    borderStyle="dashed"
                    borderWidth={1}
                    marginBottom="x4"
                    padding={'x4'}
                  >
                    <Box flexDirection="row" display="flex">
                      <Box css={{ width: 'fit-content' }}>
                        <Badge
                          key={'badge.content'}
                          text={'Oferta 1ª compra'}
                          colorPalette={BadgeColorPalette.Featured}
                        />
                      </Box>
                      {!!substitutionDiscountPercentage && (
                        <Text
                          color="error"
                          textSize="x1"
                          isBrand
                          textTransform="uppercase"
                          isBold
                          whiteSpace="nowrap"
                          css={{
                            width: 'fit-content',
                            marginLeft: '5px',
                            whiteSpaceCollapse: 'preserve',
                          }}
                        >
                          {`•  ${substitutionDiscountPercentage}% OFF`}
                        </Text>
                      )}
                    </Box>
                    <PricePerMeal
                      price={firstOfferReplacement.firstBuy.price}
                      priceFrom={0}
                      pricePerMeal={getPricePerMeal(
                        firstOfferReplacement.firstBuy.price,
                        firstOfferReplacement.firstBuy.totalMealsCount
                      )}
                    />
                  </Box>
                  <Box>
                    <PricePerMeal
                      price={firstOfferReplacement.rebuy.price}
                      priceFrom={firstOfferReplacement.rebuy.priceFrom ?? 0}
                      pricePerMeal={getPricePerMeal(
                        firstOfferReplacement.rebuy.price,
                        firstOfferReplacement.rebuy.totalMealsCount
                      )}
                    />
                  </Box>
                </>
              ) : !pricePerMeal || price === pricePerMeal ? (
                <Price price={price} priceFrom={priceFrom} />
              ) : (
                <PricePerMeal
                  price={price}
                  priceFrom={priceFrom}
                  pricePerMeal={pricePerMeal ?? 0}
                />
              )}
            </Box>
            <Box
              minWidth={['123px', '216px']}
              marginTop={['x0', isLarge ? 'x8' : 'x0']}
              alignSelf="center"
            >
              <AddToCartButton
                displayName={displayName}
                eventList={eventList}
                positionInList={positionInList}
                quantity={quantity ?? 0}
                isHover={isCardHover}
                isAvailable={isAvailable}
                isAgeRestricted={isAgeRestricted}
                item={{
                  id,
                  imageUrl,
                  kind: kind as MarketableKind,
                  classification: classification as MarketableClassification,
                  name: title,
                  price,
                  quantity: quantity ?? 0,
                  priceFrom,
                  sku,
                  firstOfferReplacement,
                }}
              />
            </Box>
          </Box>
        </Content>
      </CardWrapper>
    </Box>
  )
}
