import { RefObject, useCallback, useRef } from 'react'

export const useRefCallback = <T>(): [RefObject<T>, (node: T) => void] => {
  const ref = useRef<T | null>(null)
  const setRef = useCallback((node: T) => {
    if (ref.current) {
      // TODO: Add cleanup function if necessary
    }

    if (node) {
      // TODO: Add callback function if necessary
    }

    ref.current = node

    return ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [ref, setRef]
}
