import { Box, Grid } from '@lojinha/design-system'
import React from 'react'
import { sanitizeImage } from '@lojinha/helpers'
import { t } from '../../../dictionary'
import {
  OnItemClickType,
  OnRenderItemType,
  OriginList,
} from '../../../modules/display/types'
import { DisplayItems } from '../../../modules/page-content/page-content.types'
import { ProductCardDish } from '../../product-card'
import { User } from '@lojinha/context/src/types'

type MealCardListProps = {
  name?: string
  items: DisplayItems[]
  onRenderItem?: OnRenderItemType
  onItemClick?: OnItemClickType
  originList: OriginList
  user?: User
}

export const MealCardList = ({
  name,
  items,
  originList,
  onRenderItem,
  onItemClick,
  user,
}: MealCardListProps) => {
  return (
    <Grid
      gridTemplateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
      gridGap="x16"
      gridRowGap="x32"
    >
      {items.map((item, index) => {
        return (
          <Box key={item.id} height={['352px', '536px']}>
            <ProductCardDish
              id={item.id}
              kind={item.kind}
              classification={item.classification}
              title={item.mainProduct ?? item.name}
              description={item.subtitle || item.sideProducts?.join(' + ')}
              badges={item.itemTags.nodes}
              isAvailable={item.isAvailable}
              imageUrl={sanitizeImage({
                imageUrl: item.imageUrl,
                size: 'big',
              })}
              price={item.price || 0}
              priceFrom={item.priceFrom || 0}
              weight={t.weightAndUnit(item.weight, item.unit)}
              quantity={0}
              sku={item.sku}
              imageAriaLabel={item.name}
              eventList={originList}
              positionInList={index}
              onRenderItem={() =>
                onRenderItem &&
                onRenderItem({
                  id: item.id,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              onItemClick={() =>
                onItemClick &&
                onItemClick({
                  sku: item.sku,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  originList: originList,
                  isAvailable: item.isAvailable,
                  user,
                })
              }
              slug={item.seo.url}
              displayName={name}
            />
          </Box>
        )
      })}
    </Grid>
  )
}
