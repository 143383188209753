import {
  AddressGeoSearchQuery,
  AddressGeoSearchQueryVariables,
  AddressSearchQuery,
  AddressSearchQueryVariables,
  CheckCoordinatesQuery,
  CheckCoordinatesQueryVariables,
  Coordinates,
  SearchAddressResponse,
  useApolloClient,
} from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'
import { MASKED_ZIP_CODE_LENGTH } from '../constants'
import {
  ADDRESS_GEO_SEARCH_QUERY,
  ADDRESS_SEARCH_QUERY,
  CHECK_COORDINATES,
} from './../queries'
import { UseZipCode, ZipCodeData, ZipCodeResult } from './types'

export const useZipCode = (): UseZipCode => {
  const client = useApolloClient()

  const fetchAddressByZipCode = async (
    { zipCode, number }: { zipCode: string; number?: string },
    geolocation?: boolean
  ): Promise<Partial<ZipCodeResult>> => {
    if (!zipCode || zipCode.length < MASKED_ZIP_CODE_LENGTH) {
      return {}
    }

    let address: SearchAddressResponse | undefined | null | void

    if (geolocation) {
      address = await client
        .query<AddressGeoSearchQuery, AddressGeoSearchQueryVariables>({
          query: ADDRESS_GEO_SEARCH_QUERY,
          variables: {
            input: {
              zipCode,
              number,
            },
          },
        })
        .then(queryResult => queryResult?.data?.searchAddress)
        .catch(captureException)
    } else {
      address = await client
        .query<AddressSearchQuery, AddressSearchQueryVariables>({
          query: ADDRESS_SEARCH_QUERY,
          variables: {
            input: {
              zipCode,
              number,
            },
          },
        })
        .then(queryResult => queryResult?.data?.searchAddress)
        .catch(captureException)
    }

    if (!address || !address.city || !address.state) {
      return {}
    }
    return {
      city: address.city,
      country: address.country,
      neighborhood: address.neighborhood,
      number: address.number || '',
      state: address.state,
      street: address.street,
      zipCode: address.zipCode,
      coordinates: {
        latitude: address.coordinates?.latitude,
        longitude: address.coordinates?.longitude,
      },
    }
  }

  const validateCordinates = async (
    coordinates: Coordinates
  ): Promise<{ valid: boolean; nearestDistributionCenter?: string }> => {
    try {
      const { data } = await client.query<
        CheckCoordinatesQuery,
        CheckCoordinatesQueryVariables
      >({
        query: CHECK_COORDINATES,
        variables: {
          input: {
            coordinates,
          },
        },
      })

      const { valid, ecommDistributionCenter } = data.validateCoordinates

      return Promise.resolve({
        valid,
        nearestDistributionCenter: ecommDistributionCenter?.id,
      })
    } catch (err: any) {
      captureException(err)
      return Promise.resolve({ valid: false })
    }
  }

  const checkCoordinates = async (zipCodeData: ZipCodeData) => {
    const address = await fetchAddressByZipCode(zipCodeData, true)
    const coordinates = address?.coordinates
    if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
      return {
        valid: false,
      }
    }

    const { valid, nearestDistributionCenter } = await validateCordinates({
      latitude: coordinates.latitude!,
      longitude: coordinates.longitude!,
    })
    return {
      valid,
      address,
      nearestDistributionCenter,
    }
  }

  return {
    checkCoordinates,
    fetchAddressByZipCode,
    validateCordinates,
  }
}
