import { LocationPointer } from '@lojinha/design-system/src/icons'
import React from 'react'
import { HeaderDesktopButton } from '../../header-desktop-button'

export const DropdownAnchor = () => {
  return (
    <HeaderDesktopButton
      icon={<LocationPointer color="black" />}
      secondaryText={''}
      mainText={''}
      textEllipsis
    />
  )
}
