import React, { FC } from 'react'
import { ColorProps, FontSizeProps, theme } from '../../../theme'

export const ChatBubble: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => (
  <svg
    width={theme.fontSizes[size!]}
    height={theme.fontSizes[size!]}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 2C9.44027 2 6.88155 2.97543 4.92884 4.92903C1.79141 8.06551 1.17539 12.768 3.07714 16.5214V20.9227H7.47846C8.89613 21.6406 10.4485 22 12.0008 22C14.5595 22 17.1182 21.0237 19.0709 19.0719C22.9764 15.1665 22.9764 8.83439 19.0709 4.92903C17.1182 2.97543 14.5595 2 11.9999 2ZM11.9999 4C14.1375 4 16.1457 4.8318 17.6569 6.34304C19.1682 7.85428 20 9.86336 20 12C20 14.1366 19.1682 16.1457 17.6569 17.657C16.1466 19.1673 14.1375 20 12.0008 20C10.7456 20 9.49473 19.7024 8.38299 19.1381L8.0136 18.9503H7.59813H5.04955V16.4017V15.9863L4.86175 15.6169C4.48615 14.8745 4.22925 14.0808 4.09904 13.2552C3.9697 12.4385 3.96704 11.6111 4.09106 10.7935C4.34796 9.09888 5.12662 7.56018 6.34289 6.34304C7.85414 4.8318 9.86325 4 11.9999 4Z"
      fill={theme.colors[color!]}
    />
  </svg>
)

ChatBubble.defaultProps = {
  color: 'success',
  size: 'x3',
}
