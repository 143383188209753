import { Box, Grid } from '@lojinha/design-system'
import { imgix, sanitizeImage } from '@lojinha/helpers'
import Img from 'next/image'
import React, { FC } from 'react'
import styled from 'styled-components'
import { LandscapeImageLayout, PortraitImageLayout } from '../../../../types'

type MenuImageProps = {
  image: PortraitImageLayout | LandscapeImageLayout
}

const StyledImg = styled(Img)`
  width: 100%;
  object-fit: cover;
`

const PortraitBox = styled(Box)<{ isMobile?: boolean }>(
  ({ isMobile, theme }) => `
  width: 100%;
  height: ${isMobile ? 'auto' : '100%'};
  
  > div {
    border-radius: ${theme.borderRadius.x8};
    width: 100%;
    height: ${isMobile ? 'auto' : '100%'};
  } 
  `
)

const DesktopImageBox = styled(Box)(
  ({ theme }) => `
  > div {
    height: 100%;
    width: 100%;
    border-radius: ${theme.borderRadius.x8};
    
    img {
      object-fit: cover;
    }
  }
`
)

const ImageBox = styled.div(
  ({ theme }) => `
  display: flex;
  min-width: max-content;

  & div {
    max-width: calc(90vw - ${theme.space.x24});
    width: 308px;
    object-fit: cover;
    border-radius: ${theme.borderRadius.x8};
    
    &:not(:first-child) {
      margin-left: ${theme.space.x8};
    }
  }
`
)

const getLoader = (
  imageUrl: string,
  height: number,
  width: number,
  orient?: number
): string => {
  const url = sanitizeImage({ imageUrl, type: '.png' })

  return imgix.withParams(`${imgix.DOMAIN}${url}`, {
    h: height,
    w: width,
    fm: 'webp',
    orient,
  })
}

export const MobileMenuImage: FC<MenuImageProps> = ({ image }) => {
  if ('soloImage' in image) {
    return (
      <PortraitBox isMobile px={'x32'} paddingBottom={['x64', 'x32']}>
        <StyledImg
          alt={image.soloImage.accessibilityLabel}
          src={image.soloImage.url}
          height={208}
          width={308}
          loader={({ src }) => getLoader(src, 208, 308, 270)}
        />
      </PortraitBox>
    )
  }

  return (
    <Box marginBottom="x40" padding={'x32'} overflowX="scroll">
      <ImageBox>
        <Box>
          <StyledImg
            src={image.imageTop.url}
            alt={image.imageTop.accessibilityLabel}
            width={308}
            height={208}
            loader={({ src }) => getLoader(src, 208, 308)}
          />
        </Box>
        <Box>
          <StyledImg
            src={image.imageBottom.url}
            alt={image.imageBottom.accessibilityLabel}
            width={308}
            height={208}
            loader={({ src }) => getLoader(src, 208, 308)}
          />
        </Box>
      </ImageBox>
    </Box>
  )
}

export const DesktopMenuImage: FC<MenuImageProps> = ({ image }) => {
  if ('soloImage' in image) {
    return (
      <PortraitBox>
        <StyledImg
          alt={image.soloImage.accessibilityLabel}
          src={image.soloImage.url}
          height={308}
          width={424}
          loader={({ src }) => getLoader(src, 308, 424)}
        />
      </PortraitBox>
    )
  }
  return (
    <Grid
      gridColumnGap={'x8'}
      htmlAttrs={{
        style: {
          height: '100%',
        },
      }}
    >
      <DesktopImageBox>
        <StyledImg
          alt={image.imageTop.accessibilityLabel}
          src={image.imageTop.url}
          height={208}
          width={307}
          loader={({ src }) => getLoader(src, 208, 307)}
        />
      </DesktopImageBox>
      <DesktopImageBox>
        <StyledImg
          alt={image.imageBottom.accessibilityLabel}
          src={image.imageBottom.url}
          height={208}
          width={307}
          loader={({ src }) => getLoader(src, 208, 307)}
        />
      </DesktopImageBox>
    </Grid>
  )
}
