import React from 'react'
import { Box, Skeleton, theme } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'

export const WillDeliveryLoading = () => (
  <Box width="100px" {...testId('will-delivery-loading')}>
    <Box
      display="flex"
      height={theme.space.x32}
      flexDirection="column"
      justifyContent="space-evenly"
    >
      <Skeleton
        htmlAttrs={{
          style: {
            height: theme.space.x8,
          },
        }}
      />
      <Skeleton
        htmlAttrs={{
          style: {
            height: theme.space.x8,
          },
        }}
      />
    </Box>
  </Box>
)
