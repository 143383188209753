import React from 'react'
import { Grid, Stack } from '@lojinha/design-system'
import { DATA_FOOTER } from '../footer.i18n'
import { Group } from './group'
import { LinkColumn } from './link-column'

export const Links = () => (
  <Grid gridTemplateColumns="repeat(6, 1fr)">
    <Grid
      gridColumn={['1 / 7', '1 / 7', '1 / 3']}
      display={['block', 'block', 'grid']}
    >
      <Group title={DATA_FOOTER.comeMeet.title}>
        <LinkColumn data={DATA_FOOTER.comeMeet} />
      </Group>
    </Grid>
    <Grid
      gridColumn={['1 / 7', '1 / 7', '3 / 5']}
      display={['block', 'block', 'grid']}
    >
      <Group title={DATA_FOOTER.bePartner.title}>
        <LinkColumn data={DATA_FOOTER.bePartner} />
      </Group>
    </Grid>
    <Grid
      gridColumn={['1 / 7', '1 / 7', '5 / 7']}
      display={['block', 'block', 'grid']}
    >
      <Group title={DATA_FOOTER.contactUs.title}>
        <Stack margin="x32">
          <LinkColumn data={DATA_FOOTER.contactUs} />
          <LinkColumn data={DATA_FOOTER.helpTermsPolicies} />
        </Stack>
      </Group>
    </Grid>
  </Grid>
)
