import React from 'react'
import styled from 'styled-components'
import { useLojinhaContext } from '@lojinha/context'
import { Center, Grid, theme } from '@lojinha/design-system'
import { RecommendationsCarousel } from './recommendations-carousel'
import { BagRecommendationsDisplayProps } from './types'

const RecommendationsGrid = styled(Grid)<{
  isRecommendationsClosed: boolean | undefined
}>`
  max-height: ${({ isRecommendationsClosed }) =>
    isRecommendationsClosed ? '70px' : '500px'};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  ${theme.media.below('sm')} {
    max-height: ${({ isRecommendationsClosed }) =>
      isRecommendationsClosed ? '60px' : '500px'};
  }
`
export const RecommendationsDisplay = ({
  name,
  items,
  originList,
  onRenderItem,
  onItemClick,
}: BagRecommendationsDisplayProps) => {
  const {
    isCartRecommendationsClosed,
    toggleCartRecommendations,
  } = useLojinhaContext()

  return (
    <Center>
      <RecommendationsGrid
        gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}
        isRecommendationsClosed={isCartRecommendationsClosed}
        css={`
          ${!items.length &&
          `max-height: 0px;
           padding-bottom: 0px;`}
        `}
      >
        <Grid
          gridColumn={['2 / 24', '2 / 12']}
          display="block"
          htmlAttrs={{
            style: {
              maxWidth: '100%',
            },
          }}
        >
          <RecommendationsCarousel
            name={name}
            items={items}
            originList={originList}
            isOpen={!isCartRecommendationsClosed}
            onItemClick={onItemClick}
            onRenderItem={onRenderItem}
            onToggleClick={toggleCartRecommendations}
          />
        </Grid>
      </RecommendationsGrid>
    </Center>
  )
}
