import { tabNavigationStyle } from '@lojinha/design-system'
import { Location } from '@lojinha/design-system/src/icons'
import React, { FC } from 'react'
import styled from 'styled-components'

export type LocationButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ButtonWrapper = styled.button(
  ({ theme }) => `
    cursor: pointer;
    background: none;
    border: none;
    width: ${theme.space.x48};
    height: ${theme.space.x48};
  `,
  tabNavigationStyle
)

export const LocationButton: FC<LocationButtonProps> = ({ onClick }) => {
  return (
    <ButtonWrapper
      onClick={event => {
        event.preventDefault()
        onClick && onClick(event)
      }}
    >
      <Location />
    </ButtonWrapper>
  )
}
