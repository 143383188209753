import { Box, Center, Grid, theme } from '@lojinha/design-system'
import React, { FC } from 'react'
import { AddressData, useSaveAddress } from '@lojinha/location'
import { Address } from '@lojinha/context/src/types'
import { useShippingValue } from '@lojinha/bag/src/bag/hooks'
import { AddressListDropdown } from '../address-list-dropdown'
import { t } from '../../dictionary'
import { WillDeliverAnchor } from './anchor'

export type WillDeliverProps = {
  onClickWillDeliver: () => void
  address?: string
}

export const WillDeliver: FC<WillDeliverProps> = ({
  onClickWillDeliver,
  address,
}) => {
  const { requestRefetchShippingValue } = useShippingValue()
  const { onSubmit } = useSaveAddress({ onCreate: requestRefetchShippingValue })

  const handleOnSubmit = async (addressSelected: Address) => {
    await onSubmit(addressSelected as AddressData)
  }

  return (
    <Box
      display={['block', 'block', 'none']}
      borderStyle="solid"
      borderWidth={0}
      borderColor="gray200"
      borderBottomWidth={1}
      py={'x4'}
      htmlAttrs={{ style: { backgroundColor: theme.colors.gray100 } }}
    >
      <Center>
        <Grid
          gridTemplateColumns={['repeat(12, 1fr)']}
          htmlAttrs={{ style: { width: '100%' } }}
        >
          <Box htmlAttrs={{ style: { gridColumn: '2/ 12' } }}>
            {address ? (
              <AddressListDropdown
                address={address}
                onClickWillDelivery={onClickWillDeliver}
                onSelectAddress={handleOnSubmit}
                anchorElement={
                  <WillDeliverAnchor
                    text={address}
                    testIdText={'will-deliver-address'}
                  />
                }
              />
            ) : (
              <WillDeliverAnchor
                text={t.willDelivery}
                testIdText={'will-deliver-question'}
                onClickWillDeliver={onClickWillDeliver}
              />
            )}
          </Box>
        </Grid>
      </Center>
    </Box>
  )
}
