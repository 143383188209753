import { useLojinhaContext } from '@lojinha/context'
import { Router } from '../../../../router'

export const useLoginModal = () => {
  const { loginModal, setLoginModal } = useLojinhaContext()

  const setLoginModalStates = () => {
    setLoginModal(false)
    document.cookie = '_login_modal=true'
  }

  const onConfirmLogin = () => {
    setLoginModalStates()
    Router.push('/identification')
  }

  const onDenyLogin = () => {
    setLoginModalStates()
  }

  return {
    loginModal,
    onConfirmLogin,
    onDenyLogin,
  }
}
