import React, { FC } from 'react'
import { theme } from '@lojinha/design-system'

export const Whatsapp: FC<{ color?: string }> = ({
  color = theme.colors.black,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <g
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#prefix__clip0_whatsapp)"
        clipRule="evenodd"
      >
        <path d="M11 1c5.523 0 10 4.477 10 10s-4.477 10-10 10c-1.967 0-3.802-.568-5.35-1.55L1 21l1.55-4.649C1.57 14.804 1 12.968 1 11 1 5.477 5.477 1 11 1z" />
        <path d="M9.808 6.27c.7.7.7 1.837 0 2.538l-.423.423 3.384 3.384.423-.423c.701-.7 1.838-.7 2.539 0 .7.701.7 1.838 0 2.539L14.46 16C9.788 16 6 12.212 6 7.538L7.27 6.27c.7-.7 1.837-.7 2.538 0z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_whatsapp">
          <path fill="#fff" d="M0 0H22V22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
