import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const ArrowTop: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.566 0.1845L17.606 6.0415C17.854 6.2225 18 6.5105 18 6.8175V6.9545C18 7.7415 17.105 8.1935 16.471 7.7265L10.382 3.2395C10.224 3.1225 10 3.2355 10 3.4325V18.7935C10 19.3235 9.57 19.7535 9.04 19.7535H8.96C8.43 19.7535 8 19.3235 8 18.7935V3.4335C8 3.2355 7.776 3.1225 7.618 3.2395L1.53 7.7315C0.897 8.1995 0 7.7465 0 6.9595V6.8245C0 6.5175 0.147 6.2285 0.395 6.0485L8.435 0.1845C8.772 -0.0615 9.229 -0.0615 9.566 0.1845Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

ArrowTop.defaultProps = {
  color: 'black',
  size: 'x2',
}
