import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const Pix: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m 11.533116,13.30891 c 0.190098,-0.190393 0.517464,-0.190393 0.707544,0 l 2.71062,2.713715 c 0.49986,0.500538 1.16523,0.77545 1.8693,0.77545 h 0.53154 l -3.4182,3.422127 c -1.06659,1.039731 -2.798595,1.039731 -3.865239,0 L 6.6364227,16.787443 h 0.327384 c 0.704052,0 1.369386,-0.274913 1.869264,-0.77536 z m 0.707544,-2.594001 c -0.22527,0.193836 -0.513927,0.197368 -0.707544,0 L 8.8330707,8.0116917 C 8.3331927,7.4794998 7.6678587,7.2363224 6.9638067,7.2363224 H 6.6364227 L 10.065162,3.8021368 c 1.070163,-1.0695157 2.802168,-1.0695157 3.868758,0 l 3.42171,3.4236072 h -0.53505 c -0.70407,0 -1.36944,0.2749036 -1.8693,0.7753692 z M 6.9638067,8.0293073 c 0.485793,0 0.932868,0.1973677 1.306017,0.5427611 l 2.7000453,2.7032176 c 0.253467,0.222039 0.58437,0.380634 0.918792,0.380634 0.330939,0 0.661779,-0.158595 0.915309,-0.380634 L 15.5145,8.561499 c 0.34506,-0.3418703 0.82026,-0.5392379 1.30608,-0.5392379 h 1.32714 l 2.05227,2.0547269 c 1.06668,1.067897 1.06668,2.801937 0,3.869781 l -2.05227,2.054772 h -1.32714 c -0.48582,0 -0.96102,-0.197422 -1.30608,-0.542798 l -2.71053,-2.713805 c -0.48933,-0.489905 -1.34478,-0.489905 -1.834101,0.0035 l -2.7000452,2.699749 c -0.373149,0.345375 -0.8202241,0.542707 -1.306017,0.542707 H 5.8436578 L 3.801196,13.946769 c -1.0682613,-1.067844 -1.0682613,-2.801884 0,-3.869781 L 5.8436577,8.0293073 Z"
        fill="#37b09d"
      />
    </svg>
  )
}

Pix.defaultProps = {
  color: 'black',
  size: 'x3',
}
