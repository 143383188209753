import React from 'react'
import { Text, Stack, Anchor, Box } from '@lojinha/design-system'
import { imgix } from '@lojinha/helpers'
import Img from 'next/image'

import { DATA_FOOTER } from '../footer.i18n'

export const SecurityBadges = () => {
  return (
    <Stack margin="x16">
      <Text textAlign={['center', 'center', 'left']} as="span">
        {DATA_FOOTER.secureWebsite.title}
      </Text>
      <Box display="flex" justifyContent={['center', 'center', 'flex-start']}>
        <Stack
          margin="x8"
          layout="horizontal"
          htmlAttrs={{ style: { maxWidth: '300px' } }}
        >
          {DATA_FOOTER.secureWebsite.badges.map(badge => {
            return (
              <Anchor
                key={badge.alt}
                href={badge.url}
                isExternal={badge.externalLink}
                htmlAttrs={{ style: { maxWidth: '50%' } }}
              >
                <Img
                  loader={({ src }) => imgix.withParams(src)}
                  src={badge.image}
                  alt={badge.alt}
                  loading="lazy"
                  width={180}
                  height={60}
                  unoptimized
                />
              </Anchor>
            )
          })}
        </Stack>
      </Box>
    </Stack>
  )
}
