import { gql } from '@lojinha/palantir'

export const CART_SYNC = gql`
  mutation CartSync($input: CartSyncInput) {
    cartSync(input: $input) {
      cart {
        id
      }
    }
  }
`
