import React from 'react'
import styled from 'styled-components'
import NextLink from 'next/link'

import { Box, Anchor, LogoLivup, theme } from '@lojinha/design-system'
import { SpaceProps } from '@lojinha/design-system/src/theme'

import { testId } from '@lojinha/helpers'
import { t } from '../../dictionary'

const LogoLink = styled(Anchor)`
  min-height: ${theme.space.x48};
  min-width: ${theme.space.x48};
  display: flex;
  align-items: center;
`

export const Logo = ({ height }: { height: SpaceProps }) => {
  return (
    <Box display="flex">
      <NextLink href="/" passHref>
        <LogoLink aria-label={t.goToHomeAlt}>
          <LogoLivup
            height={theme.space[height]}
            {...testId('logo-link-svg')}
          />
        </LogoLink>
      </NextLink>
    </Box>
  )
}

Logo.defaultProps = {
  height: 'x56',
}
