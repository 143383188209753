import { sortBy } from '@lojinha/helpers'
import { DeliveryAddressListQuery } from '@lojinha/palantir'
import { Address } from '@lojinha/context/src/types'
import { omit } from './omit'

type Nullable<T> = { [P in keyof T]?: T[P] | null | undefined }
type AddressIdParts = Pick<Address, 'zipCode' | 'number' | 'complement'>

export const makeAddressId = (addr: AddressIdParts) =>
  `${addr.zipCode}.${addr.number || 0}.${addr.complement || ''}`

const addressId = (address: Address): Address => ({
  ...address,
  id: makeAddressId(address),
})

const isComplete = ({
  street,
  zipCode,
  neighborhood,
  city,
  state,
}: Nullable<Address>) => !!(street && zipCode && neighborhood && city && state)

export const getDeliveryAddresses = (data?: DeliveryAddressListQuery) => {
  const deliveryAddresses = ((data?.viewer.user.addresses.nodes
    .filter(({ __typename }) => __typename === 'DeliveryAddress')
    .filter(isComplete)
    .map(address => {
      return {
        ...omit({ item: address, keys: ['__typename'] }),
        kind: address.__typename === 'DeliveryAddress' ? 'DELIVERY' : 'PICKUP',
      }
    }) as any) || []) as Address[]

  const addresses = sortBy(
    deliveryAddresses,
    address => new Date(address.lastUsedAt!).getTime(),
    'desc'
  )
    .map<Address>(addressId)
    .reduce<Address[]>((acc, address) => {
      const exists = acc.find(({ id }) => id === address.id)
      return exists ? acc : [...acc, address]
    }, [])
    .filter((_address, i) => i < 4)

  return addresses
}
