import React, { FC } from 'react'
import { Menu } from '../../../types'
import { MenuMobileUI } from './ui'
import { useMenuNavigation } from './hook'

type MenuMobileProps = {
  menu: Menu[]
}

export const MenuMobile: FC<MenuMobileProps> = ({ menu }) => {
  const mobileMenu = menu.map(item => ({
    ...item,
    ...(item.children?.length ? { route: undefined } : {}),
  }))

  const {
    isMenuOpen,
    onCloseMenu,
    onOpenMenu,
    selectedSubMenu,
    handleClickMenu,
    goBackOnMenu,
  } = useMenuNavigation(mobileMenu)

  return (
    <MenuMobileUI
      onGoBack={goBackOnMenu}
      menuItems={mobileMenu}
      handleClickMenu={handleClickMenu}
      isMenuOpen={isMenuOpen}
      onCloseMenu={onCloseMenu}
      onOpenMenu={onOpenMenu}
      selectedSubMenu={selectedSubMenu}
    />
  )
}
