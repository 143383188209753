import { tabNavigationOutline } from '@lojinha/design-system'
import { Minus } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'
import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  isSmall?: boolean
  isBig?: boolean
  removeItem: () => void
  ariaLabel: string
}

const removeHoverStyle = css`
  ${({ theme }) => `
    border: 2px solid ${theme.colors.success};
    transition: border 0.2s ease-in-out;
  `}
`

const RemoveButton = styled.div<{ isSmall: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: border 0.2s ease-in-out, opacity 0.2s ease-in-out 250ms,
    display 0.2s ease-in-out;

  ${({ theme }) => `
    background-color: ${theme.colors.white};
    width: ${theme.space.x32};
    height: ${theme.space.x32};
    border: 2px solid ${theme.colors.gray200};
    border-radius: ${theme.space.x8};
  `}

  ${({ theme, isSmall }) =>
    isSmall
      ? `width: ${theme.space.x32}`
      : `
    ${theme.media.above('md')}{
      width: 100%;
    }
  `}
`

const Wrapper = styled.button<{ isSmall: boolean; isBig?: boolean }>`
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, isSmall, isBig }) => `
    width:  ${isBig ? theme.space.x72 : theme.space.x48};
    padding: ${isBig ? '0px' : theme.space.x8};
    min-height: ${theme.space.x48};
    min-width: ${theme.space.x48};
    background-color: ${theme.colors.transparent};

    ${
      isBig
        ? `
        ${RemoveButton} {
          height: ${theme.space.x48};
          width: ${theme.space.x72};
        }
      `
        : ''
    }
    
    ${
      isSmall
        ? `width: ${theme.space.x48};`
        : `
        ${theme.media.above('md')}{
          width: ${isBig ? theme.space.x72 : theme.space.x88};
        }
      `
    }
  `}

  &:hover {
    ${RemoveButton} {
      ${removeHoverStyle}
    }
  }

  &:focus:not(:focus-visible) {
    ${RemoveButton} {
      outline: none;
    }
  }

  &:focus-visible {
    ${RemoveButton} {
      ${tabNavigationOutline}
    }
  }
`

export const RemoveButtonUI = ({
  isSmall = false,
  isBig = false,
  removeItem,
  ariaLabel,
}: Props) => {
  return (
    <Wrapper
      isSmall={isSmall}
      aria-label={ariaLabel}
      isBig={isBig}
      onClick={() => removeItem()}
      {...testId('remove-from-cart-button-vitrine')}
    >
      <RemoveButton isSmall={isSmall}>
        <Minus color="success" />
      </RemoveButton>
    </Wrapper>
  )
}
