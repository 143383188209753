import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const Menu: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4C21.5523 4 22 4.44772 22 5C22 5.51284 21.614 5.93551 21.1166 5.99327L21 6H3C2.44772 6 2 5.55228 2 5C2 4.48716 2.38604 4.06449 2.88338 4.00673L3 4H21ZM21 11C21.5523 11 22 11.4477 22 12C22 12.5128 21.614 12.9355 21.1166 12.9933L21 13H3C2.44772 13 2 12.5523 2 12C2 11.4872 2.38604 11.0645 2.88338 11.0067L3 11H21ZM22 19C22 18.4477 21.5523 18 21 18H3L2.88338 18.0067C2.38604 18.0645 2 18.4872 2 19C2 19.5523 2.44772 20 3 20H21L21.1166 19.9933C21.614 19.9355 22 19.5128 22 19Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

Menu.defaultProps = {
  color: 'black',
  size: 'x2',
}
