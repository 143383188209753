import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const UserCircle: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C13.6622 20 15.206 19.4931 16.4852 18.6254C15.2699 17.6099 13.7066 17 12 17C10.2934 17 8.73007 17.6099 7.51481 18.6254C8.79403 19.4931 10.3378 20 12 20ZM12 15C14.305 15 16.4084 15.8675 18 17.2917C19.2447 15.8814 20 14.0289 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.0289 4.75529 15.8814 6.00004 17.2917C7.59158 15.8675 9.69497 15 12 15ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10ZM12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

UserCircle.defaultProps = {
  color: 'black',
  size: 'x2',
}
