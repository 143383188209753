import React from 'react'

import {
  Text,
  MenuDropdown,
  Button,
  tabNavigationStyle,
} from '@lojinha/design-system'
import styled from 'styled-components'
import { Menu } from '../../types'

type Props = {
  anchorElement: React.ReactElement
  menuItems: Menu[]
  onClick: (item: Menu) => void
  onFocused?: (event: React.FocusEvent | React.MouseEvent) => void
  route?: string
}

const ListItem = styled.li(
  ({ theme }) => `
    list-style: none;
    cursor: pointer;

    p {
      color: ${theme.colors.black};
    }

    &:hover,
    &:active,
    &:focus {
      p {
        color: ${theme.colors.success};
      }
    }

    &:hover {
      border-radius: 0;
      background: ${theme.colors.gray100};
    }
  `,
  tabNavigationStyle
)

function selectPreviousElement(element: HTMLElement): void {
  const nextElement = (element.previousSibling ||
    element.parentNode?.lastChild) as HTMLElement

  if (!nextElement) {
    return
  }

  const button = nextElement.getElementsByTagName('button')[0]
  if (!button) {
    return
  }

  button.focus()
}

function selectNextElement(element: HTMLElement): void {
  const nextElement = (element.nextSibling ||
    element.parentNode?.firstChild) as HTMLElement

  if (!nextElement) {
    return
  }

  const button = nextElement.getElementsByTagName('button')[0]
  if (!button) {
    return
  }

  button.focus()
}

function handleKeyDown(event: React.KeyboardEvent<HTMLElement>): void {
  const currentElement = event.currentTarget
  if (!currentElement) {
    return
  }

  switch (event.key) {
    case 'ArrowDown': {
      return selectNextElement(currentElement)
    }
    case 'ArrowUp': {
      return selectPreviousElement(currentElement)
    }
  }
}

export const MenuHeaderDropdown = ({
  anchorElement,
  menuItems,
  onClick,
  route,
}: Props) => {
  const onClickItem = (item: Menu) => () => onClick(item)

  return (
    <MenuDropdown anchorElement={anchorElement} route={route}>
      <ul>
        {menuItems?.map(item => (
          <ListItem key={item.id} onKeyDown={handleKeyDown}>
            <Button
              isLink
              isBlock
              onClick={onClickItem(item)}
              ariaLabel={item.title}
              htmlAttrs={{
                ref: item.reference,
                tabIndex: 0,
              }}
            >
              <Text textSize={'x12'} isBold>
                {item.title}
              </Text>
            </Button>
          </ListItem>
        ))}
      </ul>
    </MenuDropdown>
  )
}
