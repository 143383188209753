import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '@lojinha/design-system'
import { t } from '../dictionary'
import { RecommendationsDisplay } from './display/index'
import { useBagRecommendations, useBagRecommendationsTrackers } from './hooks'
import { OriginList } from './display/types'

const Background = styled(Box)<{
  hasItems: number
}>`
  background-color: ${({ theme }) => theme.colors.gray100};
  padding-bottom: ${({ theme, hasItems }) => (hasItems ? theme.space.x14 : 0)};
`

export const BagRecommendations: FC = ({}) => {
  const { recommendations } = useBagRecommendations()
  const {
    trackImpression,
    trackProductDetailView,
  } = useBagRecommendationsTrackers()

  return (
    <Background hasItems={recommendations.length}>
      <RecommendationsDisplay
        items={recommendations}
        name={t.takeAlso}
        onRenderItem={trackImpression}
        onItemClick={trackProductDetailView}
        originList={OriginList.CART_RECOMMENDATION}
      />
    </Background>
  )
}
