import { gql } from '@lojinha/palantir'

export const VALIDATE_COUPON_MUTATION = gql`
  mutation ValidateCoupon($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
      valid
      message
      coupon {
        id
        name
        code
        type
        value
        isProductCoupon
        isFreeDeliveryCoupon
      }
      finalDiscount
    }
  }
`

export const EARLY_VALIDATE_COUPON_QUERY = gql`
  query EarlyValidateCoupon($input: CouponEarlyValidationInput!) {
    earlyValidateCoupon(input: $input) {
      valid
      message
      coupon {
        id
        name
        code
        type
        value
        isProductCoupon
        isFreeDeliveryCoupon
      }
      finalDiscount
      pendingValidation
    }
  }
`

export const CART_INFO_QUERY = gql`
  query CouponCart {
    viewer {
      cart {
        id
        billing {
          subtotal
        }
      }
    }
  }
`
