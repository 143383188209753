import React, { FC } from 'react'
import { theme, ColorProps, FontSizeProps } from '../../../theme'

export const ChevronRight: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 4.697 2.293 C 5.057 1.932 5.624 1.905 6.017 2.21 L 6.111 2.293 L 11.111 7.293 C 11.471 7.653 11.499 8.221 11.194 8.613 L 11.111 8.707 L 6.111 13.707 C 5.72 14.098 5.087 14.098 4.697 13.707 C 4.336 13.347 4.308 12.779 4.613 12.387 L 4.697 12.293 L 8.989 8 L 4.697 3.707 C 4.336 3.347 4.308 2.779 4.613 2.387 L 4.697 2.293 Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

ChevronRight.defaultProps = {
  size: 'x2',
  color: 'black',
}
