import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import { getLocalStorage } from '@lojinha/helpers'
import { Address, useZipCode } from '@lojinha/location'
import React, { FC, useEffect } from 'react'
import { AddressFormUI } from './ui'

type AddressFormProps = {
  onSubmit: (
    isInDeliveryArea: boolean,
    isGeoLocation: boolean,
    address?: Address
  ) => void
  onLoginClick: () => void
}

export const AddressForm: FC<AddressFormProps> = ({
  onSubmit,
  onLoginClick,
}) => {
  const { isAuthenticated } = useLojinhaContext()
  const { checkCoordinates, fetchAddressByZipCode } = useZipCode()

  const onClick = async (
    fetchedAddress: Address & { isGeoLocation: boolean }
  ) => {
    const {
      valid,
      nearestDistributionCenter,
      address: coordinatesAddress,
    } = await checkCoordinates({
      zipCode: fetchedAddress.zipCode,
      number: fetchedAddress.number || '',
    })

    const { isGeoLocation, ...address } = fetchedAddress

    const fullAddress = {
      ...address,
      nearestDistributionCenter: nearestDistributionCenter
        ? { id: nearestDistributionCenter }
        : undefined,
    }
    if (
      coordinatesAddress?.coordinates?.latitude &&
      coordinatesAddress?.coordinates?.longitude
    ) {
      fullAddress.coordinates = {
        latitude: coordinatesAddress.coordinates.latitude as number,
        longitude: coordinatesAddress.coordinates.longitude as number,
      }
    }

    onSubmit(valid, isGeoLocation, fullAddress)
  }

  useEffect(() => {
    const standByItem =
      getLocalStorage('standByItem') &&
      JSON.parse(getLocalStorage('standByItem')!)

    beholder.shot('structuredEvent', {
      category: 'localization_modal',
      action: 'open',
      label: isAuthenticated ? 'logged_in' : 'not_logged_in',
      property: standByItem && standByItem.sku,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AddressFormUI
      onSubmit={onClick}
      onLoginClick={onLoginClick}
      fetchAddressByZipCode={fetchAddressByZipCode}
    />
  )
}
