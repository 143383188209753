import React, { FC } from 'react'
import { theme } from '@lojinha/design-system'

export const Facebook: FC<{ color?: string }> = ({
  color = theme.colors.black,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="22"
      fill="none"
      viewBox="0 0 13 22"
    >
      <g clipPath="url(#prefix__clip0_facebook)">
        <path
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 1H9C6.239 1 4 3.239 4 6v3H1v4h3v8h4v-8h3l1-4H8V6c0-.552.448-1 1-1h3V1z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_facebook">
          <path fill="#fff" d="M0 0H13V22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
