import { dictionary } from './dictionary'

export const COUPON_ERROR_HANDLING_MAP = {
  'api-legacy.InvalidCoupon': dictionary.couponValidation.invalidCoupon,
  'api-legacy.ExpiredCoupon': dictionary.couponValidation.expiredCoupon,
  'api-legacy.InvalidDateCoupon': dictionary.couponValidation.invalidDate,
  'api-legacy.InvalidUserCoupon': dictionary.couponValidation.invalidUser,
  'api-legacy.InvalidAddressCoupon': dictionary.couponValidation.invalidAddress,
  'api-legacy.CartNotFoundCoupon': dictionary.couponValidation.cartNotFound,
  'api-legacy.LimitReachedCoupon': dictionary.couponValidation.limitReached,
  'api-legacy.FirstPurchaseCoupon': dictionary.couponValidation.firstPurchase,
  'api-legacy.InvalidCardCoupon': dictionary.couponValidation.invalidCard,
  'api-legacy.MinPurchaseValueCoupon': dictionary.couponValidation.minPurchase,
  'api-legacy.MaxUsageCoupon': dictionary.couponValidation.maxUsage,
  'api-legacy.ShouldHaveProductCoupon':
    dictionary.couponValidation.shouldHaveProduct,
  'api-legacy.OtherUserCoupon': dictionary.couponValidation.otherUser,
  'api-legacy.OnlyMobileCoupon': dictionary.couponValidation.onlyMobile,
  'api-legacy.InvalidApplyToItems':
    dictionary.couponValidation.invalidApplyToItems,
}
