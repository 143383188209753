import { beholder } from '@lojinha/beholder'
import { Anchor, Stack } from '@lojinha/design-system'
import React, { FC } from 'react'

type Column = {
  title?: string
  links: Link[]
}

type Link = {
  label: string
  ariaLabel?: string
  url: string
  externalLink?: boolean
}

type LinkColumnProps = {
  data: Column
}

export const LinkColumn: FC<LinkColumnProps> = ({ data }) => {
  const emitEvent = (linkText: string, destinationUrl: string) => {
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'footer_link_click',
      label: linkText,
      property: destinationUrl,
    })
  }

  return (
    <Stack as="ul" margin="x8">
      {data.links.map((item: Link) => {
        return (
          <li key={item.url} style={{ listStyle: 'none' }}>
            <Anchor
              onClick={() => emitEvent(item.label, item.url)}
              textSize="x1"
              href={item.url}
              isSecondary
              isBold
              isUppercased
              aria-label={
                item.ariaLabel ? `${item.ariaLabel}.` : `${item.label}.`
              }
            >
              {item.label}
            </Anchor>
          </li>
        )
      })}
    </Stack>
  )
}
