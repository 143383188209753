import React from 'react'
import { Menu } from '../../../types'

import { MenuLoading } from './components/loading'
import { useMenuNavigation } from './hook'
import { MenuDesktopUI } from './ui'

export const MenuDesktop = ({
  menu,
  isLoading = false,
}: {
  isLoading?: boolean
  menu: Menu[]
}) => {
  const { menuItems, onClickMenu } = useMenuNavigation(menu)

  if (isLoading) return <MenuLoading />

  return <MenuDesktopUI handleClickMenu={onClickMenu} menuItems={menuItems} />
}
