import React, { FC } from 'react'
import { useUpdateCart } from './hook'
import { AddToCartProps } from './types'
import { AddToCartButtonUI } from './ui'

export const AddToCartButton: FC<AddToCartProps> = ({
  displayName,
  eventList,
  isAgeRestricted = false,
  isAvailable = true,
  isBig,
  isHover,
  isSmall = false,
  item,
  positionInList,
  quantity = 0,
}) => {
  const { getToItemQuantity, isLoading, updateItemOnBag } = useUpdateCart({
    eventList,
    positionInList,
  })

  const itemQuantity = getToItemQuantity(item.id) ?? quantity

  return (
    <AddToCartButtonUI
      isHover={isHover}
      isSmall={isSmall}
      isAvailable={isAvailable}
      isAgeRestricted={isAgeRestricted}
      quantity={itemQuantity}
      updateItemOnBag={updateItemOnBag}
      item={item}
      isLoading={isLoading}
      isBig={isBig}
      displayName={displayName}
    />
  )
}
