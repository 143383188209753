import { IcmsEnumComponentcontainerdisplayCardsize } from '@lojinha/palantir'
import { Recommendation, RecommendationEventItem } from '../types'

export type IDisplayProps = {
  items: Recommendation[]
  name: string
  slug?: string
  size?: string
  cardSize?: IcmsEnumComponentcontainerdisplayCardsize
}

export type BagRecommendationsDisplayProps = IDisplayProps & {
  onRenderItem: (item: RecommendationEventItem) => void
  onItemClick: (item: RecommendationEventItem) => void
  originList: OriginList
}

export type BagRecommendationsCarouselProps = IDisplayProps & {
  onToggleClick: ({
    name,
    isHeader,
  }: {
    name: string
    isHeader?: boolean
  }) => void
  onRenderItem: (item: RecommendationEventItem) => void
  onItemClick: (item: RecommendationEventItem) => void
  originList: OriginList
  eventList?: OriginList
  isOpen?: boolean
}

export enum OriginList {
  CAROUSEL = 'carrossel',
  LIST = 'lista',
  CATEGORY = 'categoria',
  SEARCH = 'busca',
  CART_RECOMMENDATION = 'cart_recommendation',
}
