import { t } from '../../dictionary'
import { Menu } from '../../types'

type MenuStrapi = Menu & {
  children?: MenuStrapi[]
}

export const MENU_DATA: MenuStrapi[] = [
  {
    id: '6c66e930-9777-4ea6-8e19-0e6d9d1c2a4e',
    title: t.packedLunchs,
    route: '/marmitas',
    isNew: false,
    children: [
      {
        id: 'e109ff72-13a8-4935-a51e-06fb8b7d8a0b',
        title: t.dayToDay,
        route: '/marmitas#dia-a-dia',
        isNew: false,
      },
      {
        id: '9bb64f66-8917-489b-a8d6-96b8b9bc465d',
        title: t.packedLunchsPlus,
        route: '/marmitas#turbinadas-370g',
        isNew: false,
      },
      {
        id: '3c4bdc09-9b71-4f7d-82d0-1d3fbc83687f',
        title: t.chefsChoices,
        route: '/marmitas#escolha-dos-chefs',
        isNew: false,
      },
      {
        id: '3e332b4d-fb39-42cf-a163-53700d49fbc5',
        title: t.vegetarians,
        route: '/marmitas#vegetarianas',
        isNew: false,
      },
      {
        id: '0f1948b5-1a52-4d03-98e1-883b31f7dfb9',
        title: t.lowCarb,
        route: '/marmitas#low-carb',
        isNew: false,
      },
      {
        id: 'c48791c7-3e56-4e01-bf7e-e6f9b8f51ee6',
        title: t.kits,
        route: '/marmitas#kits',
        isNew: false,
      },
    ],
  },
  {
    id: '9d2f5573-2cb2-4024-9b5f-2a3b0f579d0e',
    title: t.portions,
    route: '/porcoes',
    isNew: false,
    children: [
      {
        id: 'b0f0aa94-3df8-4871-ae21-39e28a0f2e14',
        title: t.redMeats,
        route: '/porcoes#carnes-vermelhas',
        isNew: false,
      },
      {
        id: '6f943062-63d9-4c7d-87b8-8094f8d4d184',
        title: t.chickens,
        route: '/porcoes#frangos',
        isNew: false,
      },
      {
        id: '33a21c70-92e3-49ae-857a-9d1f35c794b3',
        title: t.fishesAndSeaFood,
        route: '/porcoes#peixes-e-frutos-do-mar',
        isNew: false,
      },
      {
        id: '8c1d929e-cf75-44f8-a8da-69cf5b2e8aa9',
        title: t.vegetarians,
        route: '/porcoes#vegetarianos',
        isNew: false,
      },
      {
        id: 'c486a32e-3e93-4ac8-87d2-f0e8b16298c7',
        title: t.carbohydrates,
        route: '/porcoes#carboidratos',
        isNew: false,
      },
      {
        id: '2e1c1f37-6ef5-4c47-b74a-35f68b7d7770',
        title: t.vegetables,
        route: '/porcoes#vegetais',
        isNew: false,
      },
      {
        id: '00e15f4f-d2b2-4a01-832a-34c8b571f8f1',
        title: t.soups,
        route: '/porcoes#sopas',
        isNew: false,
      },
      {
        id: '75a7a2f8-1f21-4cc9-aa05-7e0c5ec5ca9e',
        title: t.kits,
        route: '/porcoes#kits',
        isNew: false,
      },
    ],
  },
  {
    id: 'ba2c1e66-2b1b-491c-af32-f0ac301aa2d1',
    title: t.savoryAndSweets,
    isNew: false,
    route: '/porcoes/salgados-e-doces',
    children: [
      {
        id: '634888e7-e4b4-4b48-9cc8-59984a5bb28e',
        title: t.proteinSnacks,
        isNew: false,
        route: '/porcoes/salgados-e-doces#proteicos',
      },
      {
        id: 'a992fd5e-6a4c-4852-82c3-7fc24902b18b',
        title: t.savory,
        isNew: false,
        route: '/porcoes/salgados-e-doces#salgados',
      },
      {
        id: '827aed77-dbcf-4b03-ba8e-9e1dbce8ce4e',
        title: t.sweets,
        isNew: false,
        route: '/porcoes/salgados-e-doces#doces',
      },
      {
        id: '99806d1d-299c-4b9c-9fc8-e86a7a84d573',
        title: t.breads,
        isNew: false,
        route: '/porcoes/salgados-e-doces#paes',
      },
    ],
  },
  {
    id: '7d99be05-8a32-4cf9-b48d-099f0566b0bf',
    title: t.vegetarians,
    isNew: false,
    route: '/vegetarianos',
    children: [
      {
        id: 'fe2a201c-59af-4dc9-98f8-b410df290ea6',
        title: t.packedLunchs,
        isNew: false,
        route: '/vegetarianos#marmitas',
      },
      {
        id: '5d7be887-7558-4596-832c-945bf8bc975e',
        title: t.portions,
        isNew: false,
        route: '/vegetarianos#porcoes',
      },
      {
        id: '69cf2ab5-7f53-44df-a675-fcd5686bd1cc',
        title: t.savoryAndSweets,
        isNew: false,
        route: '/vegetarianos#salgados-e-doces',
      },
      {
        id: 'a8e099cc-c261-4241-a5af-1bca144f97ec',
        title: t.kits,
        isNew: false,
        route: '/vegetarianos#kits',
      },
    ],
  },
  {
    id: '8bf312e3-7b40-43e8-b799-72218a5e42d9',
    title: 'A Liv Up',
    route: '/dna-liv-up',
    isNew: false,
    children: [
      {
        id: '8b9fa047-9f4b-44e9-b54a-35bfb556493c',
        title: 'Sobre a Liv Up',
        route: '/dna-liv-up',
        isNew: false,
      },
      {
        id: '0dae3cce-388b-44db-9066-19119ec2df7f',
        title: 'Como funciona',
        isNew: false,
        route: '/como-funciona',
      },
      {
        id: 'dded55fb-f5ef-4781-989e-ac9f9400143b',
        title: 'Formas de pagamento',
        route: '/formas-de-pagamento',
        isNew: false,
      },
    ],
  },
]
