import { FreeShippingValueQuery, useQuery } from '@lojinha/palantir'
import { captureException, SentryError } from '@lojinha/sentry'
import { FREE_SHIPPING_VALUE } from './free-shipping.queries'

interface IFreeShippingHooks {
  getFreeShippingValue: () => Promise<void>
}

interface FreeShippingHooksProps {
  setFreeShippingValue: (freeShipping?: number) => void
  setLoadingFreeShippingValue: (isLoadingfreeShipping: boolean) => void
  setError?: (isError: boolean) => void
}

export const useFreeShipping = ({
  setFreeShippingValue,
  setLoadingFreeShippingValue,
  setError,
}: FreeShippingHooksProps): IFreeShippingHooks => {
  const freeShippingValueQuery = useQuery<FreeShippingValueQuery>(
    FREE_SHIPPING_VALUE,
    {
      skip: true,
    }
  )

  const getFreeShippingValue = async (): Promise<void> => {
    try {
      setLoadingFreeShippingValue(true)
      const { data } = await freeShippingValueQuery.refetch()

      setFreeShippingValue(data?.freeShippingValue)
      setLoadingFreeShippingValue(false)
    } catch (error: any) {
      captureException(error as SentryError)
      setLoadingFreeShippingValue(false)
      setError && setError(true)
    }
  }

  return {
    getFreeShippingValue,
  }
}
