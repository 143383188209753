import { NextPageContext } from 'next'
import { getAuthToken } from '@lojinha/palantir'

enum AuthPolicy {
  Everyone,
  Anonymous,
  Logged,
}

const allowedEntryPoints = [
  {
    regex: /^\/_error$/,
    authPolicy: AuthPolicy.Everyone,
  },
  {
    regex: /^(\/checkout)?\/identification/,
    authPolicy: AuthPolicy.Anonymous,
  },
  {
    regex: /^(\/checkout)?\/$/,
    authPolicy: AuthPolicy.Logged,
  },
  {
    regex: /^(\/checkout)?\/confirmation$/,
    authPolicy: AuthPolicy.Logged,
  },
]

export const getEntryPointRedirect = (ctx: NextPageContext) => {
  const hasToken = !!getAuthToken(ctx)

  const entryPoint = allowedEntryPoints.find(rule =>
    ctx.pathname.match(rule.regex)
  )

  if (entryPoint) {
    if (entryPoint.authPolicy === AuthPolicy.Everyone) {
      return
    }

    if (entryPoint.authPolicy === AuthPolicy.Anonymous && !hasToken) {
      return
    }

    if (entryPoint.authPolicy === AuthPolicy.Logged && hasToken) {
      return
    }
  }

  return hasToken ? '/checkout' : '/checkout/identification'
}
