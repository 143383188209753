import React, { FC, useEffect } from 'react'
import { Box, Button, Modal, Stack, Text } from '@lojinha/design-system'
import { beholder } from '@lojinha/beholder'
import { testId } from '@lojinha/helpers'
import { t } from '../../dictionary'
import { useAgeRestrictionModal } from './hook'

export const AgeRestrictionModal: FC = () => {
  const {
    ageRestrictionModal,
    resetAgeRestrictionModal,
    onConfirmAge,
    onDenyAge,
  } = useAgeRestrictionModal()

  useEffect(() => {
    if (ageRestrictionModal) {
      beholder.shot('structuredEvent', {
        category: 'alcoholic_drink_alert',
        action: 'impression',
      })
    }
  }, [ageRestrictionModal])

  return (
    <Modal
      isOpen={ageRestrictionModal}
      onRequestClose={resetAgeRestrictionModal}
      headerHeight={'48px'}
      noHeaderDivider
    >
      <Box py="x24">
        <Stack margin="x32" layout="vertical">
          <Stack alignItems="center" margin="x20" layout="vertical">
            <Text
              isBrand
              textTransform="uppercase"
              textSize={['x2', 'x3']}
              textAlign="center"
            >
              {t.ageRestriction.title}
            </Text>
            <Text color={'gray700'} textAlign="center">
              {t.ageRestriction.body}
            </Text>
          </Stack>
          <Stack margin="x8" layout="vertical">
            <Button
              isOutlined
              isBlock
              onClick={onDenyAge}
              {...testId('deny-age-button')}
            >
              {t.ageRestriction.denyAge}
            </Button>
            <Button
              isBlock
              onClick={onConfirmAge}
              {...testId('confirm-age-button')}
            >
              {t.ageRestriction.confirmAge}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
