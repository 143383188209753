import React, { FC, useEffect, useRef, ChangeEvent, useState } from 'react'
import { useRouter } from 'next/router'
import {
  getSearchParam,
  hasSearchParam,
  removeSearchParam,
  updateSearchParam,
} from '@lojinha/helpers'
import { SearchInputUI } from './ui'
import { SpaceProps } from '@lojinha/design-system/dist/theme'

interface SearchInputProps {
  marginTop: SpaceProps
}

export const SearchInput: FC<SearchInputProps> = (props: SearchInputProps) => {
  const { marginTop } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const router = useRouter()
  const isSearchOpen = hasSearchParam(router)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const searchParamOnURL = getSearchParam(router)
    if (searchParamOnURL) {
      setSearchTerm(searchParamOnURL)
    }
  }, [router])

  const handleClose = () => {
    setSearchTerm('')
    removeSearchParam(router)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    updateSearchParam(router, e.target.value)
  }

  useEffect(() => {
    if (isSearchOpen) {
      return inputRef.current?.focus()
    }
    setSearchTerm('')
  }, [isSearchOpen])

  if (!isSearchOpen) {
    return <></>
  }

  return (
    <SearchInputUI
      marginTop={marginTop}
      searchTerm={searchTerm}
      isSearchOpen={isSearchOpen}
      inputRef={inputRef}
      onChange={handleChange}
      onClose={handleClose}
    />
  )
}
