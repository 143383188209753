import { isValidCpf } from '@brazilian-utils/is-valid-cpf'

export const addressStreetValidator = (value: string) =>
  !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\,\-\ \(\)\']+/)

export const addressComplementValidator = (value: string) =>
  !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\,\-\ \(\)\']+/)

export const addressNeighborhoodValidator = (value: string) =>
  !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\,\-\ \(\)\']+/)

export const addressNumberValidator = (value: string) =>
  !value.match(/[^A-Za-z0-9\,\-\ \(\)\']+/)

export const personNameValidator = (value: string) =>
  !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\,\-\ ]+/)

export const cpfValidator = (value: string) =>
  isValidCpf(value.replace(/[^\d]/g, ''))

export const phoneNumberValidator = (value: string) =>
  !!value.replace(/[^\d]/g, '').match(/[0-9]{10,11}/)

export const emailValidator = (value: string) =>
  !!value.match(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi)

export const cardExpirationDateValidator = (value: string) =>
  !!value.match(/\d{2}\/\d{2}/)
