import React, { FC } from 'react'
import { Box, Center, Grid, Text } from '@lojinha/design-system'
import { t } from '../../dictionary'

interface EmptySearchProps {
  searchTerm: string
}

const HighlightTerm: FC<{ term: string }> = ({ term }) => (
  <Text
    isBrand
    color="acaiLight"
    textSize="x4"
    as="span"
    textTransform="uppercase"
  >
    {term}
  </Text>
)

export const EmptySearch: FC<EmptySearchProps> = ({ searchTerm }) => {
  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid gridColumn={['2 / 24', '2 / 12']}>
          <Box marginTop={['x24', 'x72', 'x160']} marginBottom="x256">
            <Box marginBottom="x24">
              <Text textSize="x4" textTransform="uppercase" isBrand>
                {t.search.noResultsStart}
                <HighlightTerm term={searchTerm} />
                {t.search.noResultsEnd}
              </Text>
            </Box>
            <Text textSize="x2" color="gray700">
              {t.search.noResultsAdvice}
            </Text>
          </Box>
        </Grid>
      </Grid>
    </Center>
  )
}
