import { Search } from '@lojinha/design-system/src/icons'
import { testId, updateSearchParam } from '@lojinha/helpers'
import { beholder } from '@lojinha/beholder'
import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { t } from '../../dictionary'
import { HeaderDesktopButton } from '../header-desktop-button'

export const SearchButton: FC = () => {
  const router = useRouter()

  const handleClick = () => {
    beholder.shot('structuredEvent', {
      category: 'search_global',
      action: 'access_search',
      label: router.asPath,
    })

    updateSearchParam(router, '')
  }

  return (
    <HeaderDesktopButton
      mainText=""
      ariaLabel={t.search}
      icon={<Search size="x3" />}
      onClick={handleClick}
      {...testId('search-button-icon')}
    />
  )
}
