import {
  ShoppingBagIcon,
  Tag,
  ArrowRightFine,
  UserCircle,
} from '@lojinha/design-system/src/icons'
import { beholder } from '@lojinha/beholder'
import { t } from '../../dictionary'

interface AccountMenuItemType {
  id: string
  Icon: any
  title: string
  route: string
  index: number
  onClick?: () => void
}

interface AccountMenuType {
  menu: AccountMenuItemType[]
}

export const ACCOUNT_MENU_DATA: AccountMenuType = {
  menu: [
    {
      index: 0,
      id: 'profile',
      Icon: UserCircle,
      title: t.menu.account.profile,
      route: '/meu/perfil',
    },
    {
      index: 1,
      id: 'my_purchases',
      Icon: ShoppingBagIcon,
      title: t.menu.myOrders,
      route: '/meu/minhas-compras',
    },
    {
      index: 2,
      id: 'mgm',
      Icon: Tag,
      title: t.menu.mgm,
      route: '/meu/indicacoes',
      onClick: () => {
        beholder.shot('structuredEvent', {
          category: 'customer_referral',
          action: 'header_discount_button',
          label: null,
        })
      },
    },
    {
      index: 3,
      id: 'logout',
      Icon: ArrowRightFine,
      title: t.menu.logout,
      route: '/',
    },
  ],
}
