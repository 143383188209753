import React, { FC } from 'react'
import { Stack, Text, AlertTag, AlertTagVariants } from '@lojinha/design-system'
import { UnavailableItemInfo } from '@lojinha/context/src/types'
import { t } from '../../../dictionary'

type Props = {
  items: UnavailableItemInfo[]
}

const MAX_UNAVAILABLE_ITEMS_TO_SHOW = 3

export const UnavailableItems: FC<Props> = ({ items }) => {
  return (
    <Stack margin="x24" layout="vertical" alignItems="center">
      <AlertTag
        content={t.temporaryUnavailable(items.length)}
        variant={AlertTagVariants.alert}
      />
      <ul aria-label={t.ariaTemporaryUnavailable(items.length)}>
        {items.slice(0, MAX_UNAVAILABLE_ITEMS_TO_SHOW).map(({ id, name }) => (
          <Text
            key={id}
            as="li"
            textAlign="left"
            htmlAttrs={{
              style: { listStyleType: 'disc', listStylePosition: 'inside' },
            }}
          >
            {name}
          </Text>
        ))}
      </ul>
      <Stack margin="x0" layout="vertical" alignItems="left">
        <Text isBold>{t.temporaryUnavailableTitle(items.length)}</Text>
        <Text>{t.temporaryUnavailableDescription(items.length)}</Text>
      </Stack>
    </Stack>
  )
}
