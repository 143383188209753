import { Text } from '@lojinha/design-system'
import React from 'react'
import { t } from '../../../../dictionary'
import { numberToCurrency } from '../../../../helpers/formatNumber'

type Props = {
  price: number
  priceFrom?: number | null
  isBold?: boolean
}

export const Price = ({ price, priceFrom, isBold = false }: Props) => {
  return (
    <div aria-label={t.priceAriaLabel({ price, priceFrom })}>
      {!!priceFrom && priceFrom > price && (
        <Text
          color="gray700"
          textSize={['x14', 'x2']}
          textDecoration="line-through"
          aria-hidden
        >
          {numberToCurrency(priceFrom)}
        </Text>
      )}
      <Text
        color={'black'}
        textSize={['x14', 'x2']}
        aria-hidden
        isBold={isBold}
      >
        {numberToCurrency(price)}
      </Text>
    </div>
  )
}
