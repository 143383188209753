import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const ArrowLeftThin: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
    >
      <path
        fill={theme.colors[color!]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1845 11.435L8.0415 3.395C8.2225 3.147 8.5105 3 8.8175 3H8.9545C9.7415 3 10.1935 3.896 9.7265 4.53L5.2395 10.618C5.1225 10.777 5.2355 11 5.4325 11H20.7925C21.3235 11 21.7525 11.431 21.7525 11.961V12.04C21.7525 12.571 21.3235 13 20.7925 13H5.4325C5.2355 13 5.1225 13.225 5.2395 13.383L9.7315 19.471C10.1995 20.104 9.7465 21 8.9595 21H8.8235C8.5175 21 8.2285 20.854 8.0485 20.606L2.1845 12.566C1.9385 12.229 1.9385 11.772 2.1845 11.435"
      />
    </svg>
  )
}

ArrowLeftThin.defaultProps = {
  color: 'black',
  size: 'x2',
}
