import React from 'react'
import styled from 'styled-components'
import { Anchor, Stack } from '@lojinha/design-system'

import { Group } from './group'
import { DATA_FOOTER } from '../footer.i18n'

const Icon = styled.div`
  &:hover {
    path {
      stroke: ${({ theme }) =>
        theme.colorShade({ type: 'hover', color: 'primary' })};
    }
  }

  &:active {
    path {
      stroke: ${({ theme }) =>
        theme.colorShade({ type: 'pressed', color: 'primary' })};
    }
  }
`

export const SocialNetwork = () => (
  <Group title={DATA_FOOTER.socialNetworks.title}>
    <Stack margin="x24" layout="horizontal">
      {DATA_FOOTER.socialNetworks.links.map(network => {
        const NetworkIcon = network.icon
        return (
          <Anchor
            key={network.alt}
            href={network.url}
            isExternal={network.externalLink}
            aria-label={network.alt}
            htmlAttrs={{ lang: 'en' }}
          >
            <Icon>
              <NetworkIcon />
            </Icon>
          </Anchor>
        )
      })}
    </Stack>
  </Group>
)
