import React, { FC } from 'react'
import { theme } from '@lojinha/design-system'

export const Instagram: FC<{ color?: string }> = ({
  color = theme.colors.black,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#prefix__clip0_instagram)"
      >
        <path d="M16 1H6C3.239 1 1 3.239 1 6v10c0 2.761 2.239 5 5 5h10c2.761 0 5-2.239 5-5V6c0-2.761-2.239-5-5-5z" />
        <path
          fillRule="evenodd"
          d="M15 10.37c.253 1.707-.617 3.384-2.159 4.16-1.541.776-3.406.475-4.626-.745-1.22-1.22-1.52-3.085-.745-4.627C8.246 7.617 9.923 6.747 11.63 7c1.743.258 3.111 1.627 3.37 3.37z"
          clipRule="evenodd"
        />
        <path d="M16.5 5.5h.01" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_instagram">
          <path fill="#fff" d="M0 0H22V22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
