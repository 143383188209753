import { useRouter } from 'next/router'
import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@lojinha/helpers'
import {
  AddressKind,
  BagItem,
  EarlyValidateCouponQuery,
  EarlyValidateCouponQueryVariables,
  MarketableKind,
  useQuery,
} from '@lojinha/palantir'
import { useEffect, useState } from 'react'
import {
  COUPON_ERROR_HANDLING_MAP,
  formatFromPascalCaseToSnakeCase,
  dictionary,
  updateDeliveryTax,
} from './helpers'
import { EARLY_VALIDATE_COUPON_QUERY } from './coupon-input.queries'
import { ValidateCoupon } from './coupon-input.types'

export interface useCouponInputProps {
  isCheckoutReview: boolean
}

export interface ApplyCouponProps {
  code: string
  revalidate?: boolean
  cartId?: string
}

export const useCouponInput = (props: useCouponInputProps) => {
  const { isCheckoutReview } = props
  const { pathname } = useRouter()

  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)
  const [loading, setLoading] = useState(false)
  const [customMessage, setCustomMessage] = useState('')

  const {
    address,
    bag,
    card,
    cartId,
    centerId,
    coupon,
    deliveryKind,
    deliveryTax,
    freeShippingValue,
    setCoupon,
    setDeliveryTax,
    setDiscount,
    selectedPaymentMethod,
    setIsCheckoutReviewCouponValidating,
  } = useLojinhaContext()

  const defaultCoupon = coupon?.code || getLocalStorage('coupon_code')

  const earlyValidateCouponQuery = useQuery<
    EarlyValidateCouponQuery,
    EarlyValidateCouponQueryVariables
  >(EARLY_VALIDATE_COUPON_QUERY, {
    skip: true,
    nextFetchPolicy: 'network-only',
  })

  const validateCoupon = async (
    code: string,
    cartId?: string
  ): Promise<ValidateCoupon> => {
    return earlyValidateCouponQuery
      .refetch({
        input: {
          code,
          cartId: cartId ?? '',
          isCheckoutReview,
          subtotal: bag?.subtotal,
          bag: bag?.cartItems.map<BagItem>(item => ({
            kind: item.kind ?? MarketableKind.Product,
            itemId: item.id ?? '',
            quantity: item.quantity,
          })),
          deliverySettings: {
            kind: deliveryKind || AddressKind.Delivery,
            centerId,
            coordinates: {
              latitude: address?.coordinates?.latitude ?? 0,
              longitude: address?.coordinates?.longitude ?? 0,
            },
          },
          paymentMethodBrand: card?.brand,
        },
      })
      .then(({ data }) => ({
        data,
        errorMessage: undefined,
        errorCode: undefined,
      }))
      .catch((err: any) => {
        const code: keyof typeof COUPON_ERROR_HANDLING_MAP =
          err.graphQLErrors && err.graphQLErrors[0]?.extensions?.code
        return {
          data: undefined,
          errorMessage:
            COUPON_ERROR_HANDLING_MAP[code ?? 'api-legacy.InvalidCoupon'],
          errorCode: code ?? 'api-legacy.InvalidCoupon',
        }
      })
  }

  const applyCoupon = async ({
    code,
    revalidate,
    cartId,
  }: ApplyCouponProps) => {
    if (loading) {
      return
    }

    if ((success || !!customMessage) && !revalidate) {
      setCoupon()
      setCustomMessage('')
      setSuccess(false)
      setPending(false)
      return
    }

    if (!code) {
      return
    }

    setLoading(true)
    isCheckoutReview && setIsCheckoutReviewCouponValidating(true)

    const { data, errorMessage, errorCode } = await validateCoupon(code, cartId)

    const newCoupon = data?.earlyValidateCoupon?.coupon || undefined
    const valid = data?.earlyValidateCoupon?.valid
    const pending = data?.earlyValidateCoupon?.pendingValidation
    const message = data?.earlyValidateCoupon?.message || ''
    const finalDiscount = data?.earlyValidateCoupon?.finalDiscount || 0

    const rejectedReasonCode = (
      errorCode?.split('.')[1] ?? 'InvalidCoupon'
    ).replace('Coupon', '')

    beholder.shot('couponFilled', {
      nameOfCoupon: code,
      category: isCheckoutReview ? 'checkout' : 'sacola',
      status: (pending && 'pendent') || (valid ? 'accepted' : 'rejected'),
      rejectedReason: valid
        ? undefined
        : formatFromPascalCaseToSnakeCase(rejectedReasonCode),
    })

    updateDeliveryTax({
      subtotal: bag?.subtotal ?? 0,
      deliveryCost: deliveryTax,
      deliveryKind,
      isFreeDeliveryCoupon: newCoupon?.isFreeDeliveryCoupon,
      setDeliveryTax,
      freeShippingValue,
    })
    newCoupon && setCoupon(newCoupon)
    setDiscount(finalDiscount)
    setCustomMessage(
      !!valid
        ? message
        : errorMessage ?? dictionary.couponValidation.invalidCoupon
    )
    setSuccess(!!valid)
    setPending(!!pending)
    !!valid
      ? setLocalStorage('coupon_code', code)
      : removeLocalStorage('coupon_code')
    setLoading(false)
    isCheckoutReview && setIsCheckoutReviewCouponValidating(false)
  }

  useEffect(() => {
    if (pathname !== '/review') {
      return
    }
    coupon && applyCoupon({ code: coupon?.code, revalidate: true, cartId })
  }, [card, selectedPaymentMethod])

  useEffect(() => {
    const code = coupon?.code || getLocalStorage('coupon_code')
    if (!code) {
      return
    }

    applyCoupon({ code, revalidate: true, cartId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bag])

  const changeCoupon = () => {
    setSuccess(false)
    setPending(false)
    setCustomMessage('')
  }

  const onRemoveCoupon = () => {
    removeLocalStorage('coupon_code')
    setDiscount && setDiscount(0)

    updateDeliveryTax({
      subtotal: bag?.subtotal ?? 0,
      deliveryCost: address?.deliveryCost,
      deliveryKind,
      setDeliveryTax,
      freeShippingValue,
    })
  }

  return {
    applyCoupon,
    validateCoupon,
    changeCoupon,
    defaultCoupon,
    loading,
    success,
    pending,
    customMessage,
    onRemoveCoupon,
  }
}
