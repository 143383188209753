import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const Close: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6129 5.2097C6.22061 4.90468 5.65338 4.93241 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.9497 12.364L5.29289 18.0208L5.2097 18.115C4.90468 18.5073 4.93241 19.0745 5.29289 19.435C5.68342 19.8256 6.31658 19.8256 6.70711 19.435L12.364 13.7782L18.0208 19.435L18.115 19.5182C18.5073 19.8232 19.0745 19.7955 19.435 19.435C19.8256 19.0445 19.8256 18.4113 19.435 18.0208L13.7782 12.364L19.435 6.70711L19.5182 6.6129C19.8232 6.22061 19.7955 5.65338 19.435 5.29289C19.0445 4.90237 18.4113 4.90237 18.0208 5.29289L12.364 10.9497L6.70711 5.29289L6.6129 5.2097Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

Close.defaultProps = {
  color: 'black',
  size: 'x2',
}
