import { Box, Button, Modal, Stack, Text } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import React, { FC } from 'react'
import { t } from '../../dictionary'
import { useLoginModal } from './hook'

export const LoginModal: FC = () => {
  const { loginModal, onConfirmLogin, onDenyLogin } = useLoginModal()

  return (
    <Modal
      isOpen={loginModal}
      onRequestClose={onDenyLogin}
      headerHeight={'48px'}
      noHeaderDivider
    >
      <Box py="x24">
        <Stack margin="x32" layout="vertical">
          <Stack alignItems="center" margin="x20" layout="vertical">
            <Text
              isBrand
              textTransform="uppercase"
              textSize={['x2', 'x3']}
              textAlign="center"
            >
              {t.loginModal.title}
            </Text>
            <Text color={'gray700'} textAlign="center">
              {t.loginModal.body}
            </Text>
          </Stack>
          <Stack margin="x8" layout="vertical">
            <Button
              isBlock
              onClick={onConfirmLogin}
              {...testId('confirm-login-button')}
            >
              {t.loginModal.confirmButton}
            </Button>
            <Button
              isOutlined
              isBlock
              onClick={onDenyLogin}
              {...testId('deny-login-button')}
            >
              {t.loginModal.denyButton}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
