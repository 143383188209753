import { numberToCurrency, numberToCurrencyAriaLabel } from '@lojinha/helpers'

export const t = {
  close: 'Fechar',
  closeBag: 'Fechar sacola',
  checkOut: 'Finalizar compra',
  emptyBagTitle: 'Sua sacola está vazia',
  emptyBagText: 'Navegue pelas categorias ou faça uma busca por produtos.',
  yourBag: 'Sua sacola tem',
  freeDelivery: 'Frete grátis',
  missing: 'Faltam',
  for: 'para o',
  youWonFreeDelivery: 'Você ganhou frete grátis',
  kit: 'Kit',
  meal: 'Prato',
  product: 'Porção',
  packedmeal: 'Marmita',
  hide: 'Ocultar',
  hideBag: 'Ocultar sacola',
  feedbacks: {
    allItemsAdded: 'Itens adicionados ao carrinho com sucesso',
    allUnavailableItems:
      'Os itens não foram adicionados à sacola por estarem todos indisponíveis no momento.',
    someUnavailableItems:
      'Os seguintes itens não foram adicionados por estarem indisponíveis:',
    ageRestrictedItems:
      'Você tirou as bebidas da sacola, tudo certo com a sua compra!',
  },
  buy: 'Comprar',
  takeAlso: 'Leve também',
  backSoon: 'Volta logo',

  yourBagCountAriaLabel: (count: number) =>
    `Sua sacola tem ${count} ${count === 1 ? 'item' : 'itens'}`,

  goToCheckout: (isAuthenticated: boolean) =>
    isAuthenticated ? 'Agendar entrega' : 'Entrar ou criar conta',

  quantity: (n: number) => `${n} unidade${n === 1 ? '' : 's'}`,

  quantityAriaLabel: (quantity: number) => `Quantidade ${quantity}`,

  productsCount: (count: number) =>
    count === 1 ? `${count} item` : `${count} itens`,

  totalValue: (total: number) => `Total: ${numberToCurrency(total)}.`,

  freeDeliveryAmountNeededAriaLabel: ({
    amountNeeded,
    total,
  }: {
    amountNeeded: number
    total: number
  }) => `Faltam ${numberToCurrency(amountNeeded - total)} para o frete grátis.`,

  descriptionFormatted: (description?: string) =>
    description ? `e ${description},` : '',

  priceAriaLabel: ({
    price,
    priceFrom,
  }: {
    price: number
    priceFrom?: number | null
  }) => {
    const discount =
      !!priceFrom && priceFrom > price
        ? `de ${numberToCurrencyAriaLabel(priceFrom)} por `
        : ''
    return `Preço ${discount}${numberToCurrencyAriaLabel(price)}`
  },

  bagItemAriaLabel: ({
    title,
    description,
    price,
    priceFrom,
    quantity,
  }: {
    title: string
    description?: string
    price: number
    priceFrom?: number | null
    quantity: number
  }) =>
    `${title}, ${t.descriptionFormatted(description)} ${t.priceAriaLabel({
      price,
      priceFrom,
    })}, ${t.quantityAriaLabel(quantity)}`,

  bagRecommendationAriaLabel: ({
    title,
    description,
    price,
    priceFrom,
  }: {
    title: string
    description?: string
    price: number
    priceFrom?: number
  }) =>
    `${title}, ${description ? `e ${description},` : ''} ${t.priceAriaLabel({
      price,
      priceFrom,
    })}. Clique para ver mais detalhes.`,

  weightAndUnit: (
    weight: number | null | undefined,
    unit: string | null | undefined
  ) => `${weight || ''}${unit || ''}`,
  weightAriaLabel: (weight: string) => `Peso ${weight}.`,
}
