import { Stack, Text, theme } from '@lojinha/design-system'
import { Chevron } from '@lojinha/design-system/src/icons'
import { testId } from '@lojinha/helpers'
import React, { FC } from 'react'
import styled from 'styled-components'

type HeaderProps = {
  name: string
  onLinkClick?: () => void
  isOpen?: boolean
}

const Wrapper = styled(Stack)`
  width: 100%;
  justify-content: space-between;
`

export const Header: FC<HeaderProps> = ({ name, onLinkClick, isOpen }) => (
  <Wrapper
    layout="horizontal"
    onClick={onLinkClick}
    css={{ cursor: 'pointer' }}
    alignItems="baseline"
    {...testId(`header-${name}`)}
  >
    <Text
      textTransform="uppercase"
      isBrand
      css={`
        ${theme.media.below('sm')} {
          margin-bottom: ${theme.space.x16};
        }
      `}
    >
      {name}
    </Text>
    <Chevron size={theme.space.x14} direction={isOpen ? 'up' : 'down'} />
  </Wrapper>
)
