import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getSearchParam } from '@lojinha/helpers'
import { useSearch } from './hook'
import { LoadingSearch } from './loading-state'
import { SearchResults } from './results'
import { EmptySearch } from './empty-state'
import { PreSearch } from './pre-search'

export const Search = () => {
  const router = useRouter()
  const searchTerm = getSearchParam(router)
  const {
    debouncedSearchTerm,
    results,
    totalCount,
    hasResults,
    loading,
    hasMinimumLength,
  } = useSearch(searchTerm)

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(results), hasMinimumLength])

  const empty = hasMinimumLength && !hasResults

  if (loading) return <LoadingSearch />

  if (empty) return <EmptySearch searchTerm={debouncedSearchTerm} />

  if (hasMinimumLength && hasResults)
    return (
      <SearchResults
        term={debouncedSearchTerm}
        results={results}
        totalCount={totalCount}
      />
    )

  return <PreSearch />
}
