import React from 'react'

import { User } from '@lojinha/context/src/types'
import { Box, Grid } from '@lojinha/design-system'
import { sanitizeImage } from '@lojinha/helpers'
import { ProductCardKit } from '../../../components/product-card/kit'
import getPricePerMeal from '../../../helpers/getPricePerMeal'
import {
  OnItemClickType,
  OnRenderItemType,
  OriginList,
} from '../../../modules/display/types'
import { DisplayItems } from '../../../modules/page-content/page-content.types'

type KitCardListProps = {
  name?: string
  items: DisplayItems[]
  originList: OriginList
  onItemClick?: OnItemClickType
  onRenderItem?: OnRenderItemType
  user?: User
}

export const KitCardList = ({
  name,
  items,
  originList,
  onItemClick,
  onRenderItem,
  user,
}: KitCardListProps) => {
  return (
    <Grid
      gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}
      htmlAttrs={{ style: { zIndex: 0 } }}
    >
      <Grid gridColumn={['1 / 24', '1 / 12']} gridRowGap="x16">
        {items.map((item, index) => (
          <Box key={item.id} marginTop="x16" marginBottom="x8">
            <ProductCardKit
              id={item.id}
              kind={item.kind}
              classification={item.classification}
              title={item.name}
              description={item.description}
              badges={item.itemTags.nodes}
              isAvailable={item.isAvailable}
              imageUrl={sanitizeImage({
                imageUrl: item.imageUrl,
                size: 'medium',
              })}
              price={item.price || 0}
              priceFrom={item.priceFrom || 0}
              pricePerMeal={getPricePerMeal(item.price, item.totalMealsCount)}
              quantity={0}
              sku={item.sku}
              imageAriaLabel={item.name}
              isLarge={true}
              eventList={originList}
              positionInList={index}
              onItemClick={() =>
                onItemClick &&
                onItemClick({
                  sku: item.sku,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  originList: originList,
                  isAvailable: item.isAvailable,
                  user,
                })
              }
              onRenderItem={() =>
                onRenderItem &&
                onRenderItem({
                  id: item.id,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              slug={item.seo.url}
              displayName={name}
              firstOfferReplacement={item.firstOfferReplacement}
            />
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}
