import React, { FC } from 'react'

import { useMenu } from './hook'
import { MenuDesktop } from './menu-desktop'
import { MenuMobile } from './menu-mobile'

type MenuProps = {
  isMobile: boolean
  isLoading?: boolean
}

export const Menu: FC<MenuProps> = ({ isMobile, isLoading = false }) => {
  const { menuData } = useMenu()
  if (isMobile) {
    return <MenuMobile menu={menuData} />
  }
  return <MenuDesktop isLoading={isLoading} menu={menuData} />
}
