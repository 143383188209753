import React, { useState } from 'react'
import { Text, MenuDropdown, Button } from '@lojinha/design-system'
import styled from 'styled-components'
import { useLojinhaContext } from '@lojinha/context/'
import { Address } from '@lojinha/context/src/types'
import { singleAddress } from '@lojinha/helpers'
import { t } from '../../dictionary'

type Props = {
  address: string
  onClickWillDelivery: () => void
  onSelectAddress?: (selectedAddress: Address) => void
  anchorElement: React.ReactElement
}

const ListItem = styled.li<{ isActive?: boolean }>(
  ({ theme, isActive }) => `
    list-style: none;
    cursor: pointer;
    
    p {
      color: ${isActive ? theme.colors.success : theme.colors.black};
    }

    &:hover,
    &:active,
    &:focus {
      p {
        color: ${theme.colors.success};
      }
    }
  `
)

export const AddressListDropdown = ({
  address,
  onClickWillDelivery,
  onSelectAddress,
  anchorElement,
}: Props) => {
  const { allAddresses, setAddress, setCenterId } = useLojinhaContext()

  const [currentAddress, setCurrentAddress] = useState(address)

  const onClickAddress = (item: Address) => () => {
    setAddress(item)
    setCurrentAddress(singleAddress(item))
    const centerId = item.nearestDistributionCenter?.id

    centerId && setCenterId(centerId)
    onSelectAddress && onSelectAddress(item)
  }

  return (
    <MenuDropdown anchorElement={anchorElement}>
      <ul>
        {allAddresses?.map(item => {
          const addressFormatted = singleAddress(item)
          const itemSelected = addressFormatted === currentAddress

          const addressAriaLabel = itemSelected
            ? t.addressSelected
            : t.changeAddressTo

          return (
            <ListItem key={item.id} isActive={itemSelected}>
              <Button
                isLink
                isBlock
                onClick={onClickAddress(item)}
                ariaLabel={`${addressAriaLabel} ${addressFormatted}`}
              >
                <Text textSize={'x12'} isBold>
                  {addressFormatted}
                </Text>
              </Button>
            </ListItem>
          )
        })}
        <ListItem key="add-new-address">
          <Button
            color="success"
            isLink
            isBlock
            onClick={onClickWillDelivery}
            ariaLabel={t.addNewAddressAriaLabel}
          >
            <Text textSize={'x12'} isBold>
              {t.addNewAddress}
            </Text>
          </Button>
        </ListItem>
      </ul>
    </MenuDropdown>
  )
}
