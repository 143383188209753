import { Box, Text } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import { MarketableClassification, MarketableKind } from '@lojinha/palantir'
import React, { useEffect, useState } from 'react'
import { t } from '../../../dictionary'
import { useOnScreen } from '../../../helpers/useOnScreen'
import { useRefCallback } from '../../../helpers/useRefCallback'
import { AddToCartButton } from '../../add-to-cart-button'
import { BadgesGroup } from '../components/badges-group'
import { CardWrapper } from '../components/card'
import { ItemImage } from '../components/item-image'
import { PriceAndWeight } from '../components/price-and-weight'
import { ProductCardType } from '../types'

export const ProductCardDish = ({
  id,
  kind,
  classification,
  title,
  description,
  displayName,
  badges,
  imageUrl,
  isAvailable,
  isAgeRestricted,
  price,
  priceFrom,
  weight,
  quantity,
  sku,
  imageAriaLabel,
  eventList,
  positionInList,
  slug,
  onRenderItem,
  onItemClick,
}: ProductCardType) => {
  const [isCardHover, setIsCardHover] = useState(false)

  const [itemCard] = useRefCallback<HTMLDivElement>()
  const { isRendered } = useOnScreen(itemCard)

  useEffect(() => {
    isRendered && onRenderItem && onRenderItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered])

  const onMouseOver = () => {
    setIsCardHover(true)
  }

  const onMouseLeave = () => {
    setIsCardHover(false)
  }

  return (
    <CardWrapper
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ariaLabel={t.productCardAriaLabel({
        title,
        badges,
        price,
        priceFrom,
        weight,
        description,
      })}
      onClick={onItemClick}
      slug={slug}
    >
      <div ref={itemCard}>
        <ItemImage
          url={imageUrl}
          ariaLabel={imageAriaLabel}
          cardType="dish"
          isHover={isCardHover}
        />
        <Box px="x16" marginTop="x16" htmlAttrs={{ style: { height: '16px' } }}>
          {badges && <BadgesGroup badges={badges} />}
        </Box>
        <Box px="x16" marginTop="x16">
          <Text
            color={'black'}
            isBold
            textSize={['x14', 'x2']}
            {...testId('meal-card-title')}
          >
            {title}
          </Text>
          <Text textSize={['x1', 'x14']} color="gray700">
            {description}
          </Text>
        </Box>
      </div>

      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Box paddingLeft="x16" paddingBottom="x16" width="100%">
          <PriceAndWeight price={price} priceFrom={priceFrom} weight={weight} />
        </Box>
        <Box padding="x8" minWidth={['154px', '272px']}>
          <AddToCartButton
            displayName={displayName}
            eventList={eventList}
            positionInList={positionInList}
            quantity={quantity ?? 0}
            isHover={isCardHover}
            isAvailable={isAvailable}
            isAgeRestricted={isAgeRestricted}
            item={{
              id,
              imageUrl,
              kind: kind as MarketableKind,
              classification: classification as MarketableClassification,
              name: title,
              price,
              quantity: quantity ?? 0,
              priceFrom,
              sku,
              subtitle: description,
            }}
          />
        </Box>
      </Box>
    </CardWrapper>
  )
}
