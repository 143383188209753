import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const Minus: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="5.5"
        width="11"
        height="1"
        rx="0.5"
        fill={theme.colors[color!]}
        stroke={theme.colors[color!]}
      />
    </svg>
  )
}

Minus.defaultProps = {
  color: 'primary',
  size: 'x1',
}
