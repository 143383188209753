import React, { FC } from 'react'
import { theme } from '@lojinha/design-system'

export const Twitter: FC<{ color?: string }> = ({
  color = theme.colors.black,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      fill="none"
      viewBox="0 0 24 20"
    >
      <g clipPath="url(#prefix__clip0_twitter)">
        <path
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M23 1c-.958.675-2.018 1.192-3.14 1.53-1.234-1.418-3.222-1.916-4.978-1.246-1.756.67-2.907 2.366-2.882 4.246v1C8.43 6.623 5.052 4.922 3 2c0 0-4 9 5 13-2.06 1.398-4.513 2.099-7 2 9 5 20 0 20-11.5 0-.279-.028-.556-.08-.83C21.94 3.664 22.66 2.393 23 1z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_twitter">
          <path fill="#fff" d="M0 0H24V20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
