import React from 'react'
import { Box, Carousel } from '@lojinha/design-system'
import { normalizeHtmlId, sanitizeImage } from '@lojinha/helpers'
import { t } from '../../dictionary'
import { Recommendation } from '../recommendation'
import { Header } from './header'
import { BagRecommendationsCarouselProps } from './types'

export const RecommendationsCarousel = ({
  name,
  items,
  originList,
  isOpen,
  onRenderItem,
  onItemClick,
  onToggleClick,
}: BagRecommendationsCarouselProps) => {
  return (
    <Carousel
      slideToShow={2}
      id={normalizeHtmlId(name)}
      constrolsOnVertical="bottom"
      constrolsOnHorizontal="center"
      header={
        <Header
          name={name}
          onLinkClick={() => onToggleClick({ name, isHeader: true })}
          isOpen={isOpen}
        />
      }
    >
      {items.map((item, index) => {
        return (
          <Box key={item.id} maxWidth="160px">
            <Recommendation
              id={item.id}
              displayName={name}
              kind={item.kind}
              classification={item.classification}
              title={item.name}
              isAvailable={item.isAvailable}
              isAgeRestricted={item.isAgeRestricted}
              imageUrl={sanitizeImage({
                imageUrl: item.imageUrl,
                size: 'medium',
              })}
              price={item.price || 0}
              priceFrom={item.priceFrom || 0}
              weight={
                item.weight ? t.weightAndUnit(item.weight, item.unit) : ''
              }
              quantity={0}
              imageAriaLabel={item.name}
              sku={item.sku}
              eventList={originList}
              positionInList={index}
              onRenderItem={() =>
                onRenderItem({
                  id: item.id,
                  sku: item.sku,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              onItemClick={() =>
                onItemClick({
                  id: item.id,
                  sku: item.sku,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              slug={item.seo.url}
            />
          </Box>
        )
      })}
    </Carousel>
  )
}
