import React, { FC } from 'react'
import { Box, Stack } from '@lojinha/design-system'
import { MenuItem } from '../menu-item'
import { Menu } from '../../../../../types'
import { MobileMenuImage } from '../../../components/menu-image'

type SubMenuListProps = {
  items?: Menu[]
  selectedItem?: Menu | null
  onClick: (menu: Menu) => void
}

export const SubMenuList: FC<SubMenuListProps> = ({
  items,
  selectedItem,
  onClick,
}) => {
  return (
    <Box paddingTop="x40">
      <Stack margin="x32">
        {items?.map(item => (
          <MenuItem key={item.id} item={item} onClick={onClick} />
        ))}
      </Stack>
      {selectedItem?.image && <MobileMenuImage image={selectedItem.image} />}
    </Box>
  )
}
